import {kontrolInstance} from 'services/axiosInstances';
import {getToken, handleUnauthorized} from '../utils/userHelpers';

export const getCustomers = async (searchValue) => {
  const searchParams = {filter: {where: {companyName: {ilike: `%${searchValue}%`}}, limit: 10}};
  return kontrolInstance.get('/customers', {params: searchParams});
};

export const getSuppliers = async (searchValue) => {
  const searchParams = {
    filter: {
      where: {
        companyName: {
          ilike: `%${searchValue}%`,
        },
      },
      include: [
        'hourlyCosts',
        {supplierGroup: 'hourlyCosts'},
      ],
      limit: 10,
    },
  };
  return kontrolInstance.get('/suppliers', {params: searchParams});
};

export const getCompanyFeatures = async params => {
  return kontrolInstance.get('/company-features', {params});
};

export const patchOrCreateCompanyFeature = async (data) => {
  return kontrolInstance.patch('/company-features', data);
};

export const patchCompanyCompanyFeature = async (data, params) => {
  return kontrolInstance.patch('/company-company-features', data, {params});
};

export const getCustomerProcessCosts = async (companyId) => {
  const searchParams = {filter: {where: {companyId}}};
  return kontrolInstance.get('/company-process-rates', {params: searchParams});
};

export const createCustomerProcessCosts = async (data) => {
  const {companyId, processKey, setupRate, processRate} = data;
  const payload = {
    companyId,
    processKey,
    setupRate,
    processRate,
  };
  return kontrolInstance.post('/company-process-rates', payload);
};

export const deleteCustomerProcessCosts = async (id) => {
  return kontrolInstance.delete(`/company-process-rates/${id}`);
};

export const patchCustomerProcessCosts = async (data) => {
  return kontrolInstance.patch(`/company-process-rates`, data);
};

export const getSupplierProcessCosts = async (companyId) => {
  const searchParams = {filter: {where: {companyId}}};
  return kontrolInstance.get('/supplier-hourly-costs', {params: searchParams});
};

export const createSupplierProcessCosts = async (data) => {
  const {companyId, processKey, setupCosts, processCosts, unit} = data;
  const payload = {
    companyId,
    processKey,
    setupCosts,
    processCosts,
    unit,
  };
  return kontrolInstance.post('/supplier-hourly-costs', payload);
};

export const deleteSupplierProcessCosts = async (id) => {
  return kontrolInstance.delete(`/supplier-hourly-costs/${id}`);
};

export const patchSupplierProcessCosts = async (data) => {
  return kontrolInstance.patch(`/supplier-hourly-costs`, data);
};

export const getSupplierGroups = async () => {
  const searchParams = {filter: {include: 'hourlyCosts'}};

  return kontrolInstance.get('supplier-groups', {params: searchParams});
};

export const getSupplierGroupProcessCosts = async (supplierGroupKey) => {
  const searchParams = {filter: {where: {supplierGroupKey}}};
  return kontrolInstance.get('/supplier-group-hourly-costs', {params: searchParams});
};

export const createSupplierGroupProcessCosts = async (data) => {
  const {supplierGroupKey, processKey, setupCosts, processCosts, unit} = data;
  const payload = {
    supplierGroupKey,
    processKey,
    setupCosts,
    processCosts,
    unit,
  };
  return kontrolInstance.post('/supplier-group-hourly-costs', payload);
};

export const deleteSupplierGroupProcessCosts = async (id) => {
  return kontrolInstance.delete(`/supplier-group-hourly-costs/${id}`);
};

export const patchSupplierGroupProcessCosts = async (data) => {
  return kontrolInstance.patch(`/supplier-group-hourly-costs`, data);
};

export const patchOrCreateSupplierSupplierGroup = ({companyId, supplierGroupKey}) => {
  return kontrolInstance.patch(`/supplier-supplier-groups`, {companyId, supplierGroupKey});
};

export const deleteSupplierSupplierGroup = (id) => {
  return kontrolInstance.delete(`/supplier-supplier-groups/${id}`);
};

export const createSupplierGroup = async (data) => {
  return kontrolInstance.post(`/supplier-groups`, data);
};

export const getUsers = async () => {
  const filter = {
    include: ['roles', 'company'],
    order: 'id DESC',
  };

  return kontrolInstance.get('/users', {params: {filter}});
};

export const patchUser = async (id, payload) => {
  return kontrolInstance.patch(`/users/${id}`, payload);
};

export const getRoles = async () => {
  return kontrolInstance.get('/roles');
};

export const getLanguages = async () => {
  return kontrolInstance.get('/languages');
};

export const getUnits = async () => kontrolInstance.get('/units');

export const getDataTypes = async () => kontrolInstance.get('/data-types');

export const openHubDeliverySlip = async (bundleId) => {

  const pdf = await kontrolInstance.get(`/bundles/${bundleId}/hub/delivery-slip`, {responseType: 'arraybuffer'});

  const url = window.URL.createObjectURL(new Blob([pdf.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `Lieferschein ${bundleId}.pdf`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const downloadBundleOrderedPartConfigFiles = (bundleId, orderedPartConfigId) => {
  const token = getToken();
  if (!token) handleUnauthorized();

  const url = `${process.env.REACT_APP_KOOPER_KONTROL_URL}/api/bundles/${bundleId}/bundle-ordered-part-configs/${orderedPartConfigId}/files?access_token=${token.substr(7)}`;
  const a = document.createElement('a');
  a.href = url;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const patchOrderedPartListInvoiceId = async (id, invoiceId) => {
  return kontrolInstance.patch(`/ordered-part-lists-base/${id}/invoice-id`, {
    invoiceId,
  });
};

export const getBundles = async (page = 1, limit = 25, order = 'id DESC', include, where) => {
  return kontrolInstance.get(`/bundle-teasers-base`, {
    params: {
      filter: {
        include,
        limit,
        order,
        page,
        where,
      },
    },
  });
};

export const getTranslations = async (order = 'id DESC') => {
  return kontrolInstance.get(`/translations`, {
    params: {
      filter: {
        order,
      },
    },
  });
};
export const patchTranslation = async (id, payload) => kontrolInstance.patch(`/translations/${id}`, payload);
export const postTranslation = async (payload) => kontrolInstance.post('/translations', payload);
export const deleteTranslation = async id => kontrolInstance.delete(`/translations/${id}`);

export const putBundle = async (id, payload) => {
  return kontrolInstance.patch(`/bundles/${id}`, payload);
};

export const updateBundleStatus = async (bundleId, newStatus) => {
  return kontrolInstance.post(`/bundles/${bundleId}/status/${newStatus}`);
};

export const updateOrderStatus = async (orderedPartListId, newStatus) => {
  return kontrolInstance.post(`/ordered-part-lists-base/${orderedPartListId}/status/${newStatus}`);
};

export const patchSupplierGroup = async (supplierGroupKey, key, value) => {
  return kontrolInstance.patch(`/supplier-groups`, {key: supplierGroupKey, [key]: value});
};
