import React, {Component} from 'react';
import {
  Grid,
  IconButton,
  Paper,
  TextField,
} from '@mui/material';
import {Add} from '@mui/icons-material';
import {
  getDataTypes,
  getUnits,
  getCompanyFeatures,
  patchOrCreateCompanyFeature,
} from 'services/kooperKontrol';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {Autocomplete} from '@mui/material';

class CompanyFeaturesTable extends Component {
  constructor(props) {
    super(props);

    this.defaultNewCompanyFeature = {
      key: '',
      dataTypeKey: null,
      unitKey: null,
      defaultValue: '',
    };

    this.state = {
      loading: true,
      companyFeatures: [],
      columns: [],
      units: [],
      dataTypes: [],
      newCompanyFeature: {
        key: '',
        dataTypeKey: null,
        unitKey: null,
        defaultValue: '',
      },
    };
  }

  async componentDidMount() {
    this.setState({loading: true});

    const [
      companyFeatures,
      units,
      dataTypes,
    ] = await Promise.all([
      getCompanyFeatures(),
      getUnits(),
      getDataTypes(),
    ]);

    await this.setState({
      loading: false,
      companyFeatures,
      units: units.map((x) => x.key),
      dataTypes: dataTypes.map((x) => x.key),
    });

    this.setState({
      columns: [
        {field: 'key', headerName: 'key', flex: 1, filterable: true, editable: false},
        {field: 'dataTypeKey', headerName: 'data type', type: 'singleSelect', valueOptions: [...dataTypes.map((x) => x.key)], flex: 1, filterable: true, editable: true},
        {field: 'unitKey', headerName: 'unit', type: 'singleSelect', valueOptions: [...units.map((x) => x.key), {value: null, label: 'NULL'}], flex: 1, filterable: true, editable: true},
        {field: 'defaultValue', headerName: 'default value', flex: 1, filterable: true, editable: true},
      ],
    });
  }

  updateCompanyFeaturesState = (companyFeature) => {
    if (!this.state.companyFeatures.some((x) => x.key === companyFeature.key)) {
      return this.setState({
        companyFeatures: [companyFeature, ...this.state.companyFeatures],
      });
    }

    return this.setState({
      companyFeatures: this.state.companyFeatures.map((x) =>
        x.key === companyFeature.key ? {...x, ...companyFeature} : x,
      ),
    });
  };

  updateNewCompanyFeatureState = (key, value) => {
    return this.setState({
      newCompanyFeature: {
        ...this.state.newCompanyFeature,
        ...{[key]: value},
      },
    });
  };

  processRowUpdate = async (newRow, oldRow) => {
    try {
      await patchOrCreateCompanyFeature(newRow);
      return newRow;
    } catch (e) {
      console.error(e);
      this.props.toast.error('🤔 Edit didn\'t work: ' + e.message);
      return oldRow;
    }
  };

  onNewCompanyFeatureClick = async () => {
    try {
      const _companyFeature = await patchOrCreateCompanyFeature(this.state.newCompanyFeature);
      await this.updateCompanyFeaturesState(_companyFeature);
      this.setState({
        newCompanyFeature: this.defaultNewCompanyFeature,
      });
    } catch (e) {
      console.error(e);
    }
  };

  validNewCompanyFeature = () => {
    return this.state.newCompanyFeature.key?.length && this.state.newCompanyFeature.dataTypeKey?.length;
  };

  render() {
    return (
      <Grid container justifyContent={'space-around'} alignItems={'flex-end'} spacing={2}>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label={'Key'}
            value={this.state.newCompanyFeature.key}
            onChange={(event) =>
              this.updateNewCompanyFeatureState('key', event.target.value)
            }
            size={'small'}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            fullWidth
            disableClearable
            options={this.state.dataTypes}
            value={this.state.newCompanyFeature.dataTypeKey}
            onChange={(e, v) => this.updateNewCompanyFeatureState('dataTypeKey', v)}
            renderInput={(params) => <TextField {...params} label={'Data Type'} size={'small'} />}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            fullWidth
            options={this.state.units}
            value={this.state.newCompanyFeature.unitKey}
            onChange={(e, v) => this.updateNewCompanyFeatureState('unitKey', v)}
            renderInput={(params) => <TextField {...params} label={'Unit'} size={'small'} />}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label={'Default value'}
            value={this.state.newCompanyFeature.defaultValue}
            size={'small'}
            onChange={(event) => this.updateNewCompanyFeatureState('defaultValue', event.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <IconButton
            color="primary"
            onClick={this.onNewCompanyFeatureClick}
            disabled={!this.validNewCompanyFeature()}
          >
            <Add />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Paper style={{height: 'calc(100vh - 250px)'}}>
            <DataGridPro
              columns={this.state.columns}
              rows={this.state.companyFeatures}
              rowCount={this.state.companyFeatures?.length}
              loading={this.state.loading}
              processRowUpdate={this.processRowUpdate}
              getRowId={(row) => row.key}
              density={'compact'}
              editMode={'row'}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default CompanyFeaturesTable;
