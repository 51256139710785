
import React, {Component} from 'react';
import BasePage from 'pages/BasePage';
import ProcessFeaturesTable from 'components/ProcessFeaturesTable/ProcessFeaturesTable';

class ProcessFeaturesPage extends Component {

  componentDidMount() {
    document.title = 'KMS Kontrol - Process Features';
  }

  render() {
    return (
      <BasePage title={'Process Features'} subtitle={'Referential Data'} requiredRole={'kontrol_processes'} {...this.props}>
        <ProcessFeaturesTable {...this.props}/>
      </BasePage>
    );
  }
}

export default ProcessFeaturesPage;
