import React from 'react';

// Route Views
import BundlesPage from 'pages/BundlesPage/BundlesPage';
import CompaniesPage from 'pages/CompaniesPage/CompaniesPage';
import CompanyFeaturesPage from 'pages/CompanyFeaturesPage/CompanyFeaturesPage';
import CustomerPage from 'pages/CustomerPage/CustomerPage';
import LandingPage from 'pages/LandingPage/LandingPage';
import OrdersPage from 'pages/OrdersPage/OrdersPage';
import ProcessFeaturesPage from 'pages/ProcessFeaturesPage/ProcessFeaturesPage';
import ProcessesPage from 'pages/ProcessesPage/ProcessesPage';
import SupplierGroupHourlyCostsPage from 'pages/SupplierGroupHourlyCostsPage/SupplierGroupHourlyCostsPage';
import SupplierPage from 'pages/SupplierPage/SupplierPage';
import TranslationsPage from 'pages/TranslationsPage/TranslationsPage';
import UsersPage from 'pages/UsersPage/UsersPage';
import PositionsPage from './pages/PositionsPage/PositionsPage';
import MaterialsPage from './pages/MaterialsPage/MaterialsPage';

//Icons
import {
  Category,
  Widgets,
  Translate,
  Store,
  ShoppingBag,
  Settings,
  PriceCheck,
  PrecisionManufacturing,
  ManageAccounts,
  Engineering,
  DragIndicator,
  Business,
  Build, LocalShipping, Receipt,
} from '@mui/icons-material';
import ShipmentsPage from './pages/ShipmentsPage/ShipmentsPage';
import TransactionDocumentsPage from './pages/TransactionDocumentsPage/TransactionDocumentsPage';

const routes = [

  {
    key: 'Welcome',
    path: '/welcome',
    component: LandingPage,
  },
  {
    key: 'Users',
    icon: <ManageAccounts />,
    path: '/user-management',
    component: UsersPage,
  },
  {
    key: 'Companies',
    icon: <Business />,
    path: '/companies',
    component: CompaniesPage,
  },
  {
    key: 'Customers',
    icon: <Store />,
    path: '/customers',
    component: CustomerPage,
  },
  {
    key: 'Suppliers',
    icon: <Engineering />,
    path: '/suppliers',
    component: SupplierPage,
  },
  {
    key: 'Orders',
    icon: <ShoppingBag />,
    path: '/orders',
    component: OrdersPage,
  },
  {
    key: 'Positions',
    icon: <Category />,
    path: '/positions',
    component: PositionsPage,
  },
  {
    key: 'Shipments',
    icon: <LocalShipping />,
    path: '/shipments',
    component: ShipmentsPage,
  },
  {
    key: 'Bundles',
    icon: <DragIndicator />,
    path: '/bundles',
    component: BundlesPage,
  },
  {
    key: 'Transaction Documents',
    icon: <Receipt />,
    path: '/transaction-documents',
    component: TransactionDocumentsPage,
  },
  {
    key: 'Referential Data',
    icon: <Settings />,
    path: '/referential',
    items: [
      {
        key: 'Processes',
        icon: <PrecisionManufacturing />,
        path: '/processes',
        component: ProcessesPage,
      },
      {
        key: 'Process Features',
        icon: <Build />,
        path: '/process-features',
        component: ProcessFeaturesPage,
      },
      {
        key: 'Materials',
        icon: <Widgets />,
        path: '/materials',
        component: MaterialsPage,
      },
      {
        key: 'Supplier Groups',
        icon: <PriceCheck />,
        path: '/supplier-group-hourly-costs',
        component: SupplierGroupHourlyCostsPage,
      },
      {
        key: 'Company Features',
        icon: <Business />,
        path: '/company-features',
        component: CompanyFeaturesPage,
      },
      {
        key: 'Translations',
        icon: <Translate />,
        path: '/translations',
        component: TranslationsPage,
      },
    ],
  },

];

export default routes;
