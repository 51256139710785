import React, {Component} from 'react';
import {Container}        from 'shards-react';
import AppLogo            from 'assets/img/app_logo.svg';

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container fluid style={{height: 'calc(100vh - 120px)'}}>
        <div className='row' style={{overflow: 'auto', height: '100%', display: 'flex', justifyContent: 'center'}}>

          <div
            className='col-sm-12 col-md-6  .d-sm-none .d-md-block text-center  justify-content-center'
            style={{height: '100%'}}
          >
            <img src={AppLogo} className='img-fluid ' alt='KREATIZE Kaktus' style={{height: '40%', margin: '80px 0px'}} />
            <h1 style={{fontSize: '4.052rem'}}>Welcome back{this.props.user?.firstName ? ` ${this.props.user.firstName}` : ''}!</h1>
          </div>
        </div>
      </Container>
    );
  }
};
export default LandingPage;
