import React, { Component } from 'react';
import UsersTable from 'components/UsersTable/UsersTable'
import BasePage from "pages/BasePage";

class UsersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  componentDidMount() {
    document.title = 'KMS Kontrol - Users';
  }

  render() {
    return (
      <BasePage
        loading={this.state.loading}
        title={'Users'}
        subtitle={'Referential Data'}
        requiredRole={'kontrol_users'}
        {...this.props}
      >
        <UsersTable/>
      </BasePage>
    );
  }
}

export default UsersPage
