import React, {Component} from 'react';
import BasePage from 'pages/BasePage';
import CompanyFeaturesTable from 'components/CompanyFeaturesTable/CompanyFeaturesTable';

class CompanyFeaturesPage extends Component {

  componentDidMount() {
    document.title = 'KMS Kontrol - Company Features';
  }

  render() {
    return (
      <BasePage title={'Company Features'} subtitle={'Referential Data'} requiredRole={'kontrol_company_features'} {...this.props}>
        <CompanyFeaturesTable {...this.props}/>
      </BasePage>
    );
  }
}

export default CompanyFeaturesPage;
