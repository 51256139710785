import React, {useEffect, useState, Suspense} from 'react';
import config from 'config';
import {getToken, getUserInfo} from './utils/userHelpers';
import DownloadPage from './pages/DownloadPage/DownloadPage';
import Loading from './components/Loading/Loading';
import {BrowserRouter as Router, Route, Switch, useHistory} from 'react-router-dom';

import 'styles/shards-dashboards.1.1.0.min.css';
import AppLayout from './layout/AppLayout';
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterMoment} from '@mui/x-date-pickers-pro/AdapterMoment';

const App = () => {
  const hist = useHistory();

  document.title = config.appName;
  const [user, setUser] = useState<any | null>(null);
  const token = getToken();

  const getUser = async () => {
    try {
      const user = await getUserInfo();
      setUser(user);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  if (!token) {
    window.location.assign(`${process.env.REACT_APP_KAUTH_URL}/login?goto=${window.location.href}`);
  }

  if (!user) {
    return (
      <Loading />
    );
  }

  return (
    <Suspense fallback={<Loading />}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Router>
          <Switch>
            <Route path="/delivery-slip" exact component={props => <DownloadPage {...props} type={'delivery-slip'} />} />
            <Route path="/files" exact component={props => <DownloadPage {...props} type={'files'} />} />
            <Route render={() => <AppLayout path="/" history={hist} user={user} />} />
          </Switch>
        </Router>
      </LocalizationProvider>
    </Suspense>
  );
};
export default App;
