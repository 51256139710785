import React, {Component} from 'react';
import {Grid, Paper, TextField, IconButton, Box, Autocomplete} from '@mui/material';
import {Add, Delete} from '@mui/icons-material';
import {
  getTranslations,
  patchTranslation,
  postTranslation,
  deleteTranslation,
  getLanguages,
} from 'services/kooperKontrol';
import Swal from 'sweetalert2';
import {DataGridPro, GridToolbarContainer, GridToolbarQuickFilter} from '@mui/x-data-grid-pro';

class CustomToolbar extends Component {
  render() {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }
}

class TranslationsTable extends Component {
  constructor(props) {
    super(props);

    this.categories = [
      'process',
      'process_feature',
    ];

    this.defaultNewTranslation = {
      category: 'process',
      key: '',
      language: {
        key: 'de',
        emoji: '🇩🇪',
      },
      value: '',
    };

    this.state = {
      loading: true,
      columns: [],
      translations: [],
      languages: [],
      newTranslation: this.defaultNewTranslation,
    };
  }

  async componentDidMount() {
    this.setState({loading: true});

    const _translations = await getTranslations(['category ASC', 'key ASC', 'language ASC']);
    const _languages = await getLanguages();

    this.setState({
      loading: false,
      translations: _translations,
      languages: _languages,
      columns: [
        {field: 'category', headerName: 'Category', flex: 0.5, type: 'singleSelect', valueOptions: this.categories, filterable: true, editable: true},
        {field: 'key', headerName: 'Key', flex: 1, filterable: true, editable: true},
        {
          field: 'language', headerName: 'Language', type: 'singleSelect', flex: 0.25, filterable: true, editable: true,
          valueOptions: _languages.map((x) => ({value: x.key, label: x.emoji})),
        },
        {field: 'value', headerName: 'Translation', flex: 1, filterable: true, editable: true},
        {
          field: 'actions', type: 'actions', headerName: 'actions', editable: false,
          renderCell: params => {
            return (
              <IconButton onClick={() => this.onDeleteTranslation(params.row.id)}>
                <Delete />
              </IconButton>
            );
          },
        },
      ],
    });
  }

  onDeleteTranslation = async (id) => {
    const {isConfirmed} = await Swal.fire({
      title: 'Are you sure you want to delete this translation?',
      icon: 'warning',
      confirmButtonText: 'Yes, delete.',
      showCancelButton: true,
      reverseButtons: true,
    });

    if (!isConfirmed) return;

    try {
      await deleteTranslation(id);
      this.updateTranslationsState({id}, true);
    } catch (e) {
      console.error(e);
    }
  };

  updateTranslationsState = (translation, del = false) => {
    if (del) {
      return this.setState({
        translations: this.state.translations.filter(t => t.id !== translation.id),
      });
    }

    if (!this.state.translations.some(x => x.id === translation.id)) {
      return this.setState({
        translations: [translation, ...this.state.translations],
      });
    }
    return this.setState({
      translations: this.state.translations.map(x => (x.id === translation.id ? {...x, ...translation} : x)),
    });
  };

  updateNewTranslationState = (key, value) => {
    return this.setState({
      newTranslation: {...this.state.newTranslation, ...{[key]: value}},
    });
  };

  processRowUpdate = async (newRow) => {
    return await patchTranslation(newRow.id, {
      category: newRow.category,
      key: newRow.key?.trim() || null,
      language: newRow.language,
      value: newRow.value?.trim() || null,
    });
  };

  handleRowUpdateError = (error) => {
    console.error(error);
    this.props.toast.error(`⛈ Houston, we have a problem: ${error.response?.data?.details}`);
  };

  onNewTranslationClick = async () => {
    try {
      const _translation = await postTranslation({...this.state.newTranslation, language: this.state.newTranslation.language.key});
      await this.updateTranslationsState(_translation);
      return this.setState({
        newTranslation: this.defaultNewTranslation,
      });
    } catch (e) {
      console.error(e);
    }
  };

  validNewTranslation = () => {
    const _newTranslation = this.state.newTranslation;
    if (!_newTranslation.key.length) return false;
    return _newTranslation.value.length;

  };

  render() {
    return (
      <Grid container alignItems="flex-end" spacing={2}>
        <Grid item xs={2} ml={1}>
          <Autocomplete
            fullWidth
            disableClearable
            options={this.categories}
            value={this.state.newTranslation.category}
            onChange={(e, v) => this.updateNewTranslationState('category', v)}
            renderInput={(params) => <TextField {...params} variant={'standard'} label={'Category'} size={'small'} />}
          />
        </Grid>
        <Grid item xs={1}>
          <Autocomplete
            fullWidth
            disableClearable
            options={this.state.languages}
            getOptionKey={option => option.key}
            getOptionLabel={option => option.emoji}
            isOptionEqualToValue={(option, value) => option.key === value.key}
            value={this.state.newTranslation.language}
            onChange={(e, v) => this.updateNewTranslationState('language', v)}
            renderInput={(params) => <TextField {...params} variant={'standard'} label={'Language'} size={'small'} />}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={'Key'}
            fullWidth
            variant={'standard'}
            value={this.state.newTranslation.key}
            onChange={event => this.updateNewTranslationState('key', event.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label={'Translation'}
            fullWidth
            variant={'standard'}
            value={this.state.newTranslation.value}
            onChange={event => this.updateNewTranslationState('value', event.target.value)}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            color="primary"
            onClick={this.onNewTranslationClick}
            disabled={!this.validNewTranslation()}
          >
            <Add />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{height: 'calc(100vh - 250px)'}}>
            <Box sx={{width: '100%', height: '100%'}}>
              <DataGridPro
                sx={{height: '100%'}}
                columns={this.state.columns}
                rows={this.state.translations}
                rowCount={this.state.totalItemCount}
                loading={this.state.loading}
                slots={{
                  toolbar: CustomToolbar,
                }}
                processRowUpdate={this.processRowUpdate}
                onProcessRowUpdateError={this.handleRowUpdateError}
                editMode={'row'}
                density={'compact'}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default TranslationsTable;
