import React, {Component} from 'react';
import BasePage from 'pages/BasePage';
import SupplierHourlyCostsTable from 'components/SupplierHourlyCosts/SupplierHourlyCostsTable';
import {Card, CardBody, Col, Row} from "shards-react";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import {getSuppliers} from "../../services/kooperKontrol";


class SupplierPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      company: null,
    };
  }

  componentDidMount() {
    document.title = 'KMS Kontrol - Suppliers';
  }

  getCompanies = async (inputValue) => {
    try {
      const companies = await getSuppliers(inputValue);
      return companies.map(company => {
        return {
          ...company,
          label: company.companyName,
          value: company.id,
        };
      });
    }
    catch(err) {
      this.setState({error: err.response.status});
    }
  };

  render() {
    return (
      <BasePage title={'Suppliers'} subtitle={'Supplier Specific Pricing'} requiredRole={'kontrol_pricing'} {...this.props}>
        <div>
          <Row>
            <Col>
              <Card small className='mb-4'>
                <CardBody>
                  <Row>
                    <div className='async-search-wrapper'>
                      <AsyncSelect
                        placeholder={'Select a Supplier...'}
                        loadOptions={this.getCompanies}
                        onChange={(e) => {
                          this.setState({company: e});
                        }}
                        value={this.state.company}
                      />
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {
            this.state.company &&
            <SupplierHourlyCostsTable {...this.props} company={this.state.company}/>
          }
        </div>
      </BasePage>
    );
  }
}

export default SupplierPage;
