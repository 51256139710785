import React from 'react';

import BaseModal from 'components/Modals/BaseModal';

import {Button, FormGroup, FormInput} from 'shards-react';
import {createSupplierGroup}          from '../../../services/kooperKontrol';


export default class CreateSupplierGroupModal extends React.Component {

  constructor(props) {

    super(props);
    this.state = {
      stage: 'content',
      value: '',
    };
  }

  toggle = () => {
    this.setState({stage: 'content', value: ''});
    this.props.onToggle();
  };

  createSupplierGroup = () => {
    createSupplierGroup({key: this.state.value})
      .then(() => this.setState({stage: 'success'}))
      .catch(() => this.setState({stage: 'failure'}));

  };

  content() {

    return (
      <React.Fragment>
        <FormGroup>
          <label>Supplier-Group Key</label>
          <FormInput
            id='#key'
            onChange={e => this.setState({value: e.target.value})}
            placeholder='New Supplier Group'
          />
        </FormGroup>
        <FormGroup>

        </FormGroup>
        <Button onClick={() => this.createSupplierGroup()} theme={'warning'} outline block>
          Create a new Supplier-Group
        </Button>
      </React.Fragment>
    );

  }

  render() {

    const {stage} = this.state;

    return (

      <div>
        <BaseModal
          stage={stage}
          open={this.props.open}
          content={this.content()}
          successMessage='The Supplier Group has been created Successfully'
          failureMessage='Something went wrong. Call your favorite tech person at Kreatize 📞'
          infoMessage=''
          buttonTheme='success'
          buttonText='Create a new Supplier-Group'
          modalTitle='New Supplier Group'
          customprocessClass={'modal-40w'}
          modalBodyprocessClassName={'pt-10'}
          onToggle={this.toggle}
          noButton
          centered
          modalStyle={{marginTop: '-90px'}}
          t={() => {}}
        />
      </div>

    );
  }
}