import React, {Component} from 'react';
import BasePage from 'pages/BasePage';
import CompaniesTable from '../../components/CompaniesTable/CompaniesTable';
import {Grid} from '@mui/material';

class CompaniesPage extends Component {
  componentDidMount() {
    document.title = 'KMS Kontrol - Companies';
  }

  render() {
    return (
      <BasePage
        title={'Companies'}
        subtitle={'Company Features'}
        requiredRole={'kontrol_companies'}
        {...this.props}
      >
        <Grid container spacing={2} sx={{height: 'calc(100vh - 200px)'}}>
          <Grid item xs={12} sx={{height: '100%'}}>
            <CompaniesTable />
          </Grid>
        </Grid>

      </BasePage>
    );
  }
}

export default CompaniesPage;
