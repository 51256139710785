import React, {Component} from 'react';
import {Alert, Paper, Snackbar, Tooltip, Unstable_Grid2 as Grid} from '@mui/material';
import {DataGridPro, getGridStringOperators, GRID_CHECKBOX_SELECTION_COL_DEF, GridActionsCellItem, GridColDef, GridFilterModel, GridLogicOperator, GridRowSelectionModel, GridSortModel, GridToolbar} from '@mui/x-data-grid-pro';
import {Prisma} from '@prisma/client';
import {downloadShipmentDeliverySlip, getShipments} from '../../services/knestKontrol';
import {doesNotContainOperator, doesNotEqualOperator, generateOrderByFromSortModel, generateWhereFromFilterModel, toDisplayDatetime} from '../../utils/formatHelpers';
import {Carriers, ShippingStatuses} from '../../types/ReferentialData';
import {Download, Edit, Place} from '@mui/icons-material';
import EditShipmentModal from '../Modals/EditShipmentModal/EditShipmentModal';
import {GridCallbackDetails} from '@mui/x-data-grid/models/api';
import _ from 'lodash';
import {Link} from 'react-router-dom';

export type ShipmentTeaser = Prisma.ShipmentTeaserGetPayload<{
  include: {
    fromCompany: true;
    toCompany: true;
    shipmentBundleOrderedPartConfigs: {
      include: {
        bundleOrderedPartConfigTeaser: {
          include: {
            orderedPartConfigTeaser: {
              include: {
                orderedPart: true;
                orderedPartListTeaser: true;
              }
            }
          }
        }
      }
    };
  }
}>

type Props = {}

type State = {
  shipments: ShipmentTeaser[],
  loading: boolean,
  nextPage: number,
  totalItemCount: number,
  itemsPerPage: number,
  filterModel: GridFilterModel,
  backendFilter: Prisma.ShipmentTeaserFindManyArgs['where'],
  sortModel: GridSortModel,
  selectedRows: GridRowSelectionModel,
  snackbarOpen: boolean,
  snackbarSeverity: 'success' | 'error' | 'warning' | 'info',
  snackbarMessage: string,
  editShipmentModalOpen: boolean,
  editModalShipment: ShipmentTeaser | null,
}

class ShipmentsTable extends Component<Props, State> {
  columns: GridColDef<ShipmentTeaser>[];
  columnTypes: { [key: string]: string };

  constructor(props) {
    super(props);

    this.columns = [
      {field: 'id', type: 'number', headerName: 'ID', width: 100, sortable: false},
      {
        field: 'fromCompany.companyName', headerName: 'Shipment From', width: 250, valueGetter: (_value, row) => row.fromCompany?.companyName,
        filterOperators: [...getGridStringOperators(), doesNotContainOperator, doesNotEqualOperator],
      },
      {
        field: 'toCompany.companyName', headerName: 'Shipment To', width: 250, valueGetter: (_value, row) => row.toCompany?.companyName,
        filterOperators: [...getGridStringOperators(), doesNotContainOperator, doesNotEqualOperator],
      },
      {
        field: 'deliveryAddress', headerName: 'Delivery Address (hover to view)', width: 150, filterable: false, sortable: false, align: 'center',
        valueGetter: (_value, row) => row.toAddressLine1 + ', ' + row.toAddressLine2 + ', ' + row.toCity + ' ' + row.toZipCode + ', ' + row.toState + ' ' + row.toCountryKey,
        renderCell: ({value}) => <Tooltip title={value}><Place /></Tooltip>,
      },
      {field: 'positionsCount', type: 'number', headerName: 'Number of Positions', width: 150},
      {field: 'weightInKg', type: 'number', headerName: 'Weight', width: 150, valueFormatter: (value) => value ? `${value} kg` : ''},
      {field: 'statusKey', type: 'singleSelect', headerName: 'Current Status', width: 150, valueOptions: ShippingStatuses.map(({statusKey, translation}) => ({value: statusKey, label: translation}))},
      {field: 'transitTimeInDays', type: 'number', headerName: 'Transit Time', width: 150, valueFormatter: (value) => value !== null ? `${value} day(s)` : ''},
      {field: 'carrierKey', type: 'singleSelect', headerName: 'Carrier', width: 200, valueOptions: Carriers.map(({carrierKey, translation}) => ({value: carrierKey, label: translation}))},
      {
        field: 'trackingNumber', headerName: 'Tracking Number', width: 200,
        renderCell: (params) => {
          if (params.row.trackingUrl) {
            return <Link to={{pathname: params.row.trackingUrl}} target={'_blank'}>{params.value}</Link>;
          }

          return params.value;
        },
      },
      {field: 'createdAt', headerName: 'Created At', type: 'dateTime', width: 200, valueGetter: (value: string) => new Date(value), valueFormatter: (value) => toDisplayDatetime(value)},
      {
        field: 'actions', headerName: 'Actions', width: 80, type: 'actions',
        getActions: (params) => {
          return [
            <GridActionsCellItem
              label={'Edit'}
              icon={<Tooltip title={'Edit'}><Edit /></Tooltip>}
              onClick={() => this.setState({editShipmentModalOpen: true, editModalShipment: params.row})}
            />,
            <GridActionsCellItem
              label={'Download Delivery Slip'}
              icon={<Tooltip title={'Download Delivery Slip'}><Download /></Tooltip>}
              onClick={() => downloadShipmentDeliverySlip(params.row.id)}
            />,
          ];
        },
      },
    ];
    this.columnTypes = this.columns.reduce((acc, {field, type}) => {
      acc[field] = type;
      return acc;
    }, {});

    const queryParams = new URLSearchParams(document.location.hash?.substring(1));

    this.state = {
      shipments: [],
      loading: true,
      nextPage: 1,
      totalItemCount: 0,
      itemsPerPage: 100,
      sortModel: JSON.parse(queryParams.get('sortModel') || 'null') as GridSortModel || [],
      filterModel: JSON.parse(queryParams.get('filterModel') || 'null') as GridFilterModel || {logicOperator: GridLogicOperator.And, items: []},
      backendFilter: generateWhereFromFilterModel(
        JSON.parse(queryParams.get('filterModel') || 'null') as GridFilterModel || {logicOperator: GridLogicOperator.And, items: []},
        this.columnTypes,
      ),
      selectedRows: [],
      snackbarOpen: false,
      snackbarSeverity: 'success',
      snackbarMessage: '',
      editShipmentModalOpen: false,
      editModalShipment: null,
    };
  }

  async componentDidMount() {
    await this.loadRows();
  }

  loadRows = async () => {
    this.setState({loading: true});

    try {
      const {page, pagination: {totalItemCount}} = await getShipments(
        this.state.nextPage,
        this.state.itemsPerPage,
        [...generateOrderByFromSortModel(this.state.sortModel), {id: 'desc'}],
        {
          fromCompany: true,
          toCompany: true,
          shipmentBundleOrderedPartConfigs: {
            include: {
              bundleOrderedPartConfigTeaser: {
                include: {
                  orderedPartConfigTeaser: {
                    include: {
                      orderedPart: true,
                      orderedPartListTeaser: true,
                    },
                  },
                },
              },
            },
          },
        },
        this.state.backendFilter,
      );

      this.setState({
        shipments: this.state.nextPage === 1 ? page : this.state.shipments.concat(page),
        nextPage: this.state.nextPage + 1,
        totalItemCount,
      });
    } catch (error) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Failed to load shipments',
      });
    }

    this.setState({loading: false});
  };

  updateDocumentHash = () => {
    document.location.hash = `filterModel=${encodeURIComponent(JSON.stringify(this.state.filterModel))}&sortModel=${encodeURIComponent(JSON.stringify(this.state.sortModel))}`;
  };

  handleFilterModelChange = (filterModel: GridFilterModel, details: GridCallbackDetails<'filter'>) => {
    const newBackendFilter = generateWhereFromFilterModel(filterModel, this.columnTypes);
    const backendFilterChanged = !_.isEqual(this.state.backendFilter, newBackendFilter);

    this.setState({
      filterModel,
      nextPage: details.reason && backendFilterChanged ? 1 : this.state.nextPage,
      backendFilter: newBackendFilter,
    }, async () => {
      (details.reason && backendFilterChanged) && await this.loadRows();
      this.updateDocumentHash();
    });
  };

  handleSortModelChange = (sortModel: GridSortModel) => {
    if (this.state.sortModel !== sortModel) {
      this.setState({
        sortModel,
        nextPage: 1,
      }, async () => {
        await this.loadRows();
        this.updateDocumentHash();
      });
    }
  };

  updateShipmentInState = (shipment: ShipmentTeaser) => {
    this.setState({shipments: this.state.shipments.map((s) => s.id === shipment.id ? shipment : s)});
  };

  render() {
    return <Grid container>
      <Grid xs={12}>
        <Paper style={{height: 'calc(100vh - 180px)'}}>
          <DataGridPro<ShipmentTeaser>
            columns={this.columns}
            rows={this.state.shipments}
            loading={this.state.loading}
            rowCount={this.state.totalItemCount}
            onRowsScrollEnd={this.loadRows}
            slots={{toolbar: GridToolbar}}

            filterMode={'server'}
            filterModel={this.state.filterModel}
            onFilterModelChange={this.handleFilterModelChange}
            filterDebounceMs={1500}
            headerFilters
            headerFilterHeight={80}

            sortingMode={'server'}
            sortModel={this.state.sortModel}
            onSortModelChange={this.handleSortModelChange}

            initialState={{
              density: 'compact',
              sorting: {sortModel: this.state.sortModel},
              filter: {filterModel: this.state.filterModel},
              pinnedColumns: {left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'id'], right: ['actions']},
            }}

            checkboxSelection
            rowSelectionModel={this.state.selectedRows}
            onRowSelectionModelChange={(newSelection) => this.setState({selectedRows: newSelection})}
          />
        </Paper>
        <EditShipmentModal
          shipment={this.state.editModalShipment}
          onClose={() => this.setState({editShipmentModalOpen: false, editModalShipment: null})}
          updateShipmentInState={this.updateShipmentInState}
          open={this.state.editShipmentModalOpen}
        />
        <Snackbar
          open={this.state.snackbarOpen}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          autoHideDuration={4000}
          onClose={(_event, reason) => reason !== 'clickaway' && this.setState({snackbarOpen: false})}
        >
          <Alert onClose={() => this.setState({snackbarOpen: false})} severity={this.state.snackbarSeverity} sx={{width: '100%'}}>
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>;
  }
}

export default ShipmentsTable;
