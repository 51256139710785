import React, {useEffect} from 'react';
import {Row} from 'shards-react';
import ErrorLogo from 'assets/img/kaktus404.svg';

import './style.css';
import Swal from 'sweetalert2';

const ErrorPage = (props) => {
  let statusCode = props.statusCode || props.match?.params?.statusCode;
  if (Number.isNaN(parseFloat(statusCode))) {
    statusCode = '???';
  }

  const errorPageMessage = {
    'error_page_modal_title': 'Oh no, it looks like we have some internal issues...',
    'error_page_modal_subtitle': 'Please help us to understand what happened so that we can fix it right away. Any detail matters …',
    'error_page_modal_placeholder': 'I clicked on x and entered the number y...',
    'error_page_button_to_dashboard': 'Back to Dashboard',
    'error_page_button_reload': 'Reload this page',
    'error_404_title': 'Page not found',
    'error_500_title': 'Internal Server Error',
    'error_???_title': 'Unknown Error',
    'error_404_subtitle': 'The page you are looking for doesn\'t exist or has been moved.',
    'error_500_subtitle': 'Oups! An error occurred on the server side and we are working to fix the problem!',
    'error_???_subtitle': 'Feel free to contact us if the problem persists',
  };

  useEffect(() => {
    if (['500', '???'].includes(statusCode) && props.sentryEventId) {
      Swal.fire({
        input: 'textarea',
        icon: 'error',
        title: errorPageMessage['error_page_modal_title'],
        text: errorPageMessage['error_page_modal_subtitle'],
        inputPlaceholder: errorPageMessage['error_page_modal_placeholder'],
        showCancelButton: true,
        width: '60%',
      }).then();
    }
  });

  return (
    <div style={{marginTop: '4vh', width: '100%'}}>
      <Row style={{marginTop: '20px', display: 'flex', justifyContent: 'center'}}>
        <img style={{width: '700px'}} alt="Error" src={ErrorLogo} />
      </Row>


      <Row style={{marginTop: '40px'}}>
        <div className="notfound">
          <div className="notfound-404">
            <h1>
              <span>{statusCode[0]}</span>
              <span style={{color: '#43CCAD'}}>{statusCode[1]}</span>
              <span>{statusCode[2]}</span>
            </h1>
          </div>
        </div>
      </Row>


      <div>

        <Row style={{display: 'flex', justifyContent: 'center', marginTop: '40px'}}>
          <h3>
            {errorPageMessage[`error_${statusCode}_title`]}
          </h3>
        </Row>
        <Row style={{display: 'flex', justifyContent: 'center'}}>
          <h4>
            {errorPageMessage[`error_${statusCode}_subtitle`]}
          </h4>
        </Row>
      </div>

    </div>
  );
};

export default ErrorPage;
