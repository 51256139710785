import React, {Component} from 'react';
import BasePage from '../BasePage';
import ShipmentsTable from 'components/ShipmentsTable/ShipmentsTable';

class ShipmentsPage extends Component {
  componentDidMount() {
    document.title = 'KMS Kontrol - Shipments';
  }

  render() {
    return (
      <BasePage title={'Shipments'} requiredRole={'kontrol_hub'} {...this.props}>
        <ShipmentsTable  {...this.props} />
      </BasePage>
    );
  }
}

export default ShipmentsPage;
