import React, {Component} from 'react';
import BasePage from 'pages/BasePage';
import TranslationsTable from 'components/TranslationsTable/TranslationsTable';

class TranslationsPage extends Component {

  componentDidMount() {
    document.title = 'KMS Kontrol - Translations';
  }

  render() {
    return (
      <BasePage title={'Translations'} subtitle={'Referential Data'} requiredRole={'kontrol_translations'} {...this.props}>
        <TranslationsTable {...this.props} />
      </BasePage>
    );
  }
}

export default TranslationsPage;
