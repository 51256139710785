import React, {Component} from 'react';
import Swal from 'sweetalert2';
import {formatCurrency, generateWhereFromFilterModel, toDisplayDate, toDisplayDatetime} from 'utils/formatHelpers';
import './OrdersTable.css';
import _ from 'lodash';
import {Grid, Paper, Tooltip} from '@mui/material';
import {DataGridPro, GridActionsCellItem, GridColDef, GridEventLookup, GridFilterModel, GridLogicOperator, GridSortModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton} from '@mui/x-data-grid-pro';
import {Download, ReceiptLong, Visibility, Edit, Cancel} from '@mui/icons-material';
import {cancelOrder, createOrderConfirmation, getOrders as knestGetOrders} from '../../services/knestKontrol';
import {getToken} from '../../utils/userHelpers';
import {Prisma} from '@prisma/client';
import EditOrderModal from '../Modals/EditOrderModal/EditOrderModal';
import {OrderStatuses} from '../../types/ReferentialData';
import {GridCallbackDetails} from '@mui/x-data-grid/models/api';
import {format as formatDate} from 'date-fns';

type Order = Prisma.OrderedPartListTeaserGetPayload<{
  include: {
    customerTransactionDocuments: true,
    orderedPartConfigTeasers: {
      include: {
        customerTransactionDocumentLineItems: {
          include: {
            customerTransactionDocument: true,
          },
        }
        orderedPartConfigStatusLogs: true,
        orderedPart: {
          include: {
            orderedPartPartFeatures: true,
          },
        },
        bundleOrderedPartConfigTeasers: {
          include: {
            shipmentBundleOrderedPartConfigs: true,
            bundle: true,
          },
        },
      },
    },
    company: {
      include: {
        users: true,
      },
    },
  },
}>

type Props = {};

type State = {
  loading: boolean,
  orders: Order[],
  totalItemCount: number,
  nextPage: number,
  itemsPerPage: number,
  sortModel: GridSortModel,
  filterModel: GridFilterModel,
  backendFilter: Prisma.OrderedPartListTeaserFindManyArgs['where'],
  editOrderId: number | null,
  editModalOpen: boolean,
  editModalOrder: Order | null,
};

class OrdersTable extends Component<Props, State> {
  columns: GridColDef<Order>[];
  columnTypes: { [key: string]: string };

  constructor(props: Props | Readonly<Props>) {
    super(props);
    const orderedStatuses = ['ordered', 'delayed', 'shipped', 'partially_delivered', 'delivered', 'invoice_sent', 'invoice_paid'];

    this.columns = [
      {field: 'kmsId', headerName: '#', width: 100, sortable: true, filterable: true},
      {field: 'name', headerName: 'Order Name', width: 250, sortable: true, filterable: true},
      {field: 'companyName', headerName: 'Company Name', width: 200, sortable: true, filterable: true},
      {
        field: 'statusKey',
        type: 'singleSelect',
        headerName: 'Status',
        width: 220,
        sortable: true,
        filterable: true,
        valueOptions: OrderStatuses.map(status => ({value: status.statusKey, label: status.translation})),
      },
      {
        field: 'totalCost',
        headerName: 'Total',
        width: 150,
        sortable: true,
        filterable: true,
        valueGetter: (value) => formatCurrency(value),
      },
      {
        field: 'numberOfParts',
        headerName: '# placed parts ',
        width: 180,
        sortable: false,
        filterable: false,
        valueGetter: (_value, row) => `${row.orderedPartConfigTeasers?.filter(opc => opc.bundleOrderedPartConfigTeasers?.some(bopct => orderedStatuses.includes(bopct.bundle!.statusKey!)))?.length} / ${row.orderedPartConfigTeasers?.length}`,
      },
      {
        field: 'costOfPlacedParts',
        headerName: 'Costs of placed parts',
        width: 200,
        sortable: false,
        filterable: false,
        valueGetter: (_value, row) => {
          const uniqueParts = _.uniqBy(row.orderedPartConfigTeasers.map(opc => opc.bundleOrderedPartConfigTeasers.find(bopct => orderedStatuses.includes(bopct.bundle?.statusKey!))?.bundle), 'bundleKid');
          const cogs = uniqueParts.reduce((acc, b) => acc + parseFloat(b?.price as unknown as string), 0);
          return formatCurrency(cogs);
        },
      },
      {
        field: 'currentCm1',
        headerName: 'Current CM1',
        width: 180,
        sortable: false,
        filterable: false,
        valueGetter: (_value, row) => {
          const uniqueParts = _.uniqBy(row.orderedPartConfigTeasers?.map(opc => opc.bundleOrderedPartConfigTeasers?.find(bopct => orderedStatuses.includes(bopct.bundle?.statusKey!))?.bundle), 'bundleKid');
          const cogs = uniqueParts.reduce((acc, b) => acc + parseFloat(b?.price as unknown as string), 0);
          const customerPrice = row.totalCost!;
          const cm1 = ((customerPrice - cogs) / customerPrice * 100);
          return Number.isNaN(cm1) ? '' : `${cm1.toFixed(2)}%`;
        },
      },
      {
        field: 'logisticsCosts',
        headerName: 'Logistics Costs',
        width: 180,
        sortable: false,
        filterable: false,
        valueGetter: (_value, row) => {
          const logisticCosts = row.orderedPartConfigTeasers?.reduce((acc, opc) => {
            const opcWeight = opc.orderedPart?.orderedPartPartFeatures.find(oppf => oppf.partFeatureKey === 'weight')?.value || '';
            return acc + parseFloat(opcWeight) / 1000 * opc.batchSize! * 10;
          }, 0);
          return logisticCosts ? formatCurrency(logisticCosts) : '';
        },
      },
      {field: 'exportedAt', headerName: 'Ordered On', type: 'date', width: 200, sortable: true, filterable: true, valueFormatter: (value: string) => value ? toDisplayDatetime(value) : ''},
      {field: 'estimatedDeliveryDate', headerName: 'Estimated Delivery Date', type: 'date', width: 150, sortable: true, filterable: true, valueFormatter: (value: string) => value ? toDisplayDate(value) : ''},
      {field: 'desiredDeliveryDate', headerName: 'Desired Delivery Date', type: 'date', width: 150, sortable: true, filterable: true, valueFormatter: (value: string) => value ? toDisplayDate(value) : ''},
      {field: 'expectedDeliveryDate', headerName: 'Expected (Calculated) Delivery Date', type: 'date', width: 150, sortable: true, filterable: true, valueFormatter: (value: string) => value ? toDisplayDate(value) : ''},
      {field: 'delayed', type: 'boolean', headerName: 'Delayed?', width: 80, sortable: true, filterable: true},
      {
        field: 'actions', headerName: 'Actions', width: 120, type: 'actions', align: 'left',
        getActions: (params) => {
          const actions = [
            <GridActionsCellItem
              icon={<Tooltip title={'View in KMS'}><Visibility /></Tooltip>}
              label={'View in KMS'}
              onClick={() => window.open(`${process.env.REACT_APP_KMS_URL}/orders/${params.row.id}`)}
            />,
            <GridActionsCellItem
              icon={<Tooltip title={'Edit this Order'}><Edit /></Tooltip>}
              label={'Edit Order'}
              onClick={() => this.setState({editOrderId: params.row.id, editModalOpen: true, editModalOrder: params.row}, this.updateDocumentHash)}
            />,
          ];

          if (params.row.orderConfirmationId || params.row.customerTransactionDocuments.some(ctd => ctd.documentType === 'order_confirmation')) {
            actions.push(
              <GridActionsCellItem
                icon={<Download />}
                label={'Download Order Confirmation (English)'}
                onClick={() => window.open(
                  `${process.env.REACT_APP_KNEST_KONTROL_URL}/ordered-part-lists/${params.row.id}/order-confirmation?access_token=${getToken().substring(7)}&language=en`,
                  '_blank',
                  'height=600,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no',
                )}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<Download />}
                label={'Download Order Confirmation (German)'}
                onClick={() => window.open(
                  `${process.env.REACT_APP_KNEST_KONTROL_URL}/ordered-part-lists/${params.row.id}/order-confirmation?access_token=${getToken().substring(7)}&language=de`,
                  '_blank',
                  'height=600,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no',
                )}
                showInMenu
              />,
            );
          }

          params.row.customerTransactionDocuments.filter(ctd => ctd.documentType === 'invoice').forEach(ctd => {
            actions.push(
              <GridActionsCellItem
                icon={<Download />}
                label={`Download Invoice RE-${formatDate(ctd.createdAt!, 'yy/MM')}/${ctd.orderedPartListId}/${ctd.id} (DE)`}
                onClick={() => window.open(
                  `${process.env.REACT_APP_KNEST_KONTROL_URL}/ordered-part-lists/${ctd.orderedPartListId}/invoices/${ctd.id}?language=de&access_token=${getToken().substring(7)}`,
                  '_blank',
                  'height=600,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no',
                )}
                showInMenu
              />,
              <GridActionsCellItem
                icon={<Download />}
                label={`Download Invoice RE-${formatDate(ctd.createdAt!, 'yy/MM')}/${ctd.orderedPartListId}/${ctd.id} (EN)`}
                onClick={() => window.open(
                  `${process.env.REACT_APP_KNEST_KONTROL_URL}/ordered-part-lists/${ctd.orderedPartListId}/invoices/${ctd.id}?language=en&access_token=${getToken().substring(7)}`,
                  '_blank',
                  'height=600,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no',
                )}
                showInMenu
              />,
            );
          });

          if (!params.row.orderConfirmationId && !params.row.customerTransactionDocuments.some(ctd => ctd.documentType === 'order_confirmation')) {
            actions.push(
              <GridActionsCellItem
                icon={<ReceiptLong />}
                label={'Create Order Confirmation'}
                onClick={() => this.triggerOrderConfirmationCreation(params.row.id)}
                showInMenu
              />,
            );
          }

          actions.push(<GridActionsCellItem
            icon={<Cancel />}
            label={'Cancel Order'}
            onClick={() => this.cancelOrder(params.row.id)}
            disabled={![
              'open',
              'processing_drawing_generation',
              'manual_drawing_generation',
              'processing_release',
              'partially_released',
              'released',
              'pending_release',
              'awaiting_manual_release',
            ].includes(params.row.statusKey!)}
            showInMenu
          />);

          return actions;
        },
      },
    ];
    this.columnTypes = this.columns.reduce((acc, {field, type}) => {
      acc[field] = type;
      return acc;
    }, {});

    const queryParams = new URLSearchParams(document.location.hash?.substring(1));

    this.state = {
      loading: true,
      orders: [],
      totalItemCount: 0,
      nextPage: 1,
      itemsPerPage: 100,
      sortModel: JSON.parse(queryParams.get('sortModel') || 'null') as GridSortModel || [],
      filterModel: JSON.parse(queryParams.get('filterModel') || 'null') as GridFilterModel || {logicOperator: GridLogicOperator.And, items: []},
      backendFilter: generateWhereFromFilterModel(
        JSON.parse(queryParams.get('filterModel') || 'null') as GridFilterModel || {logicOperator: GridLogicOperator.And, items: []},
        this.columnTypes,
      ),
      editOrderId: JSON.parse(queryParams.get('editOrderId') || 'null') as number || null,
      editModalOpen: false,
      editModalOrder: null,
    };

    console.log(this.state);
  }

  CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarExport />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  };

  async componentDidMount() {
    await this.loadRows(() => {
      if (this.state.editOrderId) {
        const order = this.state.orders.find(o => o.id === this.state.editOrderId);

        if (!order) {
          return this.setState({
            editOrderId: null,
          }, this.updateDocumentHash);
        }

        this.setState({
          editModalOpen: true,
          editModalOrder: order,
        });
      }
    });
  }

  handleOnRowsScrollEnd = async (params: GridEventLookup['rowsScrollEnd']['params']) => {
    if (params.visibleRowsCount === this.state.totalItemCount) {
      return;
    }

    await this.loadRows();
  };

  getOrderQuery = () => {
    return this.state.sortModel.map(sort => ({[sort.field]: sort.sort}));
  };

  loadRows = async (callback?: () => void) => {
    this.setState({
      loading: true,
    });

    try {
      const {page: orders, pagination: {totalItemCount}} = await knestGetOrders(
        this.state.nextPage,
        this.state.itemsPerPage,
        [...this.getOrderQuery(), {id: 'desc'}],
        {
          customerTransactionDocuments: true,
          orderedPartConfigTeasers: {
            include: {
              customerTransactionDocumentLineItems: {
                include: {
                  customerTransactionDocument: true,
                },
              },
              orderedPartConfigStatusLogs: true,
              orderedPart: {
                include: {
                  orderedPartPartFeatures: true,
                },
              },
              bundleOrderedPartConfigTeasers: {
                include: {
                  shipmentBundleOrderedPartConfigs: true,
                  bundle: true,
                },
              },
            },
          },
          company: {
            include: {
              users: true,
            },
          },
        },
        this.state.backendFilter,
      );

      this.setState({
        orders: this.state.nextPage === 1 ? orders : this.state.orders.concat(orders),
        nextPage: this.state.nextPage + 1,
        totalItemCount,
      }, callback);
    } catch (err) {
      console.error(err);
    }

    this.setState({
      loading: false,
    });
  };

  updateDocumentHash = () => {
    document.location.hash = `${this.state.editOrderId ? `editOrderId=${this.state.editOrderId}&` : ''}filterModel=${encodeURIComponent(JSON.stringify(this.state.filterModel))}&sortModel=${encodeURIComponent(JSON.stringify(this.state.sortModel))}`;
  };

  handleSort = async (gridSortModel: GridSortModel) => {
    if (this.state.sortModel !== gridSortModel) {
      this.setState({
        nextPage: 1,
        sortModel: gridSortModel,
      }, async () => {
        await this.loadRows();
        this.updateDocumentHash();
      });
    }
  };

  handleFilterModelChange = async (filterModel: GridFilterModel, details: GridCallbackDetails<'filter'>) => {
    const newBackendFilter = generateWhereFromFilterModel(filterModel, this.columnTypes);
    const backendFilterChanged = !_.isEqual(newBackendFilter, this.state.backendFilter);

    this.setState({
      nextPage: details.reason && backendFilterChanged ? 1 : this.state.nextPage,
      filterModel,
      backendFilter: newBackendFilter,
    }, async () => {
      (details.reason && backendFilterChanged) && await this.loadRows();
      this.updateDocumentHash();
    });
  };

  fetchAndRefreshRow = async (orderId: number) => {
    const {page: [updatedOrder]} = await knestGetOrders(1, 1, [], {
      customerTransactionDocuments: true,
      orderedPartConfigTeasers: {
        include: {
          customerTransactionDocumentLineItems: {
            include: {
              customerTransactionDocument: true,
            },
          },
          orderedPartConfigStatusLogs: true,
          orderedPart: {
            include: {
              orderedPartPartFeatures: true,
            },
          },
          bundleOrderedPartConfigTeasers: {
            include: {
              bundle: true,
            },
          },
        },
      },
      company: {
        include: {
          users: true,
        },
      },
    }, {id: orderId});

    this.setState({
      orders: this.state.orders.map(order => order.id === updatedOrder.id ? updatedOrder : order),
    });
  };

  triggerOrderConfirmationCreation = async (orderId: number) => {
    const {isConfirmed, isDenied} = await Swal.fire({
      title: 'Are you sure you want to create an order confirmation?',
      html: 'If you choose to send an email, the order confirmation will be sent to the contact chosen in the related KMS Part List, as well as any order confirmation address configured in Kontrol Companies.<br><br>A copy of this email will be Bcc\'d to business@kreatize.com.',
      icon: 'warning',
      confirmButtonText: 'Yes, Create Order Confirmation & Send email to Customer',
      denyButtonText: 'Yes, Create Order Confirmation',
      denyButtonColor: '#70BCDE',
      confirmButtonColor: '#7ECBAC',
      showCancelButton: false,
      showDenyButton: true,
      showCloseButton: true,
      customClass: {container: 'custom-swal-container'},
      showLoaderOnConfirm: true,
      showLoaderOnDeny: true,
      preConfirm: async () => {
        try {
          await createOrderConfirmation(orderId, true);
          await this.fetchAndRefreshRow(orderId);
        } catch {
          Swal.showValidationMessage(`An error occurred while creating the order confirmation.`);
          return false;
        }
      },
      preDeny: async () => {
        try {
          await createOrderConfirmation(orderId);
          await this.fetchAndRefreshRow(orderId);
        } catch {
          Swal.showValidationMessage(`An error occurred while creating the order confirmation.`);
          return false;
        }
      },
    });

    if (isConfirmed || isDenied) {
      // Update the order in the state
      await Swal.fire({
        title: 'The order confirmation was successfully created.',
        icon: 'success',
        customClass: {
          container: 'custom-swal-container',
        },
      });
    }
  };

  cancelOrder = async (orderId: number) => {
    const {isConfirmed} = await Swal.fire({
      title: 'Are you sure you want to cancel this order?',
      text: 'This will also set any open bundles to status Lost. This action cannot be undone.',
      icon: 'warning',
      confirmButtonText: 'Yes, Cancel this Order',
      confirmButtonColor: '#FF0000',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#70BCDE',
      reverseButtons: true,
      customClass: {container: 'custom-swal-container'},
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await cancelOrder(orderId);
        } catch {
          Swal.showValidationMessage(`An error occurred while cancelling the order.`);
          return false;
        }
      },
    });

    if (isConfirmed) {
      // Update the order in the state
      this.setState({
        orders: this.state.orders.map(order => order.id === orderId ? {
          ...order,
          statusKey: 'cancelled',
        } : order),
      });
      await Swal.fire({
        title: 'The order was successfully cancelled.',
        icon: 'success',
        customClass: {
          container: 'custom-swal-container',
        },
      });
    }
  };

  closeEditOrderModal = async () => {
    this.setState({
      editOrderId: null,
      editModalOpen: false,
      loading: true,
    }, this.updateDocumentHash);

    // Update the singular row in the state
    await this.fetchAndRefreshRow(this.state.editModalOrder!.id);

    this.setState({
      loading: false,
      editModalOrder: null,
    });
  };

  updateOrderInState = (order: State['orders'][number]) => {
    this.setState({
      orders: this.state.orders.map(o => o.id === order.id ? order : o),
    });
  };

  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Paper style={{height: 'calc(100vh - 180px)'}}>
            <DataGridPro<Order>
              columns={this.columns}
              rows={this.state.orders}
              loading={this.state.loading}
              onRowsScrollEnd={this.handleOnRowsScrollEnd}
              rowCount={this.state.totalItemCount}

              sortingMode={'server'}
              onSortModelChange={this.handleSort}
              sortModel={this.state.sortModel}

              filterMode={'server'}
              filterModel={this.state.filterModel}
              onFilterModelChange={this.handleFilterModelChange}
              filterDebounceMs={1500}
              headerFilters
              headerFilterHeight={80}

              initialState={{
                density: 'compact',
                sorting: {sortModel: this.state.sortModel},
                filter: {filterModel: this.state.filterModel},
                pinnedColumns: {left: ['kmsId'], right: ['actions']},
              }}

              slots={{
                toolbar: this.CustomToolbar,
              }}
            />
          </Paper>
          <EditOrderModal
            open={this.state.editModalOpen}
            closeModal={this.closeEditOrderModal}
            order={this.state.editModalOrder}
            updateOrderInState={this.updateOrderInState}
          />
        </Grid>
      </Grid>
    );
  }
}

export default OrdersTable;
