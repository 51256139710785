import {kontrolInstance, authInstance} from 'services/axiosInstances';

export const resetPassword = async (email) => {
  return kontrolInstance.post('users/reset-password', {email});
};

export const createRoleMapping = async (userId, roleKey) => {
  return kontrolInstance.post(`users/${userId}/role/${roleKey}`);
};

export const deleteRoleMapping = async (userId, roleKey) => {
  return kontrolInstance.delete(`users/${userId}/role/${roleKey}`);
};

export const getUserInfo = async () => {
  return authInstance.get('users/current/info');
};