import React, {Component} from 'react';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import {withTheme} from '@mui/styles';
import {Close} from '@mui/icons-material';
import './EditProcessModal.scss';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {getProcess, getProcessFeatures, patchProcess} from '../../../services/knestKontrol';
import {v4 as uuidv4} from 'uuid';
import {Add, Delete} from '@mui/icons-material';

class EditProcessModal extends Component {
  constructor(props) {
    super(props);

    this.defaultNewProcessFeature = {
      key: null,
      dataTypeKey: null,
      unitKey: null,
      defaultValue: null,
      min: null,
      max: null,
      showOnDrawing: true,
      kmsConfiguration: 'disabled',
      selectOptions: null,
    };

    this.defaultSate = {
      columns: [],
      processFeatures: [],
      processProcessFeature: [],
      loading: true,
      selectedProcessFeature: null,
      snackbarOpen: false,
      snackbarSeverity: 'success',
      snackbarMessage: '',
    };

    this.state = {
      ...this.defaultSate,
    };
  }

  fetchAndRefreshData = async () => {
    await this.setState({loading: true});

    const processFeatures = await getProcessFeatures({key: 'asc'});

    // Get more information on the process
    const process = await getProcess(this.props.process.key, {
      filter: {
        include: {
          processProcessFeatures: {
            orderBy: {processFeatureKey: 'asc'},
            include: {
              processFeature: {include: {processFeatureSelectOptions: true}},
              processProcessFeatureSelectOptions: true,
            },
          },
          partsProcessProcessFeatureViews: {include: {selectOptions: true}},
        },
      },
    });

    const formattedProcessProcessFeatures = process.processProcessFeatures.map(pf => {
      const partsProcessProcessFeature = process.partsProcessProcessFeatureViews
        .find(ppf => ppf.processFeatureKey === pf.processFeatureKey);

      return {
        ...pf,
        ...pf.processFeature,
        key: pf.processFeatureKey,
        defaultValue: pf.defaultValue || null,
        min: pf.min || null,
        max: pf.max || null,
        showOnDrawing: pf.showOnDrawing || false,
        kmsConfiguration: partsProcessProcessFeature ?
          (partsProcessProcessFeature?.required ? 'required' : 'enabled') :
          'disabled',
        selectOptions: pf.dataTypeKey === 'select' ? partsProcessProcessFeature?.selectOptions || '[]' : null,
        uuid: uuidv4(),
      };
    });

    await this.setState({
      loading: false,
      columns: [
        {
          field: 'key',
          headerName: 'Process Feature Key',
          minWidth: 170,
          type: 'singleSelect',
          valueOptions: processFeatures.map(p => p.key),
          editable: false,
        },
        {field: 'dataTypeKey', headerName: 'Data Type', minWidth: 100},
        {field: 'unitKey', headerName: 'Unit', minWidth: 80},
        {field: 'defaultValue', headerName: 'Default Value', minWidth: 120, editable: true},
        {field: 'min', headerName: 'Min Value', width: 90, type: 'number', editable: true},
        {field: 'max', headerName: 'Max Value', width: 90, type: 'number', editable: true},
        {field: 'showOnDrawing', headerName: 'Show on Drawing', minWidth: 150, type: 'boolean', editable: true},
        {
          field: 'kmsConfiguration',
          headerName: 'KMS/Kumberas Status',
          type: 'singleSelect',
          minWidth: 150,
          valueOptions: ['disabled', 'enabled', 'required'],
          editable: true,
        },
        {
          field: 'selectOptions',
          headerName: 'Available Options',
          minWidth: 300,
          renderCell: ({row}) => {
            if (row.processFeature?.dataTypeKey !== 'select') return <></>;

            return <Autocomplete
              multiple
              fullWidth
              value={row.processProcessFeatureSelectOptions}
              onChange={(event, value, reason, {option}) => this.handleSelectOptionChange(row, reason, option)}
              filterSelectedOptions
              size={'small'}
              options={row.processFeature?.processFeatureSelectOptions}
              getOptionLabel={option => option.selectOptionKey}
              isOptionEqualToValue={(option, value) => option.selectOptionKey === value.selectOptionKey}
              sx={{maxHeight: '100%'}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={'standard'}
                  sx={{maxHeight: 50, overflowY: 'scroll'}}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: false,
                    disableUnderline: true,
                    sx: {maxHeight: 50},
                  }}
                />
              )}
            />;
          },
        },
        {
          field: 'actions',
          headerName: '',
          width: 20,
          editable: false,
          sortable: false,
          align: 'center',
          renderCell: (params) => {
            return (
              <Tooltip title={'Remove Process Features From Process'}>
                <IconButton onClick={() => this.removeProcessFeatureFromProcess(params.row)} size={'small'}><Delete /></IconButton>
              </Tooltip>
            );
          },
        },
      ],
      processProcessFeature: formattedProcessProcessFeatures,
      processFeatures: processFeatures.filter(pf => !formattedProcessProcessFeatures.some(ppf => ppf.key === pf.key)),
      selectedProcessFeature: null,
    });
  };

  async componentDidMount() {
    await this.fetchAndRefreshData();
  }

  closeModal = async () => {
    this.props.confirm();
    this.props.setOpen(false);

    // Reset state
    this.setState(this.defaultSate);
  };

  handleFailure = async (error) => {
    console.error(error);
    this.setState({
      snackbarOpen: true,
      snackbarMessage: 'An error occurred while updating the related process feature. Please try again later.',
      snackbarSeverity: 'error',
    });
  };

  handleRowUpdate = async (row) => {
    await patchProcess(this.props.process.key, {
      processProcessFeatures: {
        update: {
          where: {processKey_processFeatureKey: {processKey: this.props.process.key, processFeatureKey: row.processFeatureKey}},
          data: {
            defaultValue: row.defaultValue?.trim()?.length ? row.defaultValue.trim() : null,
            min: row.min,
            max: row.max,
            showOnDrawing: row.showOnDrawing,
            partsProcessProcessFeatures: {
              [row.kmsConfiguration === 'disabled' ? 'deleteMany' : 'upsert']: {
                ...row.kmsConfiguration === 'disabled' && {
                  AND: [{processKey: this.props.process.key}, {processFeatureKey: row.processFeatureKey}],
                },
                ...row.kmsConfiguration !== 'disabled' && {
                  where: {processFeatureKey_processKey: {processFeatureKey: row.processFeatureKey, processKey: this.props.process.key}},
                  create: {
                    required: row.kmsConfiguration !== 'enabled',
                  },
                  update: {
                    required: row.kmsConfiguration !== 'enabled',
                  },
                },
              },
            },
          },
        },
      },
    });

    await this.setState({
      snackbarOpen: true,
      snackbarMessage: 'Related process feature successfully saved.',
      snackbarSeverity: 'success',
    });

    return row;
  };

  handleRowUpdateFailure = async error => {
    await this.handleFailure(error);
  };

  handleSelectOptionChange = async (processProcessFeatureInstance, reason, option) => {
    await patchProcess(processProcessFeatureInstance.processKey, {
      processProcessFeatures: {
        update: {
          where: {
            processKey_processFeatureKey: {
              processKey: processProcessFeatureInstance.processKey,
              processFeatureKey: processProcessFeatureInstance.processFeatureKey,
            },
          },
          data: {
            processProcessFeatureSelectOptions: {
              ...reason === 'selectOption' && {
                create: {
                  selectOption: {connect: {key: option.selectOptionKey}},
                  process: {connect: {key: processProcessFeatureInstance.processKey}},
                },
              },
              ...reason === 'removeOption' && {
                deleteMany: {
                  AND: {
                    processKey: processProcessFeatureInstance.processKey,
                    processFeatureKey: processProcessFeatureInstance.processFeatureKey,
                    selectOptionKey: option.selectOptionKey,
                  },
                },
              },
            },
          },
        },
      },
    });

    await this.fetchAndRefreshData();
  };

  addProcessFeatureToProcess = async () => {
    try {
      await patchProcess(this.props.process.key, {
        processProcessFeatures: {
          create: {
            showOnDrawing: true,
            processFeature: {connect: {key: this.state.selectedProcessFeature.key}},
          },
        },
      });

      await this.fetchAndRefreshData();
    } catch (e) {
      await this.handleFailure(e);
    }
  };

  removeProcessFeatureFromProcess = async (processProcessFeature) => {
    try {
      await patchProcess(this.props.process.key, {
        processProcessFeatures: {
          deleteMany: {
            AND: {
              processKey: this.props.process.key,
              processFeatureKey: processProcessFeature.processFeatureKey,
            },
          },
        },
      });

      await this.fetchAndRefreshData();
    } catch (e) {
      await this.handleFailure(e);
    }
  };

  render() {
    const {open, process} = this.props;

    return (
      <Modal open={open} closeAfterTransition={true} className={'edit-process-modal-root'}>
        <Fade in={open} timeout={250}>
          <div className={'edit-process-modal'}>
            <Paper className={'edit-process-modal-paper'}>
              <IconButton
                size={'small'}
                className={'close-edit-process-modal-button'}
                onClick={this.closeModal}
              >
                <Close />
              </IconButton>
              <Grid
                container
                spacing={2}
                className={'edit-process-modal-content'}
                sx={{height: '100%'}}
              >
                <Grid xs={12}>
                  <Typography>Editing Process Features for {process?.key}</Typography>
                </Grid>
                <Grid container xs={12} alignItems={'flex-end'} sx={{width: '100%'}}>
                  <Grid xs={4}>
                    <Autocomplete
                      options={this.state.processFeatures}
                      value={this.state.selectedProcessFeature}
                      onChange={(event, value) => this.setState({selectedProcessFeature: value})}
                      getOptionLabel={option => option.key}
                      size={'small'}
                      renderInput={params => <TextField {...params} label="Process Feature" variant={'standard'} />}
                    />
                  </Grid>
                  <Grid>
                    <Tooltip
                      disableHoverListener={!!this.state.selectedProcessFeature}
                      title={'You must select a process feature first before adding it to the process'}
                    >
                      <span>
                        <Button
                          disabled={!this.state.selectedProcessFeature}
                          size={'small'}
                          startIcon={<Add />}
                          onClick={this.addProcessFeatureToProcess}
                        >
                          Add Process Feature to Process
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid xs={12} sx={{height: 'calc(100% - 112px)'}}>
                  <Paper sx={{height: '100%'}}>
                    <Box sx={{height: '100%'}}>
                      <DataGridPro
                        columns={this.state.columns}
                        rows={this.state.processProcessFeature}
                        getRowId={row => row.uuid}
                        hideFooter
                        autoHeight={false}
                        pagination={false}
                        loading={this.state.loading}
                        pinnedColumns={{left: ['key'], right: ['actions']}}
                        experimentalFeatures={{newEditingApi: true}}
                        editMode={'row'}
                        processRowUpdate={this.handleRowUpdate}
                        onProcessRowUpdateError={this.handleRowUpdateFailure}
                      />
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
            <Snackbar
              open={this.state.snackbarOpen}
              anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
              autoHideDuration={4000}
              onClose={() => this.setState({snackbarOpen: false})}
            >
              <Alert onClose={() => this.setState({snackbarOpen: false})} severity={this.state.snackbarSeverity} sx={{width: '100%'}}>
                {this.state.snackbarMessage}
              </Alert>
            </Snackbar>
          </div>
        </Fade>
      </Modal>
    );
  }
}

export default withTheme(EditProcessModal);
