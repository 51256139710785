import React from 'react';
import {Menu, MenuItem, ProSidebar, SubMenu} from 'react-pro-sidebar';
import {Link, withRouter} from 'react-router-dom';
import {Badge} from '@mui/material';
import routes from 'routes.tsx';
import './Sidebar.scss';

const Sidebar = withRouter((props) => {
  return (<ProSidebar
    collapsed={props.collapsed}
    toggled={props.toggled}
    onToggle={props.handleSidebarToggle}
    breakPoint="md"
    onMouseEnter={() => props.handleSidebarCollapsed(false)}
    onMouseLeave={() => props.handleSidebarCollapsed(true)}
  >
    <Menu iconShape="round" popperArrow>
      {routes.filter(route => route.icon).map((route) => {
        const pathName = props.history.location.pathname;
        if (route.items) {
          return <SubMenu title={route.key} icon={route.icon} key={route.key}>
            {route.items.filter(x => x.icon).map(subroute => {
              return (
                <MenuItem
                  key={subroute.key}
                  icon={<Badge badgeContent={subroute.badgeContent} invisible={!subroute.badgeContent} color={'error'} overlap={'rectangular'}>{subroute.icon}</Badge>}
                  active={pathName === route.path + subroute.path}
                  onClick={() => subroute.function && subroute.function()}
                >
                  <Link to={route.path + subroute.path}>
                    {subroute.key}
                  </Link>
                </MenuItem>);
            })}
          </SubMenu>;
        }
        return (<MenuItem
          key={route.key}
          icon={<Badge badgeContent={route.badgeContent} invisible={!route.badgeContent} color={'error'} overlap={'rectangular'}>{route.icon}</Badge>}
          active={pathName === route.path}
          onClick={() => route.function && route.function()}
        >
          <Link to={route.path} />
          {route.key}
        </MenuItem>);
      })}
    </Menu>
  </ProSidebar>);
});

export default Sidebar;
