import React, {Component}                                          from 'react';
import {Button, Container}                                         from 'shards-react';
import Loading                                                     from '../../components/Loading/Loading';
import {downloadBundleOrderedPartConfigFiles, openHubDeliverySlip} from '../../services/kooperKontrol';


class DownloadPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.download();
  }

  downloadConfigFiles = async (params) => {
    const bundleId = params.get('bundleId');
    const orderedPartConfigId = params.get('orderedPartConfigId');

    try {
      downloadBundleOrderedPartConfigFiles(bundleId, orderedPartConfigId);
    }
    catch(err) {
      console.error(err);
    }
  };

  downloadPdf = async (params) => {
    const bundleId = params.get('bundleId');
    try {
      await openHubDeliverySlip(bundleId);
    }
    catch(err) {
      console.error(err);
    }
  };

  download = () => {
    const params = new URLSearchParams(this.props.location.search);
    switch (this.props.type) {
      case 'delivery-slip':
        this.downloadPdf(params)
          .catch(console.error);
        break;
      case 'files':
        this.downloadConfigFiles(params)
          .catch(console.error);
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <Container fluid style={{height: '100vh'}}>
        <div className='row vh-100' style={{overflow: 'auto', height: '100%', display: 'flex', justifyContent: 'center'}}>
          <Loading withText text={
            <React.Fragment>
              {`Downloading ${this.props.type}...`}
              <br />
              <Button style={{marginTop: '10px'}} onClick={() => this.download()}>Download now</Button>
            </React.Fragment>
          }
          />
        </div>
      </Container>
    );
  }
}
export default DownloadPage;
