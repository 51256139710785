import React, {Component} from 'react';
import {Company, Prisma, Shipment} from '@prisma/client';
import {
  Autocomplete,
  Box,
  Fade,
  FormControl,
  FormGroup,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Unstable_Grid2 as Grid,
  List,
  ListItem,
  ListItemIcon, ListItemText, Alert, Snackbar,
} from '@mui/material';
import {Check, Close, PrecisionManufacturing, SaveAs} from '@mui/icons-material';
import './CreateShipmentModal.scss';
import {Carriers} from '../../../types/ReferentialData';
import {createShipment, getCompanies, getShipment, updateShipmentStatus} from '../../../services/knestKontrol';
import {getData as getAllCountries} from 'country-list';
import {LoadingButton} from '@mui/lab';
import {ShipmentTeaser} from '../../ShipmentsTable/ShipmentsTable';

type Position = Prisma.BundleOrderedPartConfigTeaserGetPayload<{
  include: {
    orderedPartConfigTeaser: { include: { orderedPart: true } },
    bundle: true,
  }
}>

type State = {
  newShipment: Partial<Shipment>,
  companies: Company[]
  loading: boolean,
  snackbarOpen: boolean,
  snackbarSeverity: 'success' | 'error' | 'warning' | 'info',
  snackbarMessage: string,
};

type Props = {
  open: boolean,
  shipmentPrefillData?: Partial<Shipment>,
  bundleOrderedPartConfigTeasers: Position[],
  onClose: (createdShipment?: ShipmentTeaser | null) => void,
};

class CreateShipmentModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      newShipment: {},
      companies: [],
      loading: false,
      snackbarOpen: false,
      snackbarSeverity: 'success',
      snackbarMessage: '',
    };
  }

  async componentDidMount() {
    const companies = await getCompanies({});
    this.setState({companies});
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.shipmentPrefillData !== this.props.shipmentPrefillData && this.props.shipmentPrefillData) {
      this.setState({newShipment: this.props.shipmentPrefillData});
    }

    if (prevProps.open !== this.props.open && !this.props.open) {
      this.setState({newShipment: {}, loading: false});
    }
  }

  createShipment = async (setToInTransit: boolean = false) => {
    this.setState({loading: true});

    try {
      const shipment = await createShipment({
        carrierKey: this.state.newShipment.carrierKey!,
        trackingNumber: this.state.newShipment.trackingNumber!,
        fromCompanyId: this.state.newShipment.fromCompanyId!,
        toCompanyId: this.state.newShipment.toCompanyId!,
        toAddress: {
          toAddressLine1: this.state.newShipment.toAddressLine1!,
          toAddressLine2: this.state.newShipment.toAddressLine2,
          toCity: this.state.newShipment.toCity!,
          toCountryKey: this.state.newShipment.toCountryKey!,
          toState: this.state.newShipment.toState!,
          toZipCode: this.state.newShipment.toZipCode!,
        },
        bundleOrderedPartConfigIds: this.props.bundleOrderedPartConfigTeasers.map(p => p.id),
        fromAddress: {
          fromAddressLine1: this.state.newShipment.fromAddressLine1!,
          fromAddressLine2: this.state.newShipment.fromAddressLine2,
          fromCity: this.state.newShipment.fromCity!,
          fromCountryKey: this.state.newShipment.fromCountryKey!,
          fromState: this.state.newShipment.fromState!,
          fromZipCode: this.state.newShipment.fromZipCode!,
        },
        isExpressShipment: this.state.newShipment.isExpressShipment,
      });

      if (setToInTransit) {
        await updateShipmentStatus(shipment.id, 'in_transit');
      }

      const shipmentTeaser = await getShipment(shipment.id, {
        fromCompany: true,
        toCompany: true,
        shipmentBundleOrderedPartConfigs: {
          include: {
            bundleOrderedPartConfigTeaser: {
              include: {
                orderedPartConfigTeaser: {
                  include: {
                    orderedPart: true,
                    orderedPartListTeaser: true,
                  },
                },
              },
            },
          },
        },
      });

      this.props.onClose(shipmentTeaser);
    } catch (err) {
      console.error(err);
      this.setState({snackbarOpen: true, snackbarSeverity: 'error', snackbarMessage: 'An error occurred while creating the shipment.'});
    }

    this.setState({loading: false});
  };

  render() {
    const {open} = this.props;
    return <Modal open={open} closeAfterTransition={true} className={'create-shipment-modal-root'}>
      <Fade in={open} timeout={250}>
        <div className={'create-shipment-modal'}>
          <Paper className={'create-shipment-modal-paper'}>
            <IconButton
              size={'small'}
              className={'close-create-shipment-modal-button'}
              onClick={() => this.props.onClose(null)}
            >
              <Close />
            </IconButton>
            <Box className={'create-shipment-modal-content'}>
              <Box>
                <Grid container>
                  <Grid xs={12}><Typography variant={'h6'}>Creating Shipment</Typography></Grid>
                </Grid>
              </Box>
              <Box width={'100%'} height={'calc(100% - 60px)'} overflow={'scroll'} pt={2} pb={2}>
                <Grid container spacing={3} width={'100%'} alignItems={'flex-start'}>
                  <Grid xs={12} container spacing={1}>
                    <Grid xs={12}><Typography>General Shipment Details</Typography></Grid>
                    <Grid xs={3}>
                      <FormGroup>
                        <FormLabel>
                          Carrier
                        </FormLabel>
                        <Select
                          value={this.state.newShipment.carrierKey}
                          size={'small'}
                          disabled={this.state.loading}
                          onChange={event => this.setState({newShipment: {...this.state.newShipment, carrierKey: event.target.value}})}
                        >
                          {Carriers.map(carrier => (
                            <MenuItem value={carrier.carrierKey} key={carrier.carrierKey}>{carrier.translation}</MenuItem>
                          ))}
                        </Select>
                      </FormGroup>
                    </Grid>
                    <Grid xs={3}>
                      <FormGroup>
                        <FormLabel>
                          Tracking Number
                        </FormLabel>
                        <TextField
                          value={this.state.newShipment.trackingNumber}
                          size={'small'}
                          disabled={this.state.loading}
                          onChange={event => this.setState({newShipment: {...this.state.newShipment, trackingNumber: event.target.value}})}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid xs={3}>
                      <FormGroup>
                        <FormLabel>
                          Is Express Shipment?
                        </FormLabel>
                        <ToggleButtonGroup
                          exclusive
                          disabled={this.state.loading}
                          size={'small'}
                          value={this.state.newShipment.isExpressShipment}
                          onChange={(_e, newValue) => this.setState({newShipment: {...this.state.newShipment, isExpressShipment: newValue}})}
                        >
                          <ToggleButton value={true}>
                            <Check />
                          </ToggleButton>
                          <ToggleButton value={false}>
                            <Close />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid xs={6} container spacing={1}>
                    <Grid xs={12}><Typography>Sender Details</Typography></Grid>
                    <Grid xs={12}>
                      <FormGroup>
                        <FormLabel>
                          Shipping Company
                        </FormLabel>
                        <Autocomplete<Company>
                          value={this.state.companies.find(c => c.id === this.state.newShipment!.fromCompanyId)}
                          onChange={(_e, newValue) => this.setState({newShipment: {...this.state.newShipment, fromCompanyId: newValue?.id as number}})}
                          size={'small'}
                          options={this.state.companies}
                          getOptionLabel={option => option.companyName as string}
                          getOptionKey={option => option.id}
                          renderInput={(params) => <TextField {...params} />}
                          disabled={this.state.loading}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid xs={6}>
                      <FormGroup>
                        <FormLabel>
                          Address Line 1
                        </FormLabel>
                        <TextField
                          size={'small'}
                          fullWidth
                          multiline
                          disabled={this.state.loading}
                          value={this.state.newShipment.fromAddressLine1 || ''}
                          onChange={(e) => this.setState({newShipment: {...this.state.newShipment, fromAddressLine1: e.target.value}})}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid xs={6}>
                      <FormGroup>
                        <FormLabel>
                          Address Line 2
                        </FormLabel>
                        <TextField
                          size={'small'}
                          fullWidth
                          multiline
                          disabled={this.state.loading}
                          value={this.state.newShipment.fromAddressLine2 || ''}
                          onChange={(e) => this.setState({newShipment: {...this.state.newShipment, fromAddressLine2: e.target.value}})}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid xs={6}>
                      <FormGroup>
                        <FormLabel>
                          Zip Code
                        </FormLabel>
                        <TextField
                          size={'small'}
                          fullWidth
                          multiline
                          value={this.state.newShipment.fromZipCode || ''}
                          onChange={(e) => this.setState({newShipment: {...this.state.newShipment, fromZipCode: e.target.value}})}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid xs={6}>
                      <FormGroup>
                        <FormLabel>
                          City
                        </FormLabel>
                        <TextField
                          size={'small'}
                          fullWidth
                          multiline
                          value={this.state.newShipment.fromCity || ''}
                          onChange={(e) => this.setState({newShipment: {...this.state.newShipment, fromCity: e.target.value}})}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid xs={6}>
                      <FormGroup>
                        <FormLabel>
                          State
                        </FormLabel>
                        <TextField
                          size={'small'}
                          fullWidth
                          multiline
                          disabled={this.state.loading}
                          value={this.state.newShipment.fromState || ''}
                          onChange={(e) => this.setState({newShipment: {...this.state.newShipment, fromState: e.target.value}})}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid xs={6}>
                      <FormGroup>
                        <FormLabel>
                          Country
                        </FormLabel>
                        <FormControl size={'small'}>
                          <Select
                            disabled={this.state.loading}
                            value={this.state.newShipment.fromCountryKey}
                            onChange={(e) => this.setState({newShipment: {...this.state.newShipment, fromCountryKey: e.target.value}})}
                          >
                            {getAllCountries().map(({code, name}) => {
                              return <MenuItem value={code} key={code}>{name}</MenuItem>;
                            })}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid xs={6} container spacing={1}>
                    <Grid xs={12}><Typography>Receiver Details</Typography></Grid>
                    <Grid xs={12}>
                      <FormGroup>
                        <FormLabel>
                          Receiving Company
                        </FormLabel>
                        <Autocomplete<Company>
                          value={this.state.companies.find(c => c.id === this.state.newShipment.toCompanyId)}
                          onChange={(_e, newValue) => this.setState({newShipment: {...this.state.newShipment, toCompanyId: newValue?.id as number}})}
                          size={'small'}
                          options={this.state.companies}
                          getOptionLabel={option => option.companyName as string}
                          getOptionKey={option => option.id}
                          renderInput={(params) => <TextField {...params} />}
                          disabled={this.state.loading}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid xs={6}>
                      <FormGroup>
                        <FormLabel>
                          Address Line 1
                        </FormLabel>
                        <TextField
                          size={'small'}
                          fullWidth
                          multiline
                          disabled={this.state.loading}
                          value={this.state.newShipment.toAddressLine1 || ''}
                          onChange={(e) => this.setState({newShipment: {...this.state.newShipment, toAddressLine1: e.target.value}})}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid xs={6}>
                      <FormGroup>
                        <FormLabel>
                          Address Line 2
                        </FormLabel>
                        <TextField
                          size={'small'}
                          fullWidth
                          multiline
                          value={this.state.newShipment.toAddressLine2 || ''}
                          onChange={(e) => this.setState({newShipment: {...this.state.newShipment, toAddressLine2: e.target.value}})}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid xs={6}>
                      <FormGroup>
                        <FormLabel>
                          Zip Code
                        </FormLabel>
                        <TextField
                          size={'small'}
                          fullWidth
                          multiline
                          disabled={this.state.loading}
                          value={this.state.newShipment.toZipCode || ''}
                          onChange={(e) => this.setState({newShipment: {...this.state.newShipment, toZipCode: e.target.value}})}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid xs={6}>
                      <FormGroup>
                        <FormLabel>
                          City
                        </FormLabel>
                        <TextField
                          size={'small'}
                          fullWidth
                          multiline
                          disabled={this.state.loading}
                          value={this.state.newShipment.toCity || ''}
                          onChange={(e) => this.setState({newShipment: {...this.state.newShipment, toCity: e.target.value}})}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid xs={6}>
                      <FormGroup>
                        <FormLabel>
                          State
                        </FormLabel>
                        <TextField
                          size={'small'}
                          fullWidth
                          multiline
                          disabled={this.state.loading}
                          value={this.state.newShipment.toState || ''}
                          onChange={(e) => this.setState({newShipment: {...this.state.newShipment, toState: e.target.value}})}
                        />
                      </FormGroup>
                    </Grid>
                    <Grid xs={6}>
                      <FormGroup>
                        <FormLabel>
                          Country
                        </FormLabel>
                        <FormControl size={'small'}>
                          <Select
                            disabled={this.state.loading}
                            value={this.state.newShipment.toCountryKey}
                            onChange={(e) => this.setState({newShipment: {...this.state.newShipment, toCountryKey: e.target.value}})}
                          >
                            {getAllCountries().map(({code, name}) => {
                              return <MenuItem value={code} key={code}>{name}</MenuItem>;
                            })}
                          </Select>
                        </FormControl>
                      </FormGroup>
                    </Grid>
                  </Grid>
                  <Grid xs={12} container spacing={1}>
                    <Grid xs={12}><Typography>Selected Positions Overview</Typography></Grid>
                    <Grid xs={12}>
                      <List>
                        {this.props.bundleOrderedPartConfigTeasers.map((position, index) => {
                          return <ListItem key={index}>
                            <ListItemIcon><PrecisionManufacturing /></ListItemIcon>
                            <ListItemText
                              primary={position.orderedPartConfigTeaser!.orderedPart!.name}
                              secondary={`Drawing Nr: ${position.orderedPartConfigTeaser!.orderedPart!.drawingNr}, Qty: ${position.orderedPartConfigTeaser!.batchSize}; From Bundle: ${position.bundle!.bundleKid} (${position.bundle!.companyName})`}
                            />
                          </ListItem>;
                        })}
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Box height={'24px'}>
                <Grid container justifyContent={'flex-end'} spacing={1}>
                  <Grid xs={'auto'}>
                    <LoadingButton
                      loading={this.state.loading}
                      variant={'contained'}
                      color={'primary'}
                      loadingPosition={'start'}
                      startIcon={<SaveAs />}
                      sx={{color: 'white'}}
                      onClick={() => this.createShipment()}
                      disabled={
                        !this.state.newShipment.carrierKey
                        || !this.state.newShipment.trackingNumber
                        || this.state.newShipment.isExpressShipment === undefined
                        || !this.state.newShipment.fromCompanyId
                        || !this.state.newShipment.toCompanyId
                        || !this.state.newShipment.toAddressLine1
                        || !this.state.newShipment.toCity
                        || !this.state.newShipment.toState
                        || !this.state.newShipment.toZipCode
                        || !this.state.newShipment.toCountryKey
                      }
                    >
                      Create Shipment
                    </LoadingButton>
                  </Grid>
                  <Grid xs={'auto'}>
                    <LoadingButton
                      loading={this.state.loading}
                      variant={'contained'}
                      color={'success'}
                      loadingPosition={'start'}
                      startIcon={<SaveAs />}
                      sx={{color: 'white'}}
                      onClick={() => this.createShipment(true)}
                      disabled={
                        !this.state.newShipment.carrierKey
                        || !this.state.newShipment.trackingNumber
                        || this.state.newShipment.isExpressShipment === undefined
                        || !this.state.newShipment.fromCompanyId
                        || !this.state.newShipment.toCompanyId
                        || !this.state.newShipment.toAddressLine1
                        || !this.state.newShipment.toCity
                        || !this.state.newShipment.toState
                        || !this.state.newShipment.toZipCode
                        || !this.state.newShipment.toCountryKey
                      }
                    >
                      Create Shipment & Set to In Transit
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
          <Snackbar
            open={this.state.snackbarOpen}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            autoHideDuration={4000}
            onClose={(_event, reason) => reason !== 'clickaway' && this.setState({snackbarOpen: false})}
          >
            <Alert onClose={() => this.setState({snackbarOpen: false})} severity={this.state.snackbarSeverity} sx={{width: '100%'}}>
              {this.state.snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      </Fade>
    </Modal>;
  }
}

export default CreateShipmentModal;
