import React, {Component} from 'react';
import {
  Autocomplete, Box, Chip,
  Fade,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import {withTheme} from '@mui/styles';
import {Close} from '@mui/icons-material';
import './MaterialMaterialHeatStateModal.scss';
import {getMaterialHeatStates} from '../../../services/knestKontrol';

class MaterialMaterialHeatStateModal extends Component {
  constructor(props) {
    super(props);

    this.defaultSate = {
      materialHeatStates: [],
      loading: true,
    };

    this.state = {
      ...this.defaultSate,
    };
  }

  fetchAndRefreshData = async () => {
    await this.setState({loading: true});

    const materialHeatStates = await getMaterialHeatStates();

    await this.setState({
      loading: false,
      materialHeatStates,
    });
  };

  async componentDidMount() {
    await this.fetchAndRefreshData();
  }

  closeModal = async () => {
    this.props.confirm();
    this.props.setOpen(false);
  };

  handleMaterialHeatStateChange = async (reason, option) => {
    if (reason === 'removeOption') {
      await this.props.removeMaterialHeatStateFromMaterial(this.props.material?.id, option.key);
    } else {
      await this.props.addMaterialHeatStateToMaterial(this.props.material?.id, option.key);
    }
  };

  render() {
    const {open} = this.props;

    return (
      <Modal open={open} closeAfterTransition={true}>
        <Fade in={open} timeout={250}>
          <Box className={'material-material-heat-state-modal'}>
            <Paper sx={{height: '100%'}}>
              <IconButton
                size={'small'}
                className={'close-material-material-heat-state-modal-button'}
                onClick={this.closeModal}
              >
                <Close />
              </IconButton>
              <Grid
                container
                spacing={2}
                className={'material-material-heat-state-modal-content'}
              >
                <Grid xs={12}>
                  <Typography>Editing Heat States for Material {this.props.material?.key || this.props.material?.id}</Typography>
                </Grid>
                <Grid xs={12} alignItems={'flex-end'} sx={{width: '100%'}}>
                  <Autocomplete
                    fullWidth
                    multiple
                    disableClearable
                    options={this.state.materialHeatStates}
                    value={this.props.material?.materialHeatStates.map(mhs => ({key: mhs.materialHeatStateKey}))}
                    getOptionLabel={option => option.key}
                    isOptionEqualToValue={(option, value) => option.key === value.key}
                    filterSelectedOptions
                    renderInput={params => <TextField {...params} label="Material Heat States" />}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) =>
                        <Chip
                          variant={'filled'}
                          label={option.key}
                          {...getTagProps({index})}
                        />)
                    }
                    onChange={(event, newValue, reason, {option}) => this.handleMaterialHeatStateChange(reason, option)}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Fade>
      </Modal>
    );
  }
}

export default withTheme(MaterialMaterialHeatStateModal);