import React, {Component} from 'react';
import {Container, Row} from 'shards-react';
import PageTitle from 'components/Common/PageTitle';
import Loading from 'components/Loading/Loading';
import {getUserRoles} from 'utils/userHelpers';
import NoPermission from 'components/NoPermission/NoPermission';

class BasePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: props.loading || false,
      userRoles: getUserRoles(props.user),
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.loading !== this.props.loading) {
      this.setState({loading: false});
    }
  }

  pageContent = () => {
    const userHasRequiredRights = this.props.requiredRole ? this.state.userRoles.includes(this.props.requiredRole) : true;
    if (userHasRequiredRights) {
      return this.props.children;
    } else {
      return (
        <NoPermission />
      );
    }
  };

  render() {

    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title={this.props.title}
            subtitle={this.props.subtitle}
            className="text-sm-left"
          />
        </Row>

        {this.state.loading ? <Loading /> : this.pageContent()}
      </Container>
    );
  }
}

export default BasePage;
