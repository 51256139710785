import React, {Component} from 'react';
import {Button, Card, CardBody, CardHeader, Col, FormInput, Row} from 'shards-react';
import Select from 'react-select';
import {
  createSupplierProcessCosts,
  deleteSupplierProcessCosts,
  getSupplierGroups,
  getSupplierProcessCosts,
  patchOrCreateSupplierSupplierGroup,
  deleteSupplierSupplierGroup,
  patchSupplierProcessCosts,
} from 'services/kooperKontrol';
import {getProcesses} from 'services/knestKontrol';

import './SupplierHourlyCostsTable.css';
import {IconButton} from '@mui/material';
import {Delete} from '@mui/icons-material';

const _ = require('lodash');

class SupplierHourlyCostsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: {},
      header: ['Process', 'Setup Costs', 'Process Costs', 'Min Costs', 'Unit', 'Delete', ''],
      companyCosts: [],
      company: props.company,
      processes: [],
      processesForSelect: [],
      selectedProcess: null,
    };
  }

  componentDidMount() {
    getProcesses()
      .then(res => {
        this.setState({processes: res}, () => this.updateCompanyCosts());
      });

    getSupplierGroups()
      .then(res => this.setState({supplierGroups: res}));
  }

  updateCompanyCosts = () => {
    const companyId = this.state.company.value;
    getSupplierProcessCosts(companyId)
      .then((costs) => {
        // Insert Unit from processes into costs
        costs.forEach(costItem => costItem.unit = this.state.processes.find(item => item.key === costItem.processKey).unit);

        this.setState({companyCosts: costs});

        //filter default costs arr for processes that are not selected
        const filteredProcesses = this.state.processes.filter(process =>
          !costs.some(item => item.processKey === process.key),
        );
        this.setState({processesForSelect: filteredProcesses});
      })
      .catch(error => {
        this.setState({error});
      });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.company !== prevProps.company) {
      this.setState({company: this.props.company}, () => this.updateCompanyCosts());
    }
  }

  handleNewRow = async () => {
    const processKey = this.state.selectedProcess.value;
    const defaultValues = this.state.processes.find(process => process.key === processKey);
    const companyId = this.state.company.value;
    const newItemData = {
      companyId,
      processKey: processKey,
      setupCosts: defaultValues.defaultSetupCosts,
      processCosts: defaultValues.defaultProcessCosts,
      unit: defaultValues.unit,
    };

    const processesForSelectCopy = JSON.parse(JSON.stringify(this.state.processesForSelect));
    _.remove(processesForSelectCopy, (item) => {
      return item.key === processKey;
    });

    await createSupplierProcessCosts(newItemData);

    this.updateCompanyCosts();
    this.setState({selectedProcess: null});

  };

  handleDelete = (id) => {
    deleteSupplierProcessCosts(id)
      .then(() => {
        this.updateCompanyCosts();
      });
  };

  handleCostsChange = async (type, value, id) => {
    const data = {
      id: id,
      setupCosts: type === 'setupCosts' ? value : undefined,
      processCosts: type === 'processCosts' ? value : undefined,
      minCosts: type === 'minCosts' ? value : undefined,
    };
    const companyCostsCopy = JSON.parse(JSON.stringify(this.state.companyCosts));
    const currentCosts = companyCostsCopy.find(item => item.id === id);
    type === 'setupCosts' && (currentCosts.setupCosts = value);
    type === 'processCosts' && (currentCosts.processCosts = value);
    type === 'minCosts' && (currentCosts.minCosts = value);

    this.setState({companyCosts: companyCostsCopy});
    await patchSupplierProcessCosts(data);
  };

  mapTableHeader = () => {
    return this.state.header.map((item) => {
      return (
        <th scope="col" className="border-0">
          {item}
        </th>
      );
    });
  };

  mapTableRows = () => {
    return this.state.companyCosts.map((item) => {
      return (
        <tr>
          <td>{item.processKey}</td>
          <td>
            <FormInput
              className="cost-form-input"
              type={'number'}
              value={item.setupCosts}
              onChange={(e) => this.handleCostsChange('setupCosts', e.target.value, item.id)}
            />
          </td>
          <td>
            <FormInput
              className="cost-form-input"
              type={'number'}
              value={item.processCosts}
              onChange={(e) => this.handleCostsChange('processCosts', e.target.value, item.id)}
            />
          </td>
          <td>
            <FormInput
              className="cost-form-input"
              type={'number'}
              value={item.minCosts}
              onChange={(e) => this.handleCostsChange('minCosts', e.target.value, item.id)}
            />
          </td>
          <td>{item.unit}</td>
          <td>
            <IconButton onClick={() => this.handleDelete(item.id)}>
              <Delete />
            </IconButton>
          </td>
          <td></td>
        </tr>
      );
    });
  };

  updateSupplierGroup = ({value}) => {
    patchOrCreateSupplierSupplierGroup({companyId: this.state.company.value, supplierGroupKey: value})
      .then(() => {
        const selectedCompanyCopy = {...this.state.company};
        if (!selectedCompanyCopy.supplierGroup) {
          selectedCompanyCopy.supplierGroup = {};
        }
        selectedCompanyCopy.supplierGroup.key = value;
        this.setState({company: selectedCompanyCopy});
      });
  };

  handleDeleteSupplierSupplierGroup = (companyId) => {
    deleteSupplierSupplierGroup(companyId)
      .then(() => {
        this.setState({
          company: {
            ...this.state.company,
            supplierGroup: null,
          },
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody>
                {this.state.company && this.state.supplierGroups &&
                  <Row>
                    <Col sm="12" md="4" lg="3">
                      Group
                    </Col>
                    <Col sm="11" md="7" lg="8">
                      <div className="supplier-group-wrapper">
                        <Select
                          placeholder={'Select a Supplier Group...'}
                          options={this.state.supplierGroups.map(supplierGroup => ({
                            label: supplierGroup.key,
                            value: supplierGroup.key,
                          }))}
                          value={{
                            label: this.state.company.supplierGroup ? this.state.company.supplierGroup.key : null,
                            value: this.state.company.supplierGroup ? this.state.company.supplierGroup.key : null,
                          }}
                          onChange={(e) => this.updateSupplierGroup(e)}
                        />
                      </div>
                    </Col>
                    <Col sm="1">
                      <Button
                        theme="error"
                        pill
                        onClick={() => this.handleDeleteSupplierSupplierGroup(this.state.company.value)}
                        disabled={!this.state.company.supplierGroup}
                      >
                        <Delete />
                      </Button>
                    </Col>
                  </Row>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader>
                Hourly Costs
              </CardHeader>
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                  {this.mapTableHeader()}
                  </thead>
                  <tbody>
                  {this.mapTableRows()}
                  <tr>
                    <td colSpan={2}>
                      <Select
                        placeholder={'Select a Process...'}
                        options={this.state.processesForSelect.map(process => ({
                          label: process.key,
                          value: process.key,
                        }))}
                        onChange={(e) => this.setState({selectedProcess: e})}
                        value={this.state.selectedProcess}
                      />
                    </td>
                    <td>
                      <Button theme="success" pill onClick={this.handleNewRow} disabled={!this.state.selectedProcess}>
                        +
                      </Button>
                    </td>
                    <td />
                    <td />
                    <td />
                  </tr>
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SupplierHourlyCostsTable;
