export type StatusCategory = 'new' | 'open' | 'ordered' | 'cancelled' | 'placed';
export type Status =
  'accepted'
  | 'cancelled'
  | 'closed'
  | 'complaint'
  | 'counter_offer_accepted'
  | 'counter_offer_requested'
  | 'counter_offer_submitted'
  | 'delayed'
  | 'delivered'
  | 'invoice_sent'
  | 'lost'
  | 'new'
  | 'open'
  | 'ordered'
  | 'partially_delivered'
  | 'pending_release'
  | 'placed'
  | 'processing'
  | 'rejected'
  | 'released'
  | 'shipped'
  | 'lost_but_would_have_accepted'
  | 'lost_but_would_have_rejected'
  | 'manual_drawing_generation'
  | 'processing_drawing_generation'
  | 'processing_release'
  | 'collected_for_auction'
  | 'auction_rejected'
  | 'first_round'
  | 'first_round_submitted'
  | 'incomplete'
  | 'second_round'
  | 'second_round_submitted'
  | 'won'
  | 'auction_in_progress'
  | 'auction_failed'
  | 'auction_completed'
  | 'pending_quoting'
  | 'quoting_in_progress'
  | 'quoting_completed'
  | 'quoting_rejected'
  | 'quoting_lost'
  | 'quoting_failed'
  | 'quoting_partially_completed'
  | 'invoice_paid'
  | 'arrived_at_hub'
  | 'awaiting_manual_release'
  | 'partially_released'
  | 'partially_invoiced'
  | 'created'
  | 'in_transit'
  | 'shipment_delivered'
  | 'shipment_lost'
  | 'shipment_returned'
  | 'partially_shipped'
  | 'partially_paid'
  | 'transaction_document_created'
  | 'transaction_document_sent'
  | 'transaction_document_paid';

export const KaktusStatuses: {
  statusKey: Status
  statusCategory: Exclude<StatusCategory, 'placed'>
  translation: string
}[] = [
  {statusKey: 'auction_rejected', statusCategory: 'cancelled', translation: 'Auction Rejected'},
  {statusKey: 'cancelled', statusCategory: 'cancelled', translation: 'Cancelled'},
  {statusKey: 'complaint', statusCategory: 'ordered', translation: 'Complaint'},
  {statusKey: 'counter_offer_accepted', statusCategory: 'open', translation: 'Counter Offer Accepted'},
  {statusKey: 'counter_offer_requested', statusCategory: 'open', translation: 'Counter Offer Requested'},
  {statusKey: 'counter_offer_submitted', statusCategory: 'open', translation: 'Counter Offer Submitted'},
  {statusKey: 'delayed', statusCategory: 'ordered', translation: 'Delayed'},
  {statusKey: 'delivered', statusCategory: 'ordered', translation: 'Delivered to Hub'},
  {statusKey: 'first_round', statusCategory: 'open', translation: 'Auction First Round'},
  {statusKey: 'first_round_submitted', statusCategory: 'open', translation: 'Auction First Round Submitted'},
  {statusKey: 'invoice_sent', statusCategory: 'ordered', translation: 'Invoice Sent by Supplier'},
  {statusKey: 'invoice_paid', statusCategory: 'ordered', translation: 'Invoice Paid Out'},
  {statusKey: 'partially_invoiced', statusCategory: 'ordered', translation: 'Partially Invoiced by Supplier'},
  {statusKey: 'partially_paid', statusCategory: 'ordered', translation: 'Partially Paid Out'},
  {statusKey: 'lost', statusCategory: 'cancelled', translation: 'Lost'},
  {statusKey: 'lost_but_would_have_accepted', statusCategory: 'cancelled', translation: 'Auction - Lost but would have Accepted'},
  {statusKey: 'lost_but_would_have_rejected', statusCategory: 'cancelled', translation: 'Auction - Lost but would have Rejected'},
  {statusKey: 'new', statusCategory: 'new', translation: 'New'},
  {statusKey: 'open', statusCategory: 'open', translation: 'Open'},
  {statusKey: 'ordered', statusCategory: 'ordered', translation: 'Ordered'},
  {statusKey: 'partially_delivered', statusCategory: 'ordered', translation: 'Partially Delivered to Hub'},
  {statusKey: 'rejected', statusCategory: 'cancelled', translation: 'Rejected'},
  {statusKey: 'second_round', statusCategory: 'open', translation: 'Auction Second Round'},
  {statusKey: 'second_round_submitted', statusCategory: 'open', translation: 'Auction Second Round Submitted'},
  {statusKey: 'shipped', statusCategory: 'ordered', translation: 'Shipped to Hub'},
  {statusKey: 'won', statusCategory: 'open', translation: 'Auction - Won'},
];

export const OrderStatuses: {
  statusKey: Status
  statusCategory: Exclude<StatusCategory, 'ordered' | 'new'>
  translation: string
}[] = [
  {statusKey: 'shipped', statusCategory: 'placed', translation: 'Shipped to Customer'},
  {statusKey: 'invoice_paid', statusCategory: 'placed', translation: 'Invoice Paid by Customer'},
  {statusKey: 'processing_release', statusCategory: 'open', translation: 'Processing Release'},
  {statusKey: 'invoice_sent', statusCategory: 'placed', translation: 'Invoice Sent to Customer'},
  {statusKey: 'partially_released', statusCategory: 'open', translation: 'Partially Released'},
  {statusKey: 'manual_drawing_generation', statusCategory: 'open', translation: 'Pending Manual Drawing Generation'},
  {statusKey: 'pending_release', statusCategory: 'open', translation: 'Pending Release'},
  {statusKey: 'auction_in_progress', statusCategory: 'open', translation: 'Auction in Progress'},
  {statusKey: 'processing_drawing_generation', statusCategory: 'open', translation: 'Processing Drawing Generation'},
  {statusKey: 'cancelled', statusCategory: 'cancelled', translation: 'Cancelled'},
  {statusKey: 'placed', statusCategory: 'placed', translation: 'Placed'},
  {statusKey: 'auction_failed', statusCategory: 'open', translation: 'Auction Failed'},
  {statusKey: 'collected_for_auction', statusCategory: 'open', translation: 'Collected for Auction'},
  {statusKey: 'open', statusCategory: 'open', translation: 'Open/Order Created'},
  {statusKey: 'processing', statusCategory: 'open', translation: 'Processing'},
  {statusKey: 'delivered', statusCategory: 'placed', translation: 'Delivered to Customer'},
  {statusKey: 'released', statusCategory: 'open', translation: 'Released'},
  {statusKey: 'arrived_at_hub', statusCategory: 'placed', translation: 'Arrived at Hub'},
  {statusKey: 'partially_shipped', statusCategory: 'placed', translation: 'Partially Shipped to Customer'},
  {statusKey: 'partially_delivered', statusCategory: 'placed', translation: 'Partially Delivered to Customer'},
  {statusKey: 'partially_invoiced', statusCategory: 'placed', translation: 'Invoice(s) Partially Sent to Customer'},
  {statusKey: 'partially_paid', statusCategory: 'placed', translation: 'Invoice(s) Partially Paid by Customer'},
];

export type Carrier =
  'db_schenker'
  | 'dhl'
  | 'dhl_express'
  | 'dhl_global_forwarding'
  | 'dpd'
  | 'fedex'
  | 'gls'
  | 'hermes'
  | 'other'
  | 'self_transport'
  | 'tnt'
  | 'ups';

export const Carriers: { carrierKey: Carrier, translation: string }[] = [
  {carrierKey: 'db_schenker', translation: 'DB Schenker'},
  {carrierKey: 'dhl', translation: 'DHL'},
  {carrierKey: 'dhl_express', translation: 'DHL Express'},
  {carrierKey: 'dhl_global_forwarding', translation: 'DHL Global Forwarding'},
  {carrierKey: 'dpd', translation: 'DPD'},
  {carrierKey: 'fedex', translation: 'FedEx'},
  {carrierKey: 'gls', translation: 'GLS'},
  {carrierKey: 'hermes', translation: 'Hermes'},
  {carrierKey: 'tnt', translation: 'TNT'},
  {carrierKey: 'ups', translation: 'UPS'},
  {carrierKey: 'self_transport', translation: 'Self Transport'},
  {carrierKey: 'other', translation: 'Other'},
];

export const ShippingStatuses: {
  statusKey: 'in_transit' | 'shipment_delivered' | 'shipment_lost' | 'shipment_returned' | 'created'
  translation: string
}[] = [
  {statusKey: 'in_transit', translation: 'In Transit'},
  {statusKey: 'shipment_delivered', translation: 'Shipment Delivered'},
  {statusKey: 'shipment_lost', translation: 'Shipment Lost'},
  {statusKey: 'shipment_returned', translation: 'Shipment Returned'},
  {statusKey: 'created', translation: 'Created'},
];

export type TransactionDocumentStatus = 'transaction_document_created' | 'transaction_document_sent' | 'transaction_document_paid';

export const CustomerTransactionDocumentStatuses: {
  statusKey: TransactionDocumentStatus
  translation: string
}[] = [
  {statusKey: 'transaction_document_created', translation: 'Transaction Document Created'},
  {statusKey: 'transaction_document_sent', translation: 'Transaction Document Sent to Customer'},
  {statusKey: 'transaction_document_paid', translation: 'Transaction Document Paid by Customer'},
];

export const PartnerTransactionDocumentStatuses: {
  statusKey: TransactionDocumentStatus
  translation: string
}[] = [
  {statusKey: 'transaction_document_created', translation: 'Transaction Document Created'},
  {statusKey: 'transaction_document_sent', translation: 'Transaction Document Sent by Supplier'},
  {statusKey: 'transaction_document_paid', translation: 'Transaction Document Paid Out'},
];
