import React, {Component} from 'react';
import {Prisma} from '@prisma/client';
import {Alert, Box, Paper, Snackbar, Tab, Tabs, Tooltip, Unstable_Grid2 as Grid, debounce} from '@mui/material';
import {DataGridPro, GridActionsCellItem, GridColDef, GridFilterModel, GridSortModel, GridToolbar} from '@mui/x-data-grid-pro';
import {getCustomerTransactionDocuments, getPartnerTransactionDocumentFiles, getPartnerTransactionDocuments, markCustomerInvoiceAsPaid, markPartnerInvoiceAsPaid} from '../../services/knestKontrol';
import {formatCurrency, generateOrderByFromSortModel, generateWhereFromFilterModel, toDisplayDate, toDisplayDatetime} from '../../utils/formatHelpers';
import _ from 'lodash';
import {FileDownload, Paid} from '@mui/icons-material';
import {getToken} from '../../utils/userHelpers';
import {CustomerTransactionDocumentStatuses, PartnerTransactionDocumentStatuses} from '../../types/ReferentialData';
import Swal from 'sweetalert2';

type CustomerTransactionDocument = Prisma.CustomerTransactionDocumentTeaserGetPayload<{
  include: {
    company: true
    customerTransactionDocumentLineItems: true
  }
}>
type PartnerTransactionDocument = Prisma.PartnerTransactionDocumentTeaserGetPayload<{
  include: {
    company: true,
    partnerTransactionDocumentLineItems: true
  }
}>

type Props = {};

type State = {
  loading: boolean,
  selectedTab: 'customer' | 'partner',
  itemsPerPage: number,

  snackbarOpen: boolean,
  snackbarMessage: string,
  snackbarSeverity: 'success' | 'info' | 'warning' | 'error',

  customerTransactionDocuments: CustomerTransactionDocument[],
  customerFilterModel: GridFilterModel,
  customerSortModel: GridSortModel,
  customerTotalItemsCount: number,
  customerNextPage: number,
  customerBackendFilter: Prisma.CustomerTransactionDocumentTeaserWhereInput,

  partnerTransactionDocuments: PartnerTransactionDocument[],
  partnerFilterModel: GridFilterModel,
  partnerSortModel: GridSortModel,
  partnerTotalItemsCount: number,
  partnerNextPage: number,
  partnerBackendFilter: Prisma.PartnerTransactionDocumentTeaserWhereInput,
};

class TransactionDocumentsTable extends Component<Props, State> {
  customerTransactionDocumentsColumns: GridColDef<CustomerTransactionDocument>[];
  partnerTransactionDocumentsColumns: GridColDef<PartnerTransactionDocument>[];
  customerTransactionDocumentsColumnTypes: { [key: string]: string };
  partnerTransactionDocumentsColumnTypes: { [key: string]: string };

  constructor(props: Props) {
    super(props);

    this.customerTransactionDocumentsColumns = [
      {field: 'documentNumber', headerName: 'Document Number', width: 200},
      {field: 'orderedPartListId', headerName: 'Order Number', width: 100, type: 'number', valueFormatter: (value) => `KMS-${value}`},
      {field: 'company.companyName', headerName: 'Customer Name', width: 200, valueGetter: (_value, row) => row.company?.companyName},
      {field: 'lineItemsCount', headerName: 'Line Items Count', width: 150, type: 'number'},
      {field: 'totalPrice', headerName: 'Total Price', width: 150, type: 'number', valueFormatter: (value) => formatCurrency(value)},
      {field: 'dueDate', headerName: 'Due Date', width: 150, type: 'date', valueGetter: (value) => new Date(value), valueFormatter: (value) => toDisplayDate(value)},
      {field: 'statusKey', headerName: 'Status', width: 200, type: 'singleSelect', valueOptions: CustomerTransactionDocumentStatuses.map(({statusKey, translation}) => ({value: statusKey, label: translation}))},
      {field: 'paymentTermDays', headerName: 'Payment Term Days', width: 150, type: 'number'},
      {field: 'cashDiscountTargetDays', headerName: 'Cash Discount Target Days', width: 150, type: 'number'},
      {field: 'cashDiscountPercentage', headerName: 'Cash Discount Percentage', width: 150, type: 'number'},
      {field: 'documentType', headerName: 'Document Type', width: 150, type: 'singleSelect', valueOptions: [{value: 'order_confirmation', label: 'Order Confirmation'}, {value: 'invoice', label: 'Invoice'}]},
      {field: 'createdAt', headerName: 'Created At', width: 150, type: 'date', valueGetter: (value) => new Date(value), valueFormatter: (value) => toDisplayDatetime(value)},
      {
        field: 'actions', headerName: 'Actions', width: 160, type: 'actions', align: 'left', getActions: (params) => {
          const actions = [
            <GridActionsCellItem
              label={'Download Document (DE)'}
              icon={<Tooltip title={'Download Document (in German)'}><Box sx={{fontSize: 14}}><FileDownload sx={{fontSize: 22}} />DE</Box></Tooltip>}
              onClick={() => this.downloadDocument(params.row.id, params.row.documentType as 'order_confirmation' | 'invoice', params.row.orderedPartListId!, 'de')}
            />,
            <GridActionsCellItem
              label={'Download Document (EN)'}
              icon={<Tooltip title={'Download Document (in English)'}><Box sx={{fontSize: 14}}><FileDownload sx={{fontSize: 22}} />EN</Box></Tooltip>}
              onClick={() => this.downloadDocument(params.row.id, params.row.documentType as 'order_confirmation' | 'invoice', params.row.orderedPartListId!, 'en')}
            />,
          ];

          if (params.row.documentType === 'invoice') {
            actions.push(<GridActionsCellItem
              label={'Mark as Paid'}
              icon={<Tooltip title={'Mark as Paid'}><Paid /></Tooltip>}
              onClick={() => this.markCustomerTransactionDocumentAsPaid(params.row.orderedPartListId!, params.row.id)}
              disabled={params.row.statusKey === 'transaction_document_paid'}
            />);
          }

          return actions;
        },
      },
    ];
    this.customerTransactionDocumentsColumnTypes = this.customerTransactionDocumentsColumns.reduce((acc, {field, type}) => {
      acc[field] = type;
      return acc;
    }, {});

    this.partnerTransactionDocumentsColumns = [
      {field: 'documentName', headerName: 'Document Name', width: 250},
      {field: 'company.companyName', headerName: 'Partner Name', width: 200, valueGetter: (_value, row) => row.company?.companyName},
      {
        field: 'partnerTransactionDocumentLineItems[].bundleId', headerName: 'Bundle ID(s)', width: 250, filterable: false, sortable: false,
        valueGetter: (_value, row) => [...new Set(row.partnerTransactionDocumentLineItems?.map((li) => `KAKTUS-${li.bundleId}`))].join('/'),
      },
      {field: 'lineItemsCount', headerName: 'Line Items Count', width: 150, type: 'number'},
      {field: 'totalPrice', headerName: 'Total Price', width: 150, type: 'number', valueFormatter: (value) => formatCurrency(value)},
      {field: 'dueDate', headerName: 'Due Date', width: 150, type: 'date', valueGetter: (value) => new Date(value), valueFormatter: (value) => toDisplayDate(value)},
      {field: 'statusKey', headerName: 'Status', width: 200, type: 'singleSelect', valueOptions: PartnerTransactionDocumentStatuses.map(({statusKey, translation}) => ({value: statusKey, label: translation}))},
      {field: 'paymentTermDays', headerName: 'Payment Term Days', width: 150, type: 'number'},
      {field: 'cashDiscountTargetDays', headerName: 'Cash Discount Target Days', width: 150, type: 'number'},
      {field: 'cashDiscountPercentage', headerName: 'Cash Discount Percentage', width: 150, type: 'number'},
      {field: 'createdAt', headerName: 'Created At', width: 150, type: 'date', valueGetter: (value) => new Date(value), valueFormatter: (value) => toDisplayDatetime(value)},
      {
        field: 'actions', headerName: 'Actions', width: 80, type: 'actions', getActions: (params) => {
          return [
            <GridActionsCellItem
              label={'Download Document'}
              icon={<Tooltip title={'Download Document'}><FileDownload /></Tooltip>}
              onClick={() => getPartnerTransactionDocumentFiles(params.row.id)}
            />,
            <GridActionsCellItem
              label={'Mark as Paid'}
              icon={<Tooltip title={'Mark as Paid'}><Paid /></Tooltip>}
              onClick={() => this.markPartnerTransactionDocumentAsPaid(params.row.id)}
              disabled={params.row.statusKey === 'transaction_document_paid'}
            />,
          ];
        },
      },
    ];
    this.partnerTransactionDocumentsColumnTypes = this.partnerTransactionDocumentsColumns.reduce((acc, {field, type}) => {
      acc[field] = type;
      return acc;
    }, {});

    this.state = {
      loading: true,
      selectedTab: 'customer',
      itemsPerPage: 100,

      snackbarOpen: false,
      snackbarMessage: '',
      snackbarSeverity: 'success',

      customerTransactionDocuments: [],
      customerFilterModel: {items: []},
      customerSortModel: [{field: 'createdAt', sort: 'desc'}],
      customerTotalItemsCount: 0,
      customerNextPage: 1,
      customerBackendFilter: {},

      partnerTransactionDocuments: [],
      partnerFilterModel: {items: []},
      partnerSortModel: [{field: 'createdAt', sort: 'desc'}],
      partnerTotalItemsCount: 0,
      partnerNextPage: 1,
      partnerBackendFilter: {},
    };
  }

  loadRows = async (tab: 'customer' | 'partner') => {
    this.setState({loading: true});

    try {
      if (tab === 'customer') {
        // Load customer transaction documents
        const {page: customerTransactionDocuments, pagination: {totalItemCount}} = await getCustomerTransactionDocuments(
          this.state.customerNextPage,
          this.state.itemsPerPage,
          generateOrderByFromSortModel(this.state.customerSortModel),
          {
            company: true,
            customerTransactionDocumentLineItems: true,
          },
          this.state.customerBackendFilter,
        );

        this.setState({
          customerTransactionDocuments: this.state.customerNextPage === 1 ? customerTransactionDocuments : this.state.customerTransactionDocuments.concat(customerTransactionDocuments),
          customerTotalItemsCount: totalItemCount,
          customerNextPage: this.state.customerNextPage + 1,
        });
      } else if (tab === 'partner') {
        const {page: partnerTransactionDocuments, pagination: {totalItemCount}} = await getPartnerTransactionDocuments(
          this.state.partnerNextPage,
          this.state.itemsPerPage,
          generateOrderByFromSortModel(this.state.partnerSortModel),
          {
            company: true,
            partnerTransactionDocumentLineItems: true,
          },
          this.state.partnerBackendFilter,
        );

        this.setState({
          partnerTransactionDocuments: this.state.partnerNextPage === 1 ? partnerTransactionDocuments : this.state.partnerTransactionDocuments.concat(partnerTransactionDocuments),
          partnerTotalItemsCount: totalItemCount,
          partnerNextPage: this.state.partnerNextPage + 1,
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        snackbarOpen: true,
        snackbarMessage: 'An error occurred while loading transaction documents',
        snackbarSeverity: 'error',
      });
    }

    this.setState({loading: false});
  };

  debouncedLoadRows = debounce(this.loadRows, 750);

  async componentDidMount() {
    await this.debouncedLoadRows(this.state.selectedTab);
  }

  async componentDidUpdate(_prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (prevState.selectedTab !== this.state.selectedTab) {
      this.setState({
        loading: true,
        customerNextPage: 1,
        partnerNextPage: 1,
      }, () => this.debouncedLoadRows(this.state.selectedTab));
    }
  }

  handleOnRowsScrollEnd = async (tab: 'customer' | 'partner') => {
    await this.debouncedLoadRows(tab);
  };

  handleFilterModelChange = (filterModel: GridFilterModel, details: any, tab: 'customer' | 'partner') => {
    const newBackendFilter = generateWhereFromFilterModel(filterModel, tab === 'customer' ? this.customerTransactionDocumentsColumnTypes : this.partnerTransactionDocumentsColumnTypes);
    const backendFilterChanged = !_.isEqual(tab === 'customer' ? this.state.customerBackendFilter : this.state.partnerBackendFilter, newBackendFilter);

    if (tab === 'customer') {
      this.setState({
        customerFilterModel: filterModel,
        customerBackendFilter: newBackendFilter,
        customerNextPage: details.reason && backendFilterChanged ? 1 : this.state.customerNextPage,
      }, () => details.reason && backendFilterChanged && this.debouncedLoadRows(tab));
    } else if (tab === 'partner') {
      this.setState({
        partnerFilterModel: filterModel,
        partnerBackendFilter: newBackendFilter,
        partnerNextPage: details.reason && backendFilterChanged ? 1 : this.state.partnerNextPage,
      }, () => details.reason && backendFilterChanged && this.debouncedLoadRows(tab));
    }
  };

  handleSortModelChange = (sortModel: GridSortModel, tab: 'customer' | 'partner') => {
    if (tab === 'customer' && !_.isEqual(this.state.customerSortModel, sortModel)) {
      this.setState({
        customerSortModel: sortModel,
        customerNextPage: 1,
      }, () => this.debouncedLoadRows(tab));
    } else if (tab === 'partner' && !_.isEqual(this.state.partnerSortModel, sortModel)) {
      this.setState({
        partnerSortModel: sortModel,
        partnerNextPage: 1,
      }, () => this.debouncedLoadRows(tab));
    }
  };

  downloadDocument = (documentId: number, documentType: 'order_confirmation' | 'invoice', orderedPartListId?: number, language: 'de' | 'en' = 'de') => {
    window.open(
      `${process.env.REACT_APP_KNEST_KONTROL_URL}/ordered-part-lists/${orderedPartListId}/`
      + `${documentType === 'order_confirmation' ? 'order-confirmation' : `invoices/${documentId}`}`
      + `?access_token=${getToken().substring(7)}`
      + `&language=${language}`,
      '_blank',
      'height=600,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no',
    );
  };

  markCustomerTransactionDocumentAsPaid = async (orderedPartListId: number, documentId: number) => {
    const {isConfirmed} = await Swal.fire({
      title: 'Are you sure you want to set this invoice as paid?',
      html: 'Setting the invoice as paid will automatically set the related positions to status paid, and the order to status paid/partially paid.',
      icon: 'warning',
      confirmButtonText: 'Yes, Set as Paid',
      confirmButtonColor: '#7ECBAC',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
      customClass: {container: 'custom-swal-container'},
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await markCustomerInvoiceAsPaid(orderedPartListId, documentId);
          this.setState({
            customerTransactionDocuments: this.state.customerTransactionDocuments.map((doc) => doc.id === documentId ? {...doc, statusKey: 'transaction_document_paid'} : doc),
          });
        } catch {
          Swal.showValidationMessage(`An error occurred while setting the invoice as paid.`);
          return false;
        }
      },
    });

    if (isConfirmed) {
      // Update the order in the state
      await Swal.fire({
        title: 'The invoice was successfully set as paid.',
        icon: 'success',
        customClass: {
          container: 'custom-swal-container',
        },
      });
    }
  };

  markPartnerTransactionDocumentAsPaid = async (documentId: number) => {
    const {isConfirmed} = await Swal.fire({
      title: 'Are you sure you want to set this invoice as paid?',
      html: 'Setting the invoice as paid will automatically set the related positions to status paid, and the related bundle(s) to status paid/partially paid.',
      icon: 'warning',
      confirmButtonText: 'Yes, Set as Paid',
      confirmButtonColor: '#7ECBAC',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
      customClass: {container: 'custom-swal-container'},
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await markPartnerInvoiceAsPaid(documentId);
          this.setState({
            partnerTransactionDocuments: this.state.partnerTransactionDocuments.map((doc) => doc.id === documentId ? {...doc, statusKey: 'transaction_document_paid'} : doc),
          });
        } catch {
          Swal.showValidationMessage(`An error occurred while setting the invoice as paid.`);
          return false;
        }
      },
    });

    if (isConfirmed) {
      // Update the order in the state
      await Swal.fire({
        title: 'The invoice was successfully set as paid.',
        icon: 'success',
        customClass: {
          container: 'custom-swal-container',
        },
      });
    }
  };

  render() {
    return <Grid container>
      <Grid xs={12}>
        <Tabs value={this.state.selectedTab} onChange={(_event, newValue) => this.setState({selectedTab: newValue})}>
          <Tab value={'customer'} label={'Customer Transaction Documents'} />
          <Tab value={'partner'} label={'Partner Transaction Documents'} />
        </Tabs>
      </Grid>
      {
        this.state.selectedTab === 'customer' && <Grid xs={12}>
          <Paper style={{height: 'calc(100vh - 240px)'}}>
            <DataGridPro
              rows={this.state.customerTransactionDocuments}
              columns={this.customerTransactionDocumentsColumns}
              loading={this.state.loading}
              onRowsScrollEnd={() => this.handleOnRowsScrollEnd('customer')}
              rowCount={this.state.customerTotalItemsCount}
              slots={{toolbar: GridToolbar}}

              filterMode={'server'}
              filterModel={this.state.customerFilterModel}
              onFilterModelChange={(filterModel, details) => this.handleFilterModelChange(filterModel, details, 'customer')}
              filterDebounceMs={1500}
              headerFilters
              headerFilterHeight={80}

              sortingMode={'server'}
              sortModel={this.state.customerSortModel}
              onSortModelChange={(sortModel) => this.handleSortModelChange(sortModel, 'customer')}

              initialState={{
                density: 'compact',
                sorting: {sortModel: this.state.customerSortModel},
                filter: {filterModel: this.state.customerFilterModel},
                pinnedColumns: {left: ['documentNumber'], right: ['actions']},
              }}
            />
          </Paper>
        </Grid>
      }
      {
        this.state.selectedTab === 'partner' && <Grid xs={12}>
          <Paper style={{height: 'calc(100vh - 240px)'}}>
            <DataGridPro
              rows={this.state.partnerTransactionDocuments}
              columns={this.partnerTransactionDocumentsColumns}
              loading={this.state.loading}
              onRowsScrollEnd={() => this.handleOnRowsScrollEnd('partner')}
              rowCount={this.state.partnerTotalItemsCount}
              slots={{toolbar: GridToolbar}}

              filterMode={'server'}
              filterModel={this.state.partnerFilterModel}
              onFilterModelChange={(filterModel, details) => this.handleFilterModelChange(filterModel, details, 'partner')}
              filterDebounceMs={1500}
              headerFilters
              headerFilterHeight={80}

              initialState={{
                density: 'compact',
                sorting: {sortModel: this.state.partnerSortModel},
                filter: {filterModel: this.state.partnerFilterModel},
                pinnedColumns: {left: ['documentName'], right: ['actions']},
              }}
            />
          </Paper>
        </Grid>
      }
      <Snackbar
        open={this.state.snackbarOpen}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        autoHideDuration={4000}
        onClose={(_event, reason) => reason !== 'clickaway' && this.setState({snackbarOpen: false})}
      >
        <Alert onClose={() => this.setState({snackbarOpen: false})} severity={this.state.snackbarSeverity} sx={{width: '100%'}}>
          {this.state.snackbarMessage}
        </Alert>
      </Snackbar>
    </Grid>;
  }
}

export default TransactionDocumentsTable;
