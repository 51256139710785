import React from 'react';
import Sidebar from 'layout/Sidebar/Sidebar';
import TopBar from 'layout/TopBar/TopBar';
import MainContainer from 'layout/MainContainer/MainContainer';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import toast, {Toaster} from 'react-hot-toast';
import './AppLayout.scss';

const AppLayout = (props) => {
  const [toggled, setToggled] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(true);

  const handleToggle = (value) => {
    setToggled(value);
    setCollapsed(!value);
  };

  return (
    <React.Fragment>
      <Toaster
        position="bottom-center"
        toastOptions={{
          style: {
            zIndex: '9999',
            padding: '16px',
            backgroundColor: '#101c3e',
            color: 'white',
          },
        }}
      />
      <TopBar {...props} handleSidebarToggle={handleToggle} sidebarToggled={toggled} />

      <div className={`app ${toggled ? 'toggled' : ''} ${collapsed ? 'collapsed' : ''}`}>
        <Sidebar {...props} toast={toast} collapsed={collapsed} toggled={toggled} handleSidebarToggle={handleToggle} handleSidebarCollapsed={setCollapsed} />
        <ErrorBoundary {...props} toast={toast}>
          <MainContainer {...props} toast={toast} />
        </ErrorBoundary>
      </div>
    </React.Fragment>
  );
};

export default AppLayout;
