import React, {Component} from 'react';
import BasePage from 'pages/BasePage';
import OrdersTable from 'components/OrdersTable/OrdersTable';

class OrdersPage extends Component {
  componentDidMount() {
    document.title = 'KMS Kontrol - Orders';
  }

  render() {
    return (
      <BasePage title={'Orders'} requiredRole={'kontrol_ordering'} {...this.props}>
        <OrdersTable  {...this.props} />
      </BasePage>
    );
  }
}

export default OrdersPage;
