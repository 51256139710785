import React, {Component} from 'react';
import BasePage from '../BasePage';
import PositionsTable from '../../components/PositionsTable/PositionsTable';

class PositionsPage extends Component {
  componentDidMount() {
    document.title = 'KMS Kontrol - Positions';
  }

  render() {
    return (
      <BasePage title={'Positions'} requiredRole={'kaktus'} {...this.props}>
        <PositionsTable  {...this.props} />
      </BasePage>
    );
  }
}

export default PositionsPage;
