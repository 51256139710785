import React, {Component} from 'react';
import {Button, Card, CardBody, CardHeader, Col, FormInput, Row} from 'shards-react';
import Select from 'react-select';
import {
  createSupplierGroupProcessCosts,
  deleteSupplierGroupProcessCosts,
  getSupplierGroupProcessCosts,
  getSupplierGroups,
  patchSupplierGroup,
  patchSupplierGroupProcessCosts,
} from 'services/kooperKontrol';
import {getProcesses} from 'services/knestKontrol';
import {Add, Delete} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import './SupplierGroupHourlyCostsTable.css';
import CreateSupplierGroupModal from '../Modals/CreateSupplierGroupModal/CreateSupplierGroupModal';

const _ = require('lodash');

class SupplierGroupHourlyCostsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: {},
      header: ['Process', 'Setup Costs', 'Process Costs', 'Min Costs', 'Unit', 'Delete', ''],
      companyCosts: [],
      selectedSupplierGroup: null,
      processes: [],
      processesForSelect: [],
      selectedProcess: null,
      supplierGroups: [],
      modalOpen: false,
    };
  }

  componentDidMount() {
    getProcesses()
      .then(res => this.setState({processes: res}));

    getSupplierGroups()
      .then(res => this.setState({supplierGroups: res}));
  }

  updateCompanyCosts = () => {
    const companyId = this.state.selectedSupplierGroup.value;
    getSupplierGroupProcessCosts(companyId)
      .then((costs) => {
        // Insert Unit from processes into costs
        costs.forEach(costItem => costItem.unit = this.state.processes.find(item => item.key === costItem.processKey).unit);

        this.setState({companyCosts: costs});

        //filter default costs arr for processes that are not selected
        const filteredProcesses = this.state.processes.filter(process =>
          !costs.some(item => item.processKey === process.key),
        );
        this.setState({processesForSelect: filteredProcesses});
      })
      .catch(error => {
        this.setState({error});
      });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.selectedSupplierGroup !== prevState.selectedSupplierGroup && this.state.selectedSupplierGroup) {
      this.updateCompanyCosts();
    }
  }

  handleNewRow = async () => {

    const processKey = this.state.selectedProcess.value;
    const defaultValues = this.state.processes.find(process => process.key === processKey);
    const supplierGroup = this.state.selectedSupplierGroup.value;
    const newItemData = {
      supplierGroupKey: supplierGroup,
      processKey: processKey,
      setupCosts: defaultValues.defaultSetupCosts,
      processCosts: defaultValues.defaultProcessCosts,
      unit: defaultValues.unit,
    };

    const processesForSelectCopy = JSON.parse(JSON.stringify(this.state.processesForSelect));
    _.remove(processesForSelectCopy, (item) => {
      return item.key === processKey;
    });

    await createSupplierGroupProcessCosts(newItemData);

    this.updateCompanyCosts();
    this.setState({selectedProcess: null});

  };

  handleDelete = (id) => {
    deleteSupplierGroupProcessCosts(id)
      .then(() => {
        this.updateCompanyCosts();
      });
  };

  handleCostsChange = async (type, value, id) => {
    const data = {
      id: id,
      setupCosts: type === 'setupCosts' ? value : undefined,
      processCosts: type === 'processCosts' ? value : undefined,
      minCosts: type === 'minCosts' ? value : undefined,

    };
    const companyCostsCopy = JSON.parse(JSON.stringify(this.state.companyCosts));
    const currentCosts = companyCostsCopy.find(item => item.id === id);
    type === 'setupCosts' && (currentCosts.setupCosts = value);
    type === 'processCosts' && (currentCosts.processCosts = value);
    type === 'minCosts' && (currentCosts.minCosts = value);

    this.setState({companyCosts: companyCostsCopy});
    await patchSupplierGroupProcessCosts(data);
  };

  validateTargetCosts = (value) => {
    return Math.max(Math.min(99.99, value), 0);
  };

  patchSupplierGroup = async (key, value, commit = true, validate = (value) => value) => {
    this.setState({selectedSupplierGroup: {...this.state.selectedSupplierGroup, [key]: validate(parseFloat(value))}});
    const newSupplierGroupsArr = [...this.state.supplierGroups];
    let currSupplierGroup = this.state.supplierGroups.find(sg => sg.key === this.state.selectedSupplierGroup.key);

    const index = this.state.supplierGroups.findIndex(sg => sg.key === this.state.selectedSupplierGroup.key);
    newSupplierGroupsArr[index] = {...currSupplierGroup, [key]: validate(parseFloat(value))};

    this.setState({supplierGroups: newSupplierGroupsArr});

    if (commit) {
      await patchSupplierGroup(this.state.selectedSupplierGroup.key, key, parseFloat(value));
    }
  };

  mapTableHeader = () => {
    return this.state.header.map((item) => {
      return (
        <th scope="col" className="border-0">
          {item}
        </th>
      );
    });
  };

  handleModalToggle = () => {
    getSupplierGroups()
      .then(res => this.setState({supplierGroups: res, modalOpen: false}));

  };

  mapTableRows = () => {
    return this.state.companyCosts.map((item) => {
      return (
        <tr>
          <td>{item.processKey}</td>
          <td>
            <FormInput
              className="cost-form-input"
              type={'number'}
              value={item.setupCosts}
              onChange={(e) => this.handleCostsChange('setupCosts', e.target.value, item.id)}
            />
          </td>
          <td>
            <FormInput
              className="cost-form-input"
              type={'number'}
              value={item.processCosts}
              onChange={(e) => this.handleCostsChange('processCosts', e.target.value, item.id)}
            />
          </td>
          <td>
            <FormInput
              className="cost-form-input"
              type={'number'}
              value={item.minCosts}
              onChange={(e) => this.handleCostsChange('minCosts', e.target.value, item.id)}
            />
          </td>
          <td>{item.unit}</td>
          <td>
            <IconButton onClick={() => this.handleDelete(item.id)}>
              <Delete />
            </IconButton>
          </td>
        </tr>
      );
    });
  };

  render() {
    return (
      <div>
        <CreateSupplierGroupModal open={this.state.modalOpen} onToggle={() => this.handleModalToggle()} />
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader style={{paddingBottom: 0}}>
                <Row>
                  <Col sm="12" md="11" lg="11">
                    <div>
                      Supplier Group
                    </div>
                  </Col>
                  <Col>
                    <IconButton onClick={() => this.setState({modalOpen: !this.state.modalOpen})}>
                      <Add />
                    </IconButton>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody style={{paddingTop: 0}}>
                <Row>
                  <div className="async-search-wrapper">
                    <Select
                      placeholder={'Select a Supplier Group...'}
                      options={this.state.supplierGroups.map(process => ({
                        label: process.key,
                        value: process.key,
                        ...process,
                      }))}
                      onChange={(e) => {
                        this.setState({selectedSupplierGroup: e});
                      }}
                      value={this.state.selectedSupplierGroup}
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {this.state.selectedSupplierGroup &&
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader>

                  Supplier Group Data

                </CardHeader>

                <CardBody>
                  <Row>
                    <Col>
                      Buffer in Working Days:
                    </Col>
                    <Col>
                      <FormInput
                        type={'number'}
                        value={this.state.selectedSupplierGroup.additionalLeadtimeBuffer}
                        onChange={(e) => this.patchSupplierGroup('additionalLeadtimeBuffer', e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row style={{marginTop: 10}}>
                    <Col>
                      Target Costs Margin [%] (0 &lt;= Target Cost Margin &lt; 100):
                    </Col>
                    <Col>
                      <FormInput
                        type={'number'}
                        value={this.state.selectedSupplierGroup.targetCostsMargin}
                        onChange={({target: {value}}) => this.patchSupplierGroup('targetCostsMargin', value, false, this.validateTargetCosts)}
                        onBlur={(e) => this.patchSupplierGroup('targetCostsMargin', e.target.value)}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>}

        {this.state.selectedSupplierGroup &&
          <React.Fragment>
            <Row>
              <Col>
                <Card small className="mb-4">
                  <CardHeader>
                    Supplier Group Hourly Costs
                  </CardHeader>

                  <CardBody className="p-0 pb-3">
                    <table className="table mb-0">
                      <thead className="bg-light">
                      {this.mapTableHeader()}
                      </thead>
                      <tbody>
                      {this.mapTableRows()}
                      <tr>
                        <td colSpan={2}>
                          <Select
                            placeholder={'Select a Process...'}
                            options={this.state.processesForSelect.map(process => ({
                              label: process.key,
                              value: process.key,
                            }))}
                            onChange={(e) => this.setState({selectedProcess: e})}
                            value={this.state.selectedProcess}

                          />
                        </td>
                        <td>
                          <Button
                            theme="success" pill onClick={this.handleNewRow}
                            disabled={!this.state.selectedProcess}
                          >+</Button>
                        </td>
                        <td />
                        <td />
                        <td />
                      </tr>

                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        }
      </div>
    );
  }
}

export default SupplierGroupHourlyCostsTable;
