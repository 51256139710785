import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import * as Sentry from '@sentry/browser';
import {Integrations} from '@sentry/tracing';
import {createTheme, ThemeProvider, responsiveFontSizes} from '@mui/material/styles';
import {LicenseInfo} from '@mui/x-license';

//Init MUI X Pro
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENSE!);

//Init Sentry and add user context
if (process.env.REACT_APP_SENTRY_ENABLE) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    // @ts-ignore
    project: 'kaktus',
    autoSessionTracking: true,
  });
}

const theme = responsiveFontSizes(createTheme({
  palette: {
    primary: {
      main: '#32BEE1',
    },
    secondary: {
      main: '#43CCAD',
    },
    info: {
      main: '#32BEE1',
    },
    warning: {
      main: '#F7B01E',
    },
    error: {
      main: '#FF645F',
    },
    success: {
      main: '#43CCAD',
    },
  },
  typography: {
    fontFamily: [
      'Titillium Web',
    ].join(','),
  },
}));

const root = createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
);
