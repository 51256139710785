import React from 'react';
import './Modals.css';
import {Button, Modal, ModalFooter} from 'react-bootstrap';
import {FailureIcon, InfoIcon, SuccessIcon} from '../AnimatedIcons/AnimatedIcons';
import {BeatLoader} from 'react-spinners';

/* Props
 stage -> [content, loading, success, loading]
 open -> bool
 content -> component
 successMessage -> string
 failureMessage -> string
 infoMessage -> string
 buttonTheme -> string
 buttonText -> component / string
 modalTitle -> string
 update -> function (pass to parent)

 */

export class BaseModal extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      open: this.props.open,
      stage: this.props.stage,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.stage !== prevProps.stage) {
      this.setState({stage: this.props.stage});
    }
    if (this.props.open !== prevProps.open) {
      this.setState({open: this.props.open});
    }
  }

  toggle() {
    if (this.props.open && this.props.success === 3) {
      this.props.update();
    }
    this.setState({
      stage: 'content',
    });
    this.props.onToggle();
  }

  showModalContent() {
    const style = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '216px',
    };
    /*eslint-disable*/
    switch (this.state.stage) {
      case 'content':
        return this.props.content;

      case 'loading':
        return (
          <div style={style}>
            <BeatLoader size={30} color={'#00be68'} />
          </div>
        );

      case 'success':
        return (
          <div style={style}>
            <SuccessIcon />
            <ModalFooter>{this.props.successMessage}</ModalFooter>
          </div>
        );

      case 'info':
        return (
          <div style={style}>
            <InfoIcon />
            <ModalFooter>{this.props.infoMessage}</ModalFooter>
          </div>
        );

      case 'failure':
        return (
          <div style={style}>
            <FailureIcon />
            <ModalFooter>{this.props.failureMessage}</ModalFooter>
          </div>
        );
    }
  }

  render() {
    return (
      <div>
        {this.props.noButton || (
          <Button
            block={this.props.size === 'small' ? false : true}
            theme={this.props.buttonTheme}
            disabled={this.props.disabled}
            size="lg"
            className={this.props.size === 'small' ? '' : 'mt-2 p-2'}
            onClick={this.toggle}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: this.props.size !== 'small' ? '53.1944px' : '',
            }}
          >
            {this.props.buttonText}
          </Button>
        )}
        <Modal
          show={this.props.open}
          onHide={this.toggle}
          size={this.props.modalSize || 'lg'}
          dialogClassName={this.props.customClass}
          animation={true}
          centered={this.props.centered}
          style={this.props.modalStyle}
        >
          <Modal.Header closeButton>{this.props.modalTitle}</Modal.Header>
          <Modal.Body className={this.props.modalBodyClassName}>
            {this.showModalContent()}
          </Modal.Body>
          {this.state.stage !== 'content' && this.state.stage !== 'loading' ? (
            <Modal.Footer>
              <Button block onClick={this.toggle} theme="success">
                {'Close'}
              </Button>
            </Modal.Footer>
          ) : null}
        </Modal>
      </div>
    );
  }
}

export default BaseModal;