import React, {Component} from 'react';
import ProcessesTable from 'components/ProcessesTable/ProcessesTable';
import BasePage from 'pages/BasePage';

class ProcessesPage extends Component {
  componentDidMount() {
    document.title = 'KMS Kontrol - Processes';
  }

  render() {
    return (
      <BasePage
        title={'Processes'}
        subtitle={'Referential Data'}
        requiredRole={'kontrol_pricing'}
        {...this.props}
      >
        <ProcessesTable />
      </BasePage>
    );
  }
}

export default ProcessesPage;
