import React, {Component} from 'react';
import {
  Autocomplete, Box, Chip,
  Fade,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import {withTheme} from '@mui/styles';
import {Close} from '@mui/icons-material';
import './SelectOptionsModal.scss';
import {getSelectOptions, getProcessFeature, patchProcessFeature} from '../../../services/knestKontrol';

class SelectOptionsModal extends Component {
  constructor(props) {
    super(props);

    this.defaultSate = {
      selectOptions: [],
      selectedSelectOptions: [],
      loading: true,
    };

    this.state = {
      ...this.defaultSate,
    };
  }

  fetchAndRefreshData = async () => {
    await this.setState({loading: true});

    const selectOptions = (await getSelectOptions()).map(option => ({selectOptionKey: option.key}));
    const processFeature = await getProcessFeature(this.props.processFeature?.key, {
      filter: {include: {processFeatureSelectOptions: true}},
    });

    await this.setState({
      loading: false,
      selectOptions,
      selectedSelectOptions: processFeature.processFeatureSelectOptions,
    });
  };

  async componentDidMount() {
    await this.fetchAndRefreshData();
  }

  closeModal = async () => {
    this.props.confirm();
    this.props.setOpen(false);

    // Reset state
    this.setState(this.defaultSate);
  };

  handleSelectOptionsChange = async (reason, option) => {
    await patchProcessFeature(this.props.processFeature.key, {
      processFeatureSelectOptions: {
        ...reason === 'removeOption' && {
          deleteMany: {
            AND: {
              processFeatureKey: this.props.processFeature.key,
              selectOptionKey: option.selectOptionKey,
            },
          },
        },
        ...['createOption', 'selectOption'].includes(reason) && {
          upsert: {
            where: {
              processFeatureKey_selectOptionKey: {
                processFeatureKey: this.props.processFeature.key,
                selectOptionKey: option.selectOptionKey || option,
              },
            },
            create: {
              selectOption: {
                connectOrCreate: {
                  where: {key: option.selectOptionKey || option},
                  create: {key: option.selectOptionKey || option},
                }
              },
            },
            update: {},
          },
        },
      },
    });

    await this.fetchAndRefreshData();
  };

  render() {
    const {open, processFeature} = this.props;

    return (
      <Modal open={open} closeAfterTransition={true}>
        <Fade in={open} timeout={250}>
          <Box className={'select-options-modal'}>
            <Paper sx={{height: '100%'}}>
              <IconButton
                size={'small'}
                className={'close-select-options-modal-button'}
                onClick={this.closeModal}
              >
                <Close />
              </IconButton>
              <Grid
                container
                spacing={2}
                className={'select-options-modal-content'}
              >
                <Grid xs={12}>
                  <Typography>Editing Select Options for {processFeature?.key}</Typography>
                </Grid>
                <Grid xs={12} alignItems={'flex-end'} sx={{width: '100%'}}>
                  <Autocomplete
                    fullWidth
                    multiple
                    freeSolo
                    disableClearable
                    getOptionLabel={option => option.selectOptionKey}
                    options={this.state.selectOptions}
                    value={this.state.selectedSelectOptions}
                    renderInput={params => <TextField {...params} label="Select Options" />}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) =>
                        <Chip
                          variant={'filled'}
                          label={option.selectOptionKey}
                          {...getTagProps({index})}
                        />)
                    }
                    onChange={(event, newValue, reason, {option}) => this.handleSelectOptionsChange(reason, option)}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Fade>
      </Modal>
    );
  }
}

export default withTheme(SelectOptionsModal);