import React from 'react';
import ErrorPage from 'components/ErrorPage/ErrorPage';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      eventId: '',
      href: '',
    };
  }

  componentDidUpdate() {
    /* Compare current href to href when error occured to reset hasError state when page changed*/
    if (this.state.href !== window.location.href && this.state.hasError) {
      this.setState({hasError: false});
    }
  }

  componentDidCatch(error, errorInfo) {
    const eventId = Sentry.captureException(error, {extra: errorInfo});
    console.error(error, errorInfo);
    this.setState({hasError: true, href: window.location.href, eventId});
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage statusCode={''} sentryEventId={this.state.eventId} />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
