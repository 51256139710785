import React, {Component} from 'react';
import {Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'shards-react';
import userLogo from './person_hoodie.svg';
import './UserMenu.css';


class UserMenu extends Component{
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      open: false,
    };
  }

  toggle() {
    this.setState({open: !this.state.open});
  }

  handleProfileClick = () => {
    this.props.redirectToProfilePage();
    this.setState({open: false});
  };

  handleLogoutClick = () => {
    this.props.logout();
    this.setState({open: false});
  };

  render() {
    return (
      <React.Fragment>

        <Dropdown open={this.state.open} toggle={this.toggle}>

          <DropdownToggle nav>
            <Button className={'user-button'}>
              <div>
                <img src={userLogo} alt={'User Logo'} />
              </div>
            </Button>
          </DropdownToggle>

          <DropdownMenu className={'user-dropdown'} right>
            <DropdownItem onClick={this.handleLogoutClick}>
              <i className='fas fa-sign-out-alt' />
              <span className={'item-text'}>{'Logout'}</span>
            </DropdownItem>
          </DropdownMenu>

        </Dropdown>

      </React.Fragment>
    );
  }
}

export default UserMenu;