import React, {Component} from 'react';
import {Container} from 'shards-react';
import LockIcon from 'assets/img/lock.svg';

class NoPermission extends Component {
  render() {
    return (
      <Container fluid className="main-content-container px-4 pb-4">
        <div>
          <div className="error__content" style={{marginTop: '10%'}}>
            <img alt="lock" src={LockIcon} style={{height: '300px', marginTop: '20px', marginBottom: '20px'}} />
            <h4>You don't have the required role to view this page.</h4>
            <p>Please contact support if you need access.</p>
          </div>
        </div>
      </Container>
    );
  }
}

export default NoPermission;
