import {knestKontrolInstance} from './axiosInstances';
import {Company, OrderedPartList, Prisma, Shipment} from '@prisma/client';
import {getToken} from '../utils/userHelpers';

export const getProcesses = async params => {
  return knestKontrolInstance.get('/processes', {params});
};

export const getProcess = async (processKey, params) => {
  return knestKontrolInstance.get(`/processes/${processKey}`, {params});
};

export const patchProcess = async (processKey, data) => {
  return knestKontrolInstance.patch(`/processes/${processKey}`, data);
};

export const putProcess = async (processKey, data) => {
  return knestKontrolInstance.put(`/processes/${processKey}`, data);
};

export const getProcessFeatures = async (orderBy = {key: 'asc'}) => {
  return knestKontrolInstance.get(`/process-features`, {
    params: {
      filter: {
        orderBy,
        include: {kaktusProcessFeatures: true, processFeatureSelectOptions: true},
      },
    },
  });
};

export const getProcessFeature = async (key, params) => {
  return knestKontrolInstance.get(`/process-features/${key}`, {params});
};

export const patchProcessFeature = async (key, payload) => knestKontrolInstance.patch(`/process-features/${key}`, payload);

export const postProcessFeature = async (payload) => knestKontrolInstance.post('/process-features', payload);

export const deleteProcessFeature = async key => knestKontrolInstance.delete(`/process-features/${key}`);

export const postKaktusProcessFeature = async (payload) => knestKontrolInstance.post('/kaktus-process-features', payload);

export const deleteKaktusProcessFeature = async (key) => knestKontrolInstance.delete(`/kaktus-process-features/${key}`);

export const getProcessCategories = async params => {
  return knestKontrolInstance.get('/process-categories', {params});
};

export const getSelectOptions = async params => {
  return knestKontrolInstance.get('select-options', {params});
};

export const getMaterials = async params => {
  return knestKontrolInstance.get('materials', {params});
};

export const getMaterialCategories = async params => {
  return knestKontrolInstance.get('material-categories', {params});
};

export const patchMaterialCategory = async (materialCategoryKey, data) => {
  return knestKontrolInstance.patch(`material-categories/${materialCategoryKey}`, data);
};

export const createMaterialCategory = async data => {
  return knestKontrolInstance.post('material-categories', data);
};

export const deleteMaterialCategory = async materialCategoryKey => {
  return knestKontrolInstance.delete(`material-categories/${materialCategoryKey}`);
};

export const patchMaterial = async (materialId, data) => {
  return knestKontrolInstance.patch(`materials/${materialId}`, data);
};

export const createMaterial = async data => {
  return knestKontrolInstance.post('materials', data);
};

export const getMaterialNameStandards = async params => {
  return knestKontrolInstance.get('material-name-standards', {params});
};

export const createMaterialNameStandard = async data => {
  return knestKontrolInstance.post('material-name-standards', data);
};

export const deleteMaterialNameStandard = async materialNameStandardKey => {
  return knestKontrolInstance.delete(`material-name-standards/${materialNameStandardKey}`);
};

export const getMaterialShapes = async params => {
  return knestKontrolInstance.get('material-shapes', {params});
};

export const createMaterialShape = async data => {
  return knestKontrolInstance.post('material-shapes', data);
};

export const deleteMaterialShape = async materialShapeKey => {
  return knestKontrolInstance.delete(`material-shapes/${materialShapeKey}`);
};

export const getMaterialHeatStates = async params => {
  return knestKontrolInstance.get('material-heat-states', {params});
};

export const createMaterialHeatState = async data => {
  return knestKontrolInstance.post('material-heat-states', data);
};

export const deleteMaterialHeatState = async materialHeatStateKey => {
  return knestKontrolInstance.delete(`material-heat-states/${materialHeatStateKey}`);
};

export const getCompanies = async params => {
  return knestKontrolInstance.get<void, Company[]>('companies', {params});
};

export const patchCompany = async (companyId, data) => {
  return knestKontrolInstance.patch(`companies/${companyId}`, data);
};

export const getCompanyFeatures = async params => {
  return knestKontrolInstance.get('company-features', {params});
};

export const createOrderConfirmation = async (orderId, sendEmail = false) => {
  return knestKontrolInstance.post(`ordered-part-lists/${orderId}/create-order-confirmation?sendEmail=${sendEmail}`);
};

export const getOrders = async <I extends Prisma.OrderedPartListTeaserInclude | null | undefined = {}>(
  page: number,
  pageSize: number,
  orderBy: Prisma.OrderedPartListTeaserFindManyArgs['orderBy'],
  include: I,
  where: Prisma.OrderedPartListTeaserFindManyArgs['where'],
) => {
  return knestKontrolInstance.get<void, {
    page: Prisma.OrderedPartListTeaserGetPayload<{ include: I }>[],
    pagination: { totalItemCount: number, currentPage: number, itemsPerPage: number }
  }
  >('ordered-part-list-teasers', {
    params: {
      filter: {
        include,
        limit: pageSize,
        orderBy,
        page,
        where,
      },
    },
  });
};

export const cancelOrder = async (orderId: number) => {
  return knestKontrolInstance.post(`ordered-part-lists/${orderId}/cancel`);
};

export const updateOrder = async (orderId: number, data: Prisma.OrderedPartListUpdateInput) => {
  return knestKontrolInstance.patch<Prisma.OrderedPartListUpdateInput, OrderedPartList>(`ordered-part-lists/${orderId}`, data);
};

export const getPositions = async <I extends Prisma.BundleOrderedPartConfigTeaserInclude | null | undefined = {}>(
  page: number,
  pageSize: number,
  orderBy: Prisma.BundleOrderedPartConfigTeaserFindManyArgs['orderBy'],
  include: I,
  where: Prisma.BundleOrderedPartConfigTeaserFindManyArgs['where'],
) => {
  return knestKontrolInstance.get<void, {
    page: Prisma.BundleOrderedPartConfigTeaserGetPayload<{ include: I }>[],
    pagination: { totalItemCount: number, currentPage: number, itemsPerPage: number }
  }>('bundle-ordered-part-config-teasers', {
    params: {
      filter: {
        include,
        limit: pageSize,
        orderBy,
        page,
        where,
      },
    },
  });
};

export const getShipments = async <I extends Prisma.ShipmentTeaserInclude | null | undefined = {}>(
  page: number,
  pageSize: number,
  orderBy: Prisma.ShipmentTeaserFindManyArgs['orderBy'],
  include: I,
  where: Prisma.ShipmentTeaserFindManyArgs['where'],
) => {
  return knestKontrolInstance.get<void, {
    page: Prisma.ShipmentTeaserGetPayload<{ include: I }>[],
    pagination: { totalItemCount: number, currentPage: number, itemsPerPage: number }
  }>('shipment-teasers', {
    params: {
      filter: {
        include,
        limit: pageSize,
        orderBy,
        page,
        where,
      },
    },
  });
};

export const getShipment = async <I extends Prisma.ShipmentTeaserInclude | null | undefined = {}>(shipmentId: number, include: I) => {
  return knestKontrolInstance.get<void, Prisma.ShipmentTeaserGetPayload<{ include: I }>>(`shipment-teasers/${shipmentId}`, {
    params: {
      filter: {
        include,
      },
    },
  });
};

export const updateShipment = async (shipmentId: number, data: Prisma.ShipmentUpdateInput) => {
  return knestKontrolInstance.patch <Prisma.ShipmentUpdateInput, Shipment>(`shipments/${shipmentId}`, data);
};

export const updateShipmentStatus = async (shipmentId: number, statusKey: string) => {
  return knestKontrolInstance.post(`shipments/${shipmentId}/status/${statusKey}`);
};

export const createShipment = async (data: {
  carrierKey: string,
  bundleOrderedPartConfigIds: number[],
  trackingNumber: string | null,
  fromCompanyId: number,
  toCompanyId: number,
  toAddress: {
    toAddressLine1: string,
    toAddressLine2?: string | null,
    toCity: string,
    toState: string,
    toZipCode: string,
    toCountryKey: string,
  },
  fromAddress?: {
    fromAddressLine1?: string,
    fromAddressLine2?: string | null,
    fromCity?: string,
    fromState?: string,
    fromZipCode?: string,
    fromCountryKey?: string,
  },
  isExpressShipment?: boolean,
}) => {
  return knestKontrolInstance.post<any, Prisma.ShipmentGetPayload<{}>>('shipments', data);
};

export const createInvoice = async (orderedPartListId: number, sendEmail: boolean = false, orderedPartConfigIds?: number[]) => {
  return knestKontrolInstance.post(`ordered-part-lists/${orderedPartListId}/create-invoice?sendEmail=${sendEmail}`, {orderedPartConfigIds});
};

export const getCustomerTransactionDocuments = async <I extends Prisma.CustomerTransactionDocumentTeaserInclude | null | undefined = {}>(
  page: number,
  pageSize: number,
  orderBy: Prisma.CustomerTransactionDocumentTeaserFindManyArgs['orderBy'],
  include: I,
  where: Prisma.CustomerTransactionDocumentTeaserFindManyArgs['where'],
) => {
  return knestKontrolInstance.get<void, {
    page: Prisma.CustomerTransactionDocumentTeaserGetPayload<{ include: I }>[],
    pagination: { totalItemCount: number, currentPage: number, itemsPerPage: number }
  }>('customer-transaction-document-teasers', {
    params: {
      filter: {
        include,
        limit: pageSize,
        orderBy,
        page,
        where,
      },
    },
  });
};

export const getPartnerTransactionDocuments = async <I extends Prisma.PartnerTransactionDocumentTeaserInclude | null | undefined = {}>(
  page: number,
  pageSize: number,
  orderBy: Prisma.PartnerTransactionDocumentTeaserFindManyArgs['orderBy'],
  include: I,
  where: Prisma.PartnerTransactionDocumentTeaserFindManyArgs['where'],
) => {
  return knestKontrolInstance.get<void, {
    page: Prisma.PartnerTransactionDocumentTeaserGetPayload<{ include: I }>[],
    pagination: { totalItemCount: number, currentPage: number, itemsPerPage: number }
  }>('partner-transaction-document-teasers', {
    params: {
      filter: {
        include,
        limit: pageSize,
        orderBy,
        page,
        where,
      },
    },
  });
};

export const getPartnerTransactionDocumentFiles = async (id: number) => {
  const fileResponse = await knestKontrolInstance.get(`/partner-transaction-documents/${id}/files`, {responseType: 'arraybuffer'});
  return downloadFile(fileResponse);
};

export const downloadFile = async (fileResponse) => {
  const fileName = decodeURI(fileResponse.headers['content-disposition']?.split('"')[1]);
  const contentType = fileResponse.headers['content-type'];

  if (contentType === 'application/pdf') {
    const url = window.URL.createObjectURL(new Blob([fileResponse.data], {type: contentType}));
    window.open(
      url,
      '_blank',
      'height=600,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no',
    );
    return;
  }

  const url = window.URL.createObjectURL(new Blob([fileResponse.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const markCustomerInvoiceAsPaid = async (orderedPartListId: number, documentId: number) => {
  return knestKontrolInstance.post(`ordered-part-lists/${orderedPartListId}/invoices/${documentId}/mark-as-paid`);
};

export const markPartnerInvoiceAsPaid = async (documentId: number) => {
  return knestKontrolInstance.post(`partner-transaction-documents/${documentId}/mark-as-paid`);
};

export const downloadShipmentDeliverySlip = async (shipmentId: number, languageKey: 'de' | 'en' = 'de') => {
  window.open(
    `${process.env.REACT_APP_KNEST_KONTROL_URL}/shipments/${shipmentId}/delivery-slip`
    + `?access_token=${getToken().substring(7)}`
    + `&languageKey=${languageKey}`,
    '_blank',
    'height=600,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no',
  );
};

export const patchBundleOrderedPartConfig = async (bundleOrderedPartConfigId: number, data: Prisma.BundleOrderedPartConfigUpdateInput) => {
  return knestKontrolInstance.patch<Prisma.BundleOrderedPartConfigUpdateInput, Prisma.BundleOrderedPartConfigGetPayload<{}>>(`bundle-ordered-part-configs/${bundleOrderedPartConfigId}`, data);
};

export const patchBundleOrderedPartConfigs = async (data: Prisma.BundleOrderedPartConfigUpdateManyArgs) => {
  return knestKontrolInstance.patch<Prisma.BundleOrderedPartConfigUpdateManyArgs, Prisma.BatchPayload>('bundle-ordered-part-configs', data);
};
