import './UsersTable.css';
import React, {Component} from 'react';
import {getRoles, getLanguages, getUsers, patchUser} from 'services/kooperKontrol';
import {createRoleMapping, deleteRoleMapping, resetPassword} from 'services/kooperAuth';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {Autocomplete, Paper, TextField, Tooltip} from '@mui/material';
import {DataGridPro, GridActionsCellItem, GridToolbar} from '@mui/x-data-grid-pro';
import {LockReset} from '@mui/icons-material';

const MySwal = withReactContent(Swal);

export class UsersTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      header: ['id', 'fist_name', 'last_name', 'email', 'new_password', 'language', 'emailVerified', 'receive_emails', 'roles'],
      users: [],
      password: '',
      searchValue: '',
      roles: [],
      languages: [],
      columns: [],
    };

    this.columns = [
      {field: 'id', headerName: 'ID', width: 75},
      {field: 'firstName', headerName: 'First Name', width: 150, editable: true},
      {field: 'lastName', headerName: 'Last Name', width: 150, editable: true},
      {field: 'email', headerName: 'Email', width: 250},
      {field: 'crmId', headerName: 'CRM ID', width: 200},
      {field: 'language', headerName: 'Language', width: 100, editable: true, type: 'singleSelect', valueOptions: this.state.languages.map(lng => lng.key)},
      {field: 'emailVerified', headerName: 'Verified?', width: 100, type: 'boolean'},
      {field: 'receiveEmails', headerName: 'Receive Emails', width: 120, type: 'boolean'},
      {field: 'roles', headerName: 'Roles', width: 350},
      {field: 'actions', headerName: 'Actions', width: 150},
    ];
  }

  async componentDidMount() {
    const roles = await getRoles();
    const languages = await getLanguages();
    const users = await getUsers();

    this.setState({
      roles,
      languages,
      users,
      columns: [
        {field: 'id', headerName: 'ID', width: 75},
        {field: 'firstName', headerName: 'First Name', width: 150, editable: true},
        {field: 'lastName', headerName: 'Last Name', width: 150, editable: true},
        {field: 'email', headerName: 'Email', width: 250},
        {field: 'crmId', headerName: 'CRM ID', width: 200},
        {
          field: 'language', headerName: 'Language', width: 100, editable: true, type: 'singleSelect', align: 'center',
          valueOptions: languages.map(lng => ({value: lng.key, label: lng.emoji})),
        },
        {field: 'emailVerified', headerName: 'Verified?', width: 100, type: 'boolean', editable: true},
        {field: 'receiveEmails', headerName: 'Receive Emails', width: 120, type: 'boolean', editable: true},
        {
          field: 'roles', headerName: 'Roles', width: 300,
          renderCell: ({id, value}) => {
            return <Autocomplete
              multiple
              fullWidth
              value={value}
              onChange={(event, value, reason, {option}) => this.handleRoleChange(id, reason, option, value)}
              filterSelectedOptions
              size={'small'}
              options={roles}
              getOptionLabel={option => option.name}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              sx={{maxHeight: '100%'}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={'standard'}
                  sx={{overflowY: 'scroll'}}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: false,
                    disableUnderline: true,
                  }}
                />
              )}
            />;
          },
        },
        {
          field: 'actions', headerName: 'Actions', type: 'actions', width: 150,
          getActions: (params) => [
            <GridActionsCellItem
              label={'Reset Password'}
              icon={<Tooltip title={'Reset Password'}><LockReset /></Tooltip>}
              onClick={() => this.handlePasswordResetButtonClick(params.row)}
            />,
          ],
        },
      ],
      loading: false,
    });
  }

  handlePasswordResetButtonClick = async (user) => {
    const {isConfirmed} = await MySwal.fire({
      title: 'Reset password?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Reset Password',
      reverseButtons: true,
      allowOutsideClick: false,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await resetPassword(user.email);
        } catch (e) {
          Swal.showValidationMessage(
            `Request failed: ${e.response.data.message}`,
          );
        }
      },
    });

    if (isConfirmed) {
      await MySwal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Password reset successful',
      });
    }
  };

  handleRoleChange = async (userId, reason, option, updatedValue) => {
    if (reason === 'selectOption') await createRoleMapping(userId, option.name);
    if (reason === 'removeOption') await deleteRoleMapping(userId, option.name);

    await this.setState({
      users: this.state.users.map(user => {
        if (user.id === userId) {
          return {
            ...user,
            roles: updatedValue,
          };
        }

        return user;
      }),
    });
  };

  updateUser = async (row) => {
    try {
      await patchUser(row.id, {
        firstName: row.firstName?.trim() || null,
        lastName: row.lastName?.trim() || null,
        language: row.language || null,
        emailVerified: row.emailVerified || false,
        receiveEmails: row.receiveEmails || false,
      });
      return row;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  render() {
    return <Paper sx={{height: 'calc(100vh - 200px)'}}>
      <DataGridPro
        columns={this.state.columns}
        rows={this.state.users}
        density={'compact'}
        loading={this.state.loading}
        getRowHeight={({model}) => {
          return Math.max(36, model.roles.length * 26 + 10);
        }}
        columnVisibilityModel={{
          crmId: false,
        }}
        pinnedColumns={{
          left: ['id', 'firstName', 'lastName'],
          right: ['actions'],
        }}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        editMode={'row'}
        processRowUpdate={(newRow) => this.updateUser(newRow)}
      />
    </Paper>;
  }
}

export default UsersTable;
