import React, {Component} from 'react';
import {Grid, Paper, Tooltip} from '@mui/material';
import {Visibility} from '@mui/icons-material';
import Swal from 'sweetalert2';
import {DataGridPro, GridActionsCellItem, GridToolbar} from '@mui/x-data-grid-pro';
import {getBundles, putBundle, updateBundleStatus} from 'services/kooperKontrol';
import {formatCurrency, toDisplayDate} from 'utils/formatHelpers';

import './BundlesTable.css';

class BundlesTable extends Component {
  constructor(props) {
    super(props);

    this.onFilterChangeTimeout = null;

    this.columns = [
      {field: 'id', hideable: false, filterable: false},
      {field: 'bundleKid', headerName: '#', flex: 0.75, filterable: true},
      {field: 'companyName', headerName: 'Company', flex: 1, filterable: true},
      {
        field: 'statusKey', headerName: 'Status', flex: 0.75, filterable: true, type: 'singleSelect',
        valueOptions: ['new', 'open', 'lost', 'cancelled', 'delivered', 'shipped', 'rejected', 'ordered'],
      },
      {
        field: 'total', headerName: 'Total', flex: 0.75, filterable: false,
        valueGetter: (value, row) => formatCurrency(row.counterOfferPrice || row.price),
      },
      {field: 'crmId', headerName: 'CRM', flex: 0.75, filterable: true, hide: true},
      {field: 'invoiceId', headerName: 'Invoice', flex: 1, filterable: true},
      {field: 'kumberasId', headerName: 'Kumberas', flex: 1, filterable: true, hide: true},
      {
        field: 'deliveryDate', headerName: 'Delivery Date', type: 'date', flex: 1.25, filterable: false,
        valueFormatter: (value) => value ? toDisplayDate(value) : '',
      },
      {
        field: 'actions', headerName: 'Actions', type: 'actions', width: 80, sortable: false, filterable: false,
        getActions: (params) => {
          return [
            <GridActionsCellItem
              label={'View in Kaktus'}
              icon={<Tooltip title={'View in Kaktus'}><Visibility /></Tooltip>}
              onClick={() => window.open(`${process.env.REACT_APP_KAKTUS_URL}/bundle-suppliers/${params.row.id}`)}
            />,
          ];
        },
      },
    ];

    this.state = {
      loading: true,
      bundles: [],
      totalItemCount: 0,
      currentPage: 1,
      itemsPerPage: 50,
      sortModel: [
        {
          field: 'id',
          sort: 'desc',
        },
      ],
      filterModel: {
        logicOperator: 'and',
        items: [
          {field: 'statusKey', operator: 'not', value: 'lost'},
          {field: 'statusKey', operator: 'not', value: 'new'},
          {field: 'statusKey', operator: 'not', value: 'open'},
        ],
      },
    };
  }

  async componentDidMount() {
    const {
      page: bundles,
      pagination: {
        totalItemCount,
        currentPage,
        itemsPerPage,
      },
    } = await getBundles(this.state.currentPage, this.state.itemsPerPage, this.getOrderQuery(), undefined, this.getFilterQuery());

    this.setState({
      loading: false,
      bundles,
      totalItemCount,
      currentPage,
      itemsPerPage,
    });
  }

  setStatus = async (orderId, status) => {
    const {isConfirmed} = await Swal.fire({
      title: 'Are you sure you want to mark this bundle as paid?',
      icon: 'warning',
      confirmButtonText: 'Yes, Mark as Paid',
      showCancelButton: true,
      reverseButtons: true,
      customClass: {
        container: 'custom-swal-container',
      },
    });

    if (isConfirmed) {
      try {
        await updateBundleStatus(orderId, status);
        await Swal.fire({
          title: 'The status was successfully updated.',
          icon: 'success',
          customClass: {
            container: 'custom-swal-container',
          },
        });
        await this.refreshPage();
      } catch (err) {
        await Swal.fire({
          title: 'An error occurred while updating the status of this bundle.',
          icon: 'error',
          text: 'Please try again later, or contact the tech team for support.',
          customClass: {
            container: 'custom-swal-container',
          },
        });
      }
    }
  };

  promptForInvoiceId = async (bundle) => {
    const {isConfirmed, value} = await Swal.fire({
      title: `${bundle.bundleKid}: Invoice ID`,
      input: 'text',
      inputValue: bundle.invoiceId,
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'You need to write something!';
        }
      },
    });
    if (!isConfirmed) return;
    try {
      await putBundle(bundle.id, {invoiceId: value});
      await Swal.fire({
        title: 'Bundle was saved',
        icon: 'success',
      });
      return this.componentDidMount();
    } catch (e) {
      console.error(e);
      await Swal.fire({
        title: 'Failure',
        text: 'Could not save the Bundle.',
        icon: 'error',
      });
    }
  };

  mapQueryOperator = (operator, value) => {
    if (operator === 'equals') return {eq: value};
    if (operator === 'contains') return {ilike: `%${value}%`};
    if (operator === 'startsWith') return {ilike: `${value}%`};
    if (operator === 'endsWith') return {ilike: `%${value}`};
    if (operator === 'is') return {eq: value};
    if (operator === 'not') return {neq: value};
    if (operator === 'isAnyOf') return {inq: value};
  };

  getOrderQuery = () => {
    return this.state.sortModel.map((sort, idx) => `${idx ? ', ' : ''}${sort.field} ${sort.sort}`).join();
  };

  getFilterQuery = () => {
    const filterModel = this.state.filterModel;
    if (!filterModel.items || !filterModel.items.length || filterModel.items.every(fModel => !fModel.value)) return;
    return {
      [filterModel.logicOperator]: [
        ...filterModel.items.filter(fModel => fModel.value).map(fModel => ({
          [fModel.field]: this.mapQueryOperator(fModel.operator, fModel.value),
        })),
      ],
    };
  };

  changePage = async ({page, pageSize}) => {
    this.setState({
      loading: true,
    });

    const {
      page: bundles,
      pagination: {
        totalItemCount,
        currentPage,
        itemsPerPage,
      },
    } = await getBundles(page + 1, pageSize, this.getOrderQuery(), undefined, this.getFilterQuery());

    this.setState({
      loading: false,
      bundles,
      totalItemCount,
      currentPage,
      itemsPerPage,
    });
  };

  refreshPage = async () => {
    this.setState({
      loading: true,
    });

    const {
      page: bundles,
      pagination: {
        totalItemCount,
        currentPage,
        itemsPerPage,
      },
    } = await getBundles(this.state.currentPage, this.state.itemsPerPage, this.getOrderQuery(), undefined, this.getFilterQuery());

    this.setState({
      loading: false,
      bundles,
      totalItemCount,
      currentPage,
      itemsPerPage,
    });
  };

  handleSort = async (gridSortModel) => {
    await this.setState({
      sortModel: gridSortModel,
    }, this.refreshPage);
  };
  onFilterChange = async (gridFilterModel) => {
    if (this.onFilterChangeTimeout) clearTimeout(this.onFilterChangeTimeout);

    this.onFilterChangeTimeout = setTimeout(async () => {
      await this.setState({
        filterModel: gridFilterModel,
      }, this.refreshPage);
    }, 2000);
  };

  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Paper sx={{height: 'calc(100vh - 160px)'}}>
            <DataGridPro
              columns={this.columns}
              rows={this.state.bundles}
              pagination
              initialState={{
                pagination: {paginationModel: {pageSize: this.state.itemsPerPage}},
                filter: {
                  filterModel: this.state.filterModel,
                },
                density: 'compact',
              }}
              columnVisibilityModel={{
                id: false,
                crmId: false,
                kumberasId: false,
              }}
              paginationMode={'server'}
              pageSizeOptions={[10, 25, 50, 100]}
              onPaginationModelChange={this.changePage}
              rowCount={this.state.totalItemCount}
              loading={this.state.loading}
              sortingMode={'server'}
              onSortModelChange={this.handleSort}
              sortModel={this.state.sortModel}
              slots={{
                toolbar: GridToolbar,
              }}
              filterMode={'server'}
              onFilterModelChange={this.onFilterChange}
              sx={{
                '.MuiTablePagination-displayedRows': {
                  marginTop: '1em',
                  marginBottom: '1em',
                },
                '.MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
                  marginTop: '1em',
                  marginBottom: '1em',
                },
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default BundlesTable;
