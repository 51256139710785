
import React, {Component} from 'react';
import BasePage from 'pages/BasePage';
import BundlesTable from 'components/BundlesTable/BundlesTable';

class BundlesPage extends Component {

  componentDidMount() {
    document.title = 'KMS Kontrol - Bundles';
  }

  render() {
    return (
      <BasePage title={'Bundles'} requiredRole={'kontrol_bundles'} {...this.props}>
        <BundlesTable {...this.props} />
      </BasePage>
    );
  }
}

export default BundlesPage;
