import React, {Component} from 'react';
import {Button, Card, CardBody, CardFooter, CardHeader, Col, FormInput, Row} from 'shards-react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import {createCustomerProcessCosts, deleteCustomerProcessCosts, getCustomers, getCustomerProcessCosts, patchCustomerProcessCosts} from 'services/kooperKontrol';
import {getProcesses} from 'services/knestKontrol';
import './CustomerHourlyCostsTable.css';
import {Delete} from '@mui/icons-material';
import {IconButton} from '@mui/material';

const _ = require('lodash');

export class CustomerHourlyCostsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: {},
      header: ['process', 'setup_rate', 'process_rate', 'unit', 'delete'],
      companyCosts: [],
      selectedCompany: null,
      processes: [],
      processesForSelect: [],
      selectedProcess: null,
    };
  }

  componentDidMount() {
    getProcesses()
      .then(res => this.setState({processes: res}));
  }

  updateCompanyCosts = () => {
    const companyId = this.state.selectedCompany.value;
    getCustomerProcessCosts(companyId)
      .then((costs) => {
        // Insert Unit from processes into costs
        costs.forEach(costItem => costItem.unit = this.state.processes.find(item => item.key === costItem.processKey).unit);

        this.setState({companyCosts: costs});

        //filter default costs arr for processes that are not selected
        const filteredProcesses = this.state.processes.filter(process =>
          !costs.some(item => item.processKey === process.key),
        );
        this.setState({processesForSelect: filteredProcesses});
      })
      .catch(error => {
        this.setState({error});
      });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.selectedCompany !== prevState.selectedCompany && this.state.selectedCompany) {
      this.updateCompanyCosts();
    }
  }

  getCompanies = async (inputValue) => {
    try {
      const companies = await getCustomers(inputValue);
      const companyNames = companies.map(company => {
        return {label: company.companyName, value: company.id};
      });
      return companyNames;
    } catch (err) {
      this.setState({error: err.response.status});
    }

  };

  handleNewRow = async () => {
    const processKey = this.state.selectedProcess.value;
    const defaultValues = this.state.processes.find(process => process.key === processKey);
    const companyId = this.state.selectedCompany.value;
    const newItemData = {
      companyId,
      processKey: processKey,
      setupRate: defaultValues.defaultSetupRate,
      processRate: defaultValues.defaultProcessRate,
    };

    const processesForSelectCopy = JSON.parse(JSON.stringify(this.state.processesForSelect));
    _.remove(processesForSelectCopy, (item) => {
      return item.key === processKey;
    });
    await createCustomerProcessCosts(newItemData);
    this.updateCompanyCosts();
    this.setState({selectedProcess: null});

  };

  handleDelete = (id) => {
    deleteCustomerProcessCosts(id)
      .then(() => {
        this.updateCompanyCosts();
      });
  };

  handleCostsChange = async (type, value, id) => {
    const data = {
      id: id,
      setupRate: type === 'setupRate' ? value : undefined,
      processRate: type === 'processRate' ? value : undefined,
    };
    const companyCostsCopy = JSON.parse(JSON.stringify(this.state.companyCosts));
    const currentCosts = companyCostsCopy.find(item => item.id === id);
    type === 'setupRate' && (currentCosts.setupRate = value);
    type === 'processRate' && (currentCosts.processRate = value);

    this.setState({companyCosts: companyCostsCopy});
    await patchCustomerProcessCosts(data);
  };

  mapTableHeader = () => {
    return this.state.header.map((item) => {
      return (
        <th scope="col" className="border-0">
          {item}
        </th>
      );
    });
  };

  mapTableRows = () => {
    return this.state.companyCosts.map((item) => {
      return (
        <tr>
          <td>{item.processKey}</td>
          <td>
            <FormInput
              className="cost-form-input"
              type={'number'}
              value={item.setupRate}
              onChange={(e) => this.handleCostsChange('setupRate', e.target.value, item.id)}
            />
          </td>
          <td>
            <FormInput
              className="cost-form-input"
              type={'number'}
              value={item.processRate}
              onChange={(e) => this.handleCostsChange('processRate', e.target.value, item.id)}
            />
          </td>
          <td>{item.unit}</td>
          <td>
            <IconButton onClick={() => this.handleDelete(item.id)}>
              <Delete />
            </IconButton>
          </td>
        </tr>
      );
    });
  };

  render() {
    return (
      <div>
        <Row>
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <Row>
                  <div className="async-search-wrapper">
                    <AsyncSelect
                      placeholder={'Select a Company...'}
                      loadOptions={this.getCompanies}
                      onChange={(e) => {
                        this.setState({selectedCompany: e});
                      }}
                      value={this.state.selectedCompany}
                    />
                  </div>
                </Row>
              </CardHeader>
              {this.state.selectedCompany &&
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                    {this.mapTableHeader()}
                    </thead>
                    <tbody>
                    {this.mapTableRows()}
                    <tr>
                      <td colSpan={2}>
                        <Select
                          placeholder={'Select a Process...'}
                          options={this.state.processesForSelect.map(process => ({
                            label: process.key,
                            value: process.key,
                          }))}
                          onChange={(e) => this.setState({selectedProcess: e})}
                          value={this.state.selectedProcess}

                        />
                      </td>
                      <td>
                        <Button
                          theme="success" pill onClick={this.handleNewRow}
                          disabled={!this.state.selectedProcess}
                        >+</Button>
                      </td>
                      <td />
                      <td />
                      <td />
                    </tr>

                    </tbody>
                  </table>
                </CardBody>
              }
              <CardFooter />


            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CustomerHourlyCostsTable;
