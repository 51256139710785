import React, {Component} from 'react';
import {Alert, Box, CircularProgress, Grid, Paper, Snackbar} from '@mui/material';
import {DataGridPro, GridToolbar} from '@mui/x-data-grid-pro';
import {getCompanies, getCompanyFeatures, patchCompany} from '../../services/knestKontrol';

class CompaniesTable extends Component {
  constructor(props) {
    super(props);

    this.dataTypeMapping = {
      bool: 'boolean',
      datetime: 'dateTime',
      double: 'number',
      int: 'number',
      select: 'singleSelect',
      string: 'string',
      text: 'string',
    };

    this.defaultSnackbarState = {
      snackbarOpen: false,
      snackbarSeverity: 'success',
      snackbarMessage: '',
      snackbarAutoHideDuration: 4000,
      snackbarAction: undefined,
      snackbarIcon: undefined,
    };

    this.state = {
      loading: true,
      columns: [],
      companies: [],
      companyFeatures: [],

      ...this.defaultSnackbarState,
    };
  }

  async componentDidMount() {
    const companies = await getCompanies({
      filter: {include: {companyCompanyFeatures: true}, orderBy: {id: 'asc'}},
    });

    const companyFeatures = await getCompanyFeatures({
      filter: {orderBy: {dataTypeKey: 'asc'}},
    });

    const columns = [
      {field: 'assetId', headerName: 'Asset ID', filterable: false},
      {field: 'companyName', headerName: 'Company Name', minWidth: 350, filterable: true, editable: true},
      {field: 'orderConfirmationEmail', headerName: 'Order Confirmation Email', minWidth: 200, filterable: true, editable: true, type: 'email'},
      {field: 'invoiceEmail', headerName: 'Invoice Email', minWidth: 200, filterable: true, editable: true, type: 'email'},
      ...companyFeatures.map(feature => ({
        field: feature.key,
        headerName: feature.key,
        width: feature.key.length * 10,
        editable: true,
        type: this.dataTypeMapping[feature.dataTypeKey],
        valueGetter: (_value, row, column) => {
          const value = row.companyCompanyFeatures.find(cf => cf.companyFeatureKey === column.field)?.value;
          return feature.dataTypeKey === 'bool' ? (value ? value === 'true' : undefined) : value;
        },
        valueSetter: (value, row) => {
          let companyCompanyFeatures = row.companyCompanyFeatures.reduce((acc, cf) => {
            acc.push({
              ...cf,
              value: cf.companyFeatureKey === feature.key ? value?.toString().trim() || null : cf.value,
            });

            return acc;
          }, []);

          if (!companyCompanyFeatures.find(cf => cf.companyFeatureKey === feature.key) && value?.toString()) {
            companyCompanyFeatures.push({
              companyFeatureKey: feature.key,
              value: value.toString().trim() || null,
            });
          }

          return {
            ...row,
            companyCompanyFeatures,
          };
        },
      })),
    ];

    await this.setState({
      companies,
      columns,
      companyFeatures,
      loading: false,
    });
  }

  handleCellEditCommit = async (newRow, oldRow) => {
    try {
      await this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'info',
        snackbarAutoHideDuration: null,
        snackbarMessage: <Grid container spacing={3}>
          <Grid item>Saving changes...</Grid>
          <Grid item><CircularProgress size={20} /></Grid>
        </Grid>,
        snackbarAction: <></>,
        snackbarIcon: false,
      });

      const changedCompanyFeatures = newRow.companyCompanyFeatures.filter(cf => cf.value !== oldRow.companyCompanyFeatures.find(cf2 => cf2.companyFeatureKey === cf.companyFeatureKey)?.value);

      await patchCompany(newRow.id, {
        companyName: newRow.companyName?.trim(),
        invoiceEmail: newRow.invoiceEmail?.trim() || null,
        orderConfirmationEmail: newRow.orderConfirmationEmail?.trim() || null,
        companyCompanyFeatures: changedCompanyFeatures.length ? {
          upsert: changedCompanyFeatures.filter(cf => cf.value !== null).map(cf => ({
            where: {companyId_companyFeatureKey: {companyId: newRow.id, companyFeatureKey: cf.companyFeatureKey}},
            create: {
              companyFeatureKey: cf.companyFeatureKey,
              value: cf.value,
            },
            update: {
              value: cf.value,
            },
          })),
          deleteMany: {
            companyFeatureKey: {in: changedCompanyFeatures.filter(cf => cf.value === null).map(cf => cf.companyFeatureKey)},
          },
        } : undefined,
      });

      await this.setState({
        ...this.defaultSnackbarState,
        snackbarOpen: true,
        snackbarSeverity: 'success',
        snackbarMessage: 'Company successfully updated!',
      });

      return newRow;
    } catch (e) {
      console.error(e);

      await this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'An error occurred while updated the company feature. Try again later, or create a defect ticket if the issue persists.',
      });

      return oldRow;
    }
  };

  render() {
    return <Paper sx={{height: '100%'}}>
      <Box sx={{width: '100%', height: '100%'}}>
        <DataGridPro
          style={{height: '100%'}}
          columns={this.state.columns}
          rows={this.state.companies}
          density={'compact'}
          processRowUpdate={this.handleCellEditCommit}
          editMode={'row'}
          disableColumnSelector
          disableDensitySelector
          loading={this.state.loading}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: {disableToolbarButton: true},
              csvOptions: {disableToolbarButton: true},
            },
          }}
          pinnedColumns={{left: ['assetId', 'companyName']}}
        />
        <Snackbar
          open={this.state.snackbarOpen}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
          autoHideDuration={this.state.snackbarAutoHideDuration}
          onClose={() => this.setState({snackbarOpen: false})}
        >
          <Alert
            onClose={() => this.setState({snackbarOpen: false})}
            severity={this.state.snackbarSeverity}
            sx={{width: '100%'}}
            action={this.state.snackbarAction}
            icon={this.state.snackbarIcon}
          >
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Paper>;
  }
}

export default CompaniesTable;
