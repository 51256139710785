import {GridFilterInputValue, GridFilterModel, GridFilterOperator, GridLogicOperator, GridSortModel} from '@mui/x-data-grid-pro';

export const formatCurrency = (value: string | number) => {
  if (!value) {
    return null;
  }

  if (typeof value !== 'number') {
    value = parseFloat(value);
  }

  return new Intl.NumberFormat(navigator.languages || 'de-DE', {style: 'currency', currency: 'EUR'}).format(value);
};

/**
 * @description Convert ISO timestamp into app defined display format for the user
 * @example 2019-08-21T06:51:22+00:00 to 21st Aug 2019
 * @param {*} timestampDate @type string
 */
export const toDisplayDate = (timestampDate: Date | string | null) => {
  if (!timestampDate) return '';
  
  return new Intl.DateTimeFormat(navigator.languages || 'de-DE', {year: 'numeric', month: 'short', day: 'numeric'}).format(new Date(timestampDate));
};

/**
 * @description Convert ISO timestamp into app defined display format for the user
 * @example 2019-08-21T06:51:22+00:00 to 21st Aug 2019 06:40am
 * @param {*} timestampDate @type string
 */
export const toDisplayDatetime = (timestampDate: Date | string): string => {
  return new Intl.DateTimeFormat(navigator.languages || 'de-DE', {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'}).format(new Date(timestampDate));
};

export const mapQueryOperator = (operator: string, value: any, type: string) => {
  switch (operator) {
    case 'equals':
    case 'is':
    case '=':
      if (type === 'boolean') return value === 'true';
      return {equals: operator === '=' ? parseFloat(value) : value};
    case 'contains':
      return {contains: value, mode: 'insensitive'};
    case 'startsWith':
      return {startsWith: value, mode: 'insensitive'};
    case 'endsWith':
      return {endsWith: value, mode: 'insensitive'};
    case 'not':
    case '!=':
    case 'doesNotEqual':
      return {not: operator === '!=' ? parseFloat(value) : value};
    case 'after':
    case '>':
      return {gt: operator === '>' ? parseFloat(value) : value};
    case 'before':
    case '<':
      return {lt: operator === '<' ? parseFloat(value) : value};
    case 'onOrAfter':
    case '>=':
      return {gte: operator === '>=' ? parseFloat(value) : value};
    case 'onOrBefore':
    case '<=':
      return {lte: operator === '<=' ? parseFloat(value) : value};
    case 'isAnyOf':
      return {in: value};
    case 'isEmpty':
      return {equals: null};
    case 'isNotEmpty':
      return {not: null};
    case 'doesNotContain':
      return {contains: value, mode: 'insensitive'};
    default:
      return;
  }
};

export const generateWhereFromFilterModel = (filterModel: GridFilterModel, fieldTypes: { [key: string]: string }) => {
  const logicOperator = filterModel.logicOperator === GridLogicOperator.Or ? 'OR' : 'AND';

  const where: { [key in 'AND' | 'OR']?: any } = {
    [logicOperator]: [],
  };

  filterModel.items.forEach(({field, operator, value}) => {
    // Skip if the value is empty and the operator is not `isEmpty` or `isNotEmpty`
    if (!['isEmpty', 'isNotEmpty'].includes(operator) && !value) return;

    let finalObject = {};

    field.split('.').reduce((acc, key, currentIndex, array) => {
      acc[key] = currentIndex === array.length - 1
        ? mapQueryOperator(operator, value, fieldTypes[field])
        : acc[key] || {};

      return acc[key];
    }, finalObject);

    if (['doesNotContain'].includes(operator)) {
      finalObject = {
        NOT: finalObject,
      };
    }

    where[logicOperator].push(finalObject);
  });

  return where;
};

export const generateOrderByFromSortModel = (sortModel: GridSortModel) => {
  return sortModel.map(({field, sort}) => {
    let finalObject = {};

    field.split('.').reduce((acc, key, currentIndex, array) => {
      acc[key] = currentIndex === array.length - 1 ? sort : acc[key] || {};
      return acc[key];
    }, finalObject);

    return finalObject;
  });
};

export const doesNotContainOperator: GridFilterOperator<any, string> = {
  label: 'does not contain',
  headerLabel: 'Does not contain',
  value: 'doesNotContain',
  getApplyFilterFn: (filterItem) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    return (value) => {
      return !value.toLowerCase().includes(filterItem.value.toLowerCase());
    };
  },
  requiresFilterValue: true,
  InputComponent: GridFilterInputValue,
};

export const doesNotEqualOperator: GridFilterOperator<any, string> = {
  label: 'does not equal',
  headerLabel: 'Does not equal',
  value: 'doesNotEqual',
  getApplyFilterFn: (filterItem) => {
    if (!filterItem.field || !filterItem.value || !filterItem.operator) {
      return null;
    }

    return (value) => {
      return value.toLowerCase() !== filterItem.value.toLowerCase();
    };
  },
  requiresFilterValue: true,
  InputComponent: GridFilterInputValue,
};
