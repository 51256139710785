import React, { Component } from 'react';
import CustomerHourlyCostsTable from 'components/CustomerHourlyCostsTable/CustomerHourlyCostsTable'
import BasePage from 'pages/BasePage'

class CustomerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  componentDidMount() {
    document.title = 'KMS Kontrol - Customers';
  }

  render() {
    return (
      <BasePage title={"Customer"} subtitle={"Customer Specific Pricing"} requiredRole={'kontrol_pricing'} {...this.props}>
        <CustomerHourlyCostsTable {...this.props} />
      </BasePage>
    )
  }
}

export default CustomerPage
