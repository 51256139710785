import Cookies from 'universal-cookie/lib';
import {decode, encode} from 'jwt-simple';
import {getUserInfo as fetchUserInfo} from '../services/kooperAuth';
import Axios from 'axios';

const cookies = new Cookies();

const kauthCookieName = `${process.env.REACT_APP_ENVIRONMENT}_kauth`;

export const getUserInfo = () => {
  try {
    return fetchUserInfo();
  } catch (e) {
    console.error(e);
  }
};

export const getToken = (returnObject = false) => {
  const authCookie = cookies.get(kauthCookieName);
  if (!authCookie) {
    return false;
  }
  const authObject = decode(authCookie, 'secret');
  return returnObject ? authObject : `Bearer ${authObject.access_token}`;
};

export const getUserRoles = (user) => {
  if (!user) {
    return [];
  }
  return user.roles.map(role => role.name);
};

export const logout = () => {
  window.location = `${process.env.REACT_APP_KAUTH_URL}/logout?goto=${window.location.href}`;
};

const loginAndRedirect = () => {
  window.location = `${process.env.REACT_APP_KAUTH_URL}/login?goto=${window.location.href}`;
};

export async function handleUnauthorized() {
  const token = getToken(true);

  if (!token?.refresh_token) {
    loginAndRedirect();
  }

  try {
    const {data} = await Axios.post(`${process.env.REACT_APP_KOOPER_AUTH_URL}/oauth/token`, {
      client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
      client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
      refresh_token: token.refresh_token,
      grant_type: 'refresh_token',
    });

    const encodedToken = encode(data, 'secret');
    const domain = process.env.REACT_APP_ENVIRONMENT === 'local' ? 'localhost' : '.kreatize.com';
    cookies.set(kauthCookieName, encodedToken, {domain, maxAge: 604800, path: '/'});
  } catch (error) {
    loginAndRedirect();
  }
}
