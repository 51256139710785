import React, {Component}            from 'react';
import BasePage                      from 'pages/BasePage';
import SupplierGroupHourlyCostsTable from 'components/SupplierGroupHourlyCostsTable/SupplierGroupHourlyCostsTable';


class SupplierGroupHourlyCostsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    document.title = 'KMS Kontrol - Supplier Groups';
  }

  render() {
    return (
      <BasePage title={'Hourly Process Costs'} subtitle={'Specific supplier group pricing'} requiredRole={'kontrol_pricing'} {...this.props}>
        <SupplierGroupHourlyCostsTable  {...this.props}/>
      </BasePage>
    );
  }
}

export default SupplierGroupHourlyCostsPage;
