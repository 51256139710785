import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import {getToken, handleUnauthorized} from 'utils/userHelpers';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

require('dotenv').config('.env');

const requestInterceptor = (request: AxiosRequestConfig) => {
  request.headers = request.headers || {};
  request.headers.Authorization = getToken();
  return request;
};

const responseInterceptor = (response: AxiosResponse) => {
  if (response.config.responseType === 'arraybuffer') return response;

  return response.data;
};

const axiosInstances: { [key: string]: AxiosInstance } = ['kontrol', 'auth', 'knest-kontrol'].reduce((acc, host) => {

  const isKnestHost = host.split('-')[0] === 'knest';
  const knestHostName = host.split('-')[1];

  const axiosInstance = axios.create({
    timeout: 100000,
    baseURL: isKnestHost ?
      `${process.env[`REACT_APP_KNEST_${knestHostName.toUpperCase()}_URL`]}` :
      `${process.env[`REACT_APP_KOOPER_${host.toUpperCase()}_URL`]}/${host === 'auth' ? 'auth' : 'api'}`,
  });

  axiosInstance.interceptors.request.use(requestInterceptor);
  axiosInstance.interceptors.response.use(responseInterceptor);

  createAuthRefreshInterceptor(axiosInstance, handleUnauthorized, {
    statusCodes: [401, 403],
    skipWhileRefreshing: false,
  });

  acc[`${isKnestHost ? `knest${knestHostName[0].toUpperCase() + knestHostName.substring(1)}` : host}Instance`] = axiosInstance;

  return acc;
}, {});

const kontrolInstance = axiosInstances.kontrolInstance;
const authInstance = axiosInstances.authInstance;
const knestKontrolInstance = axiosInstances.knestKontrolInstance;

export {kontrolInstance, authInstance, knestKontrolInstance};
