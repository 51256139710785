import React from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import routes from 'routes.tsx';
import ReactTooltip from 'react-tooltip';
import {Container} from '@mui/material';
import LandingPage from '../../pages/LandingPage/LandingPage';
import ErrorPage from '../../components/ErrorPage/ErrorPage';

export const MainContainer = (props) => {
  const hist = useHistory();

  const getRoutes = () => {
    return routes.map((route) => {
      if (route.items) {
        return route.items.map(subroute => <Route path={route.path + subroute.path} render={() => (<subroute.component {...props} history={hist} />)} />);
      }
      return <Route key={route.key} exact path={route.path} render={() => (<route.component {...props} history={hist} />)} />;
    });
  };

  return (
    <Container maxWidth={false} disableGutters sx={{width: 'calc(100vw - 80px)', marginLeft: '80px'}}>
      <Switch>
        <Route path="/" exact component={props => <LandingPage {...props} />} />
        {getRoutes(routes)}
        <Route component={() => <ErrorPage statusCode={'404'} />} />
      </Switch>
      <ReactTooltip effect="solid" type="error" />
    </Container>
  );
};
export default MainContainer;
