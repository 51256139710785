import React, {Component} from 'react';
import MaterialsTable from '../../components/MaterialsTable/MaterialsTable';
import BasePage from 'pages/BasePage';

class MaterialsPage extends Component {
  componentDidMount() {
    document.title = 'KMS Kontrol - Materials';
  }

  render() {
    return (
      <BasePage
        title={'Materials'}
        subtitle={'Referential Data'}
        requiredRole={'kontrol_materials'}
        {...this.props}
      >
        <MaterialsTable />
      </BasePage>
    );
  }
}

export default MaterialsPage;
