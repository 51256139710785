import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import {AppBar, Hidden, IconButton, Toolbar} from '@mui/material';
import logo from 'assets/img/logo.png';
import {Menu} from '@mui/icons-material';
import UserMenu from 'components/UserMenu/UserMenu.js';
import './TopBar.scss';

class TopBar extends Component {
  render() {
    const {user} = this.props;
    return (
      <AppBar color="default" position="fixed">
        <Toolbar>
          <Hidden mdUp>
            <IconButton onClick={() => this.props.handleSidebarToggle(!this.props.sidebarToggled)}>
              <Menu />
            </IconButton>
          </Hidden>

          <NavLink to={'/'}>
            <img className={'top-bar-logo'} src={logo} alt="Kreatize" />
          </NavLink>


          <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%', alignItems: 'center'}}>

            <UserMenu
              user={user}
              logout={() => {
                window.location = `${process.env.REACT_APP_KAUTH_URL}/logout?goto=${window.location.href}`;
              }}
            />
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default TopBar;
