import React, {Component} from 'react';
import {getProcessCategories, getProcesses, putProcess} from 'services/knestKontrol';
import EcoIcon from '@mui/icons-material/LocalFlorist';
import {DataGridPro, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, useGridApiRef} from '@mui/x-data-grid-pro';
import {Paper, Grid, Box, IconButton, Button, Snackbar, Alert, Tooltip} from '@mui/material';
import {Edit, Add} from '@mui/icons-material';
import {v4 as uuidv4} from 'uuid';
import EditProcessModal from '../Modals/EditProcessModal/EditProcessModal';

export class ProcessesTable extends Component {
  constructor(props) {
    super(props);

    this.defaultNewProcess = {
      key: '',
      class: 'process',
      isStartingProcess: true,
      unitKey: 'euro_per_hour',
      defaultSetupRate: 50,
      defaultProcessRate: 50,
      ecoFriendliness: null,
      defaultSupplierSetupCosts: 41,
      defaultSupplierProcessCosts: 41,
      minPrice: null,
      defaultSupplierMinCosts: null,
      category: null,
      isVital: false,
      isOffered: true,
      increasesLeadTime: true,
      showOnDrawing: false,
      showOnOrderConfirmation: false,
    };

    this.sortModel = [{field: 'key', sort: 'asc'}];

    this.processClasses = [
      {value: 'process', label: 'Process'},
      {value: 'post_process', label: 'Post Process'},
      {value: 'production_requirement', label: 'Production Requirement'},
    ];

    this.unitKeys = [
      {value: 'euro_per_hour', label: '€/hr'},
      {value: 'euro_per_position', label: '€/pc'},
    ];

    this.ecoFriendlinessOptions = [
      {value: 0, label: <EcoIcon style={{display: 'inline', color: '#ff0000'}} />},
      {value: 1, label: <EcoIcon style={{display: 'inline', color: '#ebeb00'}} />},
      {value: 2, label: <EcoIcon style={{display: 'inline', color: '#0ac44b'}} />},
      {
        value: 3, label: <>
          <EcoIcon style={{display: 'inline', color: '#0ac44b'}} />
          <EcoIcon style={{display: 'inline', color: '#0ac44b'}} />
        </>,
      },
      {
        value: 4, label: <>
          <EcoIcon style={{display: 'inline', color: '#0ac44b'}} />
          <EcoIcon style={{display: 'inline', color: '#0ac44b'}} />
          <EcoIcon style={{display: 'inline', color: '#0ac44b'}} />
        </>,
      },
      {value: null, label: 'NULL'},
    ];

    this.state = {
      loading: true,
      columns: [],
      processes: [],
      newProcess: {
        ...this.defaultNewProcess,
      },
      snackbarOpen: false,
      snackbarSeverity: 'success',
      snackbarMessage: '',
      selectedProcess: null,
      editProcessModalOpen: false,
    };
  }

  editProcessFeatures = async (selectedProcess) => {
    await this.setState({
      selectedProcess,
    });

    this.setState({
      editProcessModalOpen: true,
    });
  };

  fetchAndRefreshData = async () => {
    const processes = await getProcesses({
      filter: {
        orderBy: {key: 'asc'},
        include: {
          partsProcesses: true,
        },
      },
    });

    const processCategories = [...(await getProcessCategories()).map(pc => ({value: pc.key, label: pc.key})), {value: null, label: 'NULL'}];

    // Add a unique UUID to each process instance
    processes.map(p => {
      p.uuid = uuidv4();
      p.enabledOnKms = p.partsProcesses.length;
      return p;
    });

    await this.setState({
      loading: false,
      processes,
      columns: [
        {field: 'key', minWidth: 200, headerName: 'Key', filterable: true, editable: true, sortable: false},
        {
          field: 'class',
          minWidth: 170,
          headerName: 'Class',
          type: 'singleSelect',
          valueOptions: this.processClasses,
          filterable: true,
          editable: true,
          sortable: false,
          renderCell: (params) => this.processClasses.find(c => c.value === params.value).label,
        },
        {field: 'isStartingProcess', minWidth: 150, headerName: 'Starting Process?', type: 'boolean', editable: true, sortable: false},
        {field: 'defaultSetupRate', minWidth: 150, headerName: 'Customer Setup', type: 'number', editable: true, sortable: false},
        {field: 'defaultProcessRate', minWidth: 150, headerName: 'Customer Process', type: 'number', editable: true, sortable: false},
        {field: 'defaultSupplierSetupCosts', minWidth: 150, headerName: 'Supplier Setup', type: 'number', editable: true, sortable: false},
        {field: 'defaultSupplierProcessCosts', minWidth: 150, headerName: 'Supplier Process', type: 'number', editable: true, sortable: false},
        {
          field: 'unitKey',
          width: 70,
          headerName: 'Unit',
          type: 'singleSelect',
          valueOptions: this.unitKeys,
          renderCell: (params) => this.unitKeys.find(uk => uk.value === params.value).label,
          editable: true,
          sortable: false,
        },
        {field: 'minPrice', minWidth: 150, headerName: 'Customer Min Price', type: 'number', editable: true, sortable: false},
        {field: 'defaultSupplierMinCosts', minWidth: 150, headerName: 'Supplier Min Costs', type: 'number', editable: true, sortable: false},
        {
          field: 'ecoFriendliness',
          minWidth: 130,
          headerName: 'Eco Friendliness',
          type: 'singleSelect',
          valueOptions: this.ecoFriendlinessOptions,
          editable: true,
          renderCell: (params) => params.value === null ? '' : this.ecoFriendlinessOptions.find(ef => ef.value === params.value)?.label,
          sortable: false,
        },
        {
          field: 'category',
          minWidth: 100,
          headerName: 'Category',
          type: 'singleSelect',
          valueOptions: processCategories,
          renderCell: (params) => params.value === null ? '' : processCategories.find(c => c.value === params.value).label,
          editable: true,
          sortable: false,
        },
        {field: 'isVital', minWidth: 100, headerName: 'Is Vital', type: 'boolean', editable: true, sortable: false},
        {field: 'isOffered', minWidth: 100, headerName: 'Is Offered', type: 'boolean', editable: true, sortable: false},
        {field: 'increasesLeadTime', minWidth: 180, headerName: 'Increases Lead Time', type: 'boolean', editable: true, sortable: false},
        {field: 'showOnDrawing', minWidth: 150, headerName: 'Show on Drawing', type: 'boolean', editable: true, sortable: false},
        {field: 'showOnOrderConfirmation', minWidth: 150, headerName: 'Show on AB', type: 'boolean', editable: true, sortable: false},
        {
          field: 'enabledOnKms',
          minWidth: 100,
          headerName: 'Enabled on KMS/Kumberas',
          type: 'boolean',
          editable: true,
          sortable: false,
        },
        {
          field: 'actions',
          headerName: '',
          width: 30,
          editable: false,
          sortable: false,
          align: 'center',
          type: 'actions',
          renderCell: params => {
            return (
              <Tooltip title={'Edit Related Process Features'}>
                <IconButton onClick={() => this.editProcessFeatures(params.row)} size={'small'}><Edit /></IconButton>
              </Tooltip>
            );
          },
        },
      ],
    });
  };

  async componentDidMount() {
    await this.fetchAndRefreshData();
  }

  handleRowEditStop = async (params, event) => {
    if (params.reason === 'rowFocusOut') event.defaultMuiPrevented = true;
  };

  handleRowUpdate = async (newRow, previousRow) => {
    if (!newRow.key.length) return previousRow;

    const updatedProcess = await putProcess(newRow.key, {
      ...newRow,
      ...newRow.enabledOnKms !== previousRow.enabledOnKms ? {
        partsProcesses: {
          ...newRow.enabledOnKms ? {
            create: {},
          } : {
            deleteMany: {
              id: newRow.id,
            },
          },
        },
      } : {partsProcesses: undefined},
      enabledOnKms: undefined,
      uuid: undefined,
      partsProcessProcessFeatureViews: undefined,
      processProcessFeatures: undefined,
    });

    await this.setState({
      processes: this.state.processes.map(process => process.uuid === newRow.uuid ? {...newRow, ...updatedProcess} : process),
      snackbarOpen: true,
      snackbarMessage: 'Process successfully saved.',
      snackbarSeverity: 'success',
    });

    return newRow;
  };

  handleRowUpdateFailure = async (error) => {
    console.error(error);
    this.setState({
      snackbarOpen: true,
      snackbarMessage: 'An error occurred while saving the process. Please try again later.',
      snackbarSeverity: 'error',
    });
  };

  CustomToolbar = () => {
    const addNewProcessRow = async () => {
      const currentGrid = this.props.gridApiRef.current;

      const existingRowInEditMode = this.state.processes.find(p => currentGrid.getRowMode(p.uuid) === 'edit');

      if (existingRowInEditMode) {
        return this.setState({
          snackbarOpen: true,
          snackbarMessage: 'You cannot add a new process while another process is being updated/created.',
          snackbarSeverity: 'warning',
        });
      }

      const uuid = uuidv4();
      this.setState({
        processes: [{...this.defaultNewProcess, uuid}, ...this.state.processes],
      }, () => currentGrid.startRowEditMode({id: uuid, fieldToFocus: 'key'}));
    };

    return (
      <GridToolbarContainer>
        <Button startIcon={<Add />} onClick={() => addNewProcessRow()} size={'small'}>Add Process</Button>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  render() {
    return (
      <>
        <Grid container spacing={2} sx={{height: 'calc(100vh - 200px)'}}>
          <Grid item xs={12} sx={{height: '100%'}}>
            <Paper sx={{height: '100%'}}>
              <Box sx={{width: '100%', height: '100%'}}>
                {!this.state.loading && <DataGridPro
                  style={{height: '100%'}}
                  columns={this.state.columns}
                  rows={this.state.processes}
                  loading={this.state.loading}
                  initialState={{
                    density: 'compact',
                  }}
                  disableColumnSelector
                  pagination={false}
                  pinnedColumns={{left: ['key'], right: ['actions']}}
                  processRowUpdate={this.handleRowUpdate}
                  onRowEditStop={this.handleRowEditStop}
                  onProcessRowUpdateError={this.handleRowUpdateFailure}
                  editMode={'row'}
                  getRowId={(row) => row.uuid}
                  slots={{
                    toolbar: this.CustomToolbar,
                  }}
                  apiRef={this.props.gridApiRef}
                  hideFooter
                />}
                <Snackbar
                  open={this.state.snackbarOpen}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                  autoHideDuration={4000}
                  onClose={() => this.setState({snackbarOpen: false})}
                >
                  <Alert onClose={() => this.setState({snackbarOpen: false})} severity={this.state.snackbarSeverity} sx={{width: '100%'}}>
                    {this.state.snackbarMessage}
                  </Alert>
                </Snackbar>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        {this.state.editProcessModalOpen && <EditProcessModal
          open={this.state.editProcessModalOpen}
          process={this.state.selectedProcess}
          confirm={this.fetchAndRefreshData}
          setOpen={open => this.setState({editProcessModalOpen: open})}
        />}
      </>
    );
  }
}

const withGridApiRef = Component => props => {
  const gridApiRef = useGridApiRef();
  return <Component gridApiRef={gridApiRef} {...props} />;
};

export default withGridApiRef(ProcessesTable);
