import React, {Component} from 'react';
import BasePage from '../BasePage';
import TransactionDocumentsTable from '../../components/TransactionDocumentsTable/TransactionDocumentsTable';

class TransactionDocumentsPage extends Component {
  componentDidMount() {
    document.title = 'KMS Kontrol - Transaction Documents';
  }

  render() {
    return (
      <BasePage title={'Transaction Documents'} requiredRole={'kontrol_invoicing'} {...this.props}>
        <TransactionDocumentsTable />
      </BasePage>
    );
  }
}

export default TransactionDocumentsPage;
