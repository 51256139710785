import React, {Component} from 'react';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Fade,
  FormControl,
  FormGroup,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Select,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import {Check, Close, Download, MoreVert} from '@mui/icons-material';
import {Prisma, ShipmentBundleOrderedPartConfig} from '@prisma/client';
import './EditOrderModal.scss';
import {DatePicker} from '@mui/x-date-pickers-pro';
import moment from 'moment';
import {DataGridPro, GRID_CHECKBOX_SELECTION_FIELD, GridActionsCellItem, GridRowSelectionModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton} from '@mui/x-data-grid-pro';
import {formatCurrency} from '../../../utils/formatHelpers';
import {createInvoice, getOrders, updateOrder} from '../../../services/knestKontrol';
import {KaktusStatuses, OrderStatuses, Status} from '../../../types/ReferentialData';
import CreateShipmentModal from '../CreateShipmentModal/CreateShipmentModal';
import {ShipmentTeaser} from '../../ShipmentsTable/ShipmentsTable';
import EditShipmentModal from '../EditShipmentModal/EditShipmentModal';
import Swal from 'sweetalert2';
import {getToken} from '../../../utils/userHelpers';

type Order = Prisma.OrderedPartListTeaserGetPayload<{
  include: {
    customerTransactionDocuments: true,
    orderedPartConfigTeasers: {
      include: {
        customerTransactionDocumentLineItems: {
          include: {
            customerTransactionDocument: true,
          },
        },
        orderedPartConfigStatusLogs: true,
        orderedPart: {
          include: {
            orderedPartPartFeatures: true,
          },
        },
        bundleOrderedPartConfigTeasers: {
          include: {
            shipmentBundleOrderedPartConfigs: true,
            bundle: true,
          },
        },
      },
    },
    company: {
      include: {
        users: true,
      }
    }
  },
}>;

type Position = Prisma.BundleOrderedPartConfigTeaserGetPayload<{
  include: {
    orderedPartConfigTeaser: { include: { orderedPart: true } },
    bundle: true,
  }
}>

type Props = {
  open: boolean
  closeModal: Function
  order: Order | null
  updateOrderInState: (order: Order) => void
};

type State = {
  currentOrder: Order | null
  snackbarOpen: boolean
  snackbarSeverity: 'success' | 'error' | 'warning' | 'info'
  snackbarMessage: string
  selectedTab: 'order' | 'partConfigs'
  selectedOrderedPartConfigIds: GridRowSelectionModel
  selectedPositions: Position[]
  createShipmentModalOpen: boolean
  editShipmentModalOpen: boolean
  createdShipment: ShipmentTeaser | null
  loading: boolean
};

class EditOrderModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentOrder: null,
      snackbarOpen: false,
      snackbarSeverity: 'success',
      snackbarMessage: '',
      selectedTab: 'partConfigs',
      selectedOrderedPartConfigIds: [],
      selectedPositions: [],
      createShipmentModalOpen: false,
      editShipmentModalOpen: false,
      createdShipment: null,
      loading: false,
    };
  }

  CustomToolbar = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <GridToolbarContainer>
        <Grid container justifyContent={'space-between'} width={'100%'}>
          <Grid>
            <GridToolbarColumnsButton />
            <GridToolbarExport />
            <GridToolbarFilterButton />
          </Grid>
          <Grid>
            <Button
              id={'batch-actions-button'}
              aria-controls={open ? 'batch-actions-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup={'true'}
              onClick={handleClick}
              endIcon={<MoreVert />}
              size={'small'}
            >
              Batch Actions
            </Button>
            <Menu
              id={'batch-actions-menu'}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'batch-actions-button',
              }}
            >
              <MenuItem
                disabled={
                  // Disable if no rows are selected or if any of the selected rows are in a status that doesn't allow the action
                  this.state.selectedOrderedPartConfigIds.length === 0 ||
                  this.state.selectedOrderedPartConfigIds.some((rowId) => !['shipped', 'delivered'].includes(this.state.currentOrder?.orderedPartConfigTeasers.find((opct) => opct.id === rowId)?.statusKey!)) ||
                  this.state.selectedOrderedPartConfigIds.some((rowId) => this.state.currentOrder?.orderedPartConfigTeasers.find(opct => opct.id === rowId)?.orderedPartConfigStatusLogs?.some((statusLog) => statusLog.statusKey === 'invoice_sent'))
                }
                onClick={async () => {
                  handleClose();
                  await this.createInvoice();
                }}
              >
                Generate Invoice
              </MenuItem>
              <MenuItem
                disabled={
                  // Disable if no rows are selected, or if the status is not `arrived_at_hub`
                  this.state.selectedOrderedPartConfigIds.length === 0 ||
                  this.state.selectedOrderedPartConfigIds.some((rowId) => this.state.currentOrder?.orderedPartConfigTeasers.find((opct) => opct.id === rowId)?.statusKey !== 'arrived_at_hub')
                }
                onClick={() => {
                  this.setState({
                    createShipmentModalOpen: true,
                  }, handleClose);
                }}
              >
                Create Shipment to Customer
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    );
  };

  componentDidMount() {
    this.setState({
      currentOrder: this.props.order,
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.order !== this.props.order) {
      this.setState({
        currentOrder: this.props.order,
        selectedTab: 'partConfigs',
        selectedOrderedPartConfigIds: [],
      });
    }
  }

  closeModal = () => {
    this.props.closeModal();
  };

  editOrder = async <P extends keyof Order, V extends Order[P]>(property: P, value: V, commitChanges: boolean = false) => {
    this.setState({
      currentOrder: {
        ...this.state.currentOrder!,
        [property]: value,
      },
    });

    if (commitChanges) {
      // Commit changes to the database
      try {
        const updatedOrder = await updateOrder(this.state.currentOrder!.id, {[property]: value});
        this.setState({
          snackbarOpen: true,
          snackbarSeverity: 'success',
          snackbarMessage: 'Changes saved successfully.',
          currentOrder: {...this.state.currentOrder!, ...updatedOrder},
        }, () => this.props.updateOrderInState(this.state.currentOrder!));
      } catch (err) {
        this.setState({
          snackbarOpen: true,
          snackbarSeverity: 'error',
          snackbarMessage: 'An error occurred while trying to save the changes. Try again later.',
          currentOrder: this.props.order,
        });
      }
    }
  };

  updateSelection = (newSelection: GridRowSelectionModel) => {
    this.setState({
      selectedOrderedPartConfigIds: newSelection,
      selectedPositions: this.state.currentOrder!.orderedPartConfigTeasers
        .filter(opct => newSelection.includes(opct.id) &&
          opct.bundleOrderedPartConfigTeasers.find(bopct => KaktusStatuses.filter((status) => status.statusCategory === 'ordered').map((status) => status.statusKey).includes(bopct.bundle?.statusKey as Status))?.bundle?.statusKey)
        .map(opct => ({
          ...opct.bundleOrderedPartConfigTeasers.find(bopct => KaktusStatuses.filter((status) => status.statusCategory === 'ordered').map((status) => status.statusKey).includes(bopct.bundle?.statusKey as Status))!,
          orderedPartConfigTeaser: opct,
        })),
    });
  };

  handleCreateShipmentModalClose = (createdShipment?: ShipmentTeaser | null) => {
    this.setState({
      createShipmentModalOpen: false,
    });

    if (createdShipment) {
      // Open Edit Shipment Modal with the created shipment
      this.setState({
        editShipmentModalOpen: true,
        createdShipment,
      });
    }
  };

  handleEditShipmentModalClose = async () => {
    this.setState({
      editShipmentModalOpen: false,
      loading: true,
    });

    try {
      // Get updated order data
      const updatedOrder = await this.getUpdatedOrder();

      this.setState({
        currentOrder: updatedOrder,
      });

      this.props.updateOrderInState(updatedOrder);
    } catch (err) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'Failed to update order data. Reload the page to see the changes.',
      });
    }

    this.setState({
      loading: false,
    });
  };

  getUpdatedOrder = async () => {
    const {page: [updatedOrder]} = await getOrders(1, 1, undefined, {
      customerTransactionDocuments: true,
      orderedPartConfigTeasers: {
        include: {
          customerTransactionDocumentLineItems: {
            include: {
              customerTransactionDocument: true,
            },
          },
          orderedPartConfigStatusLogs: true,
          orderedPart: {
            include: {
              orderedPartPartFeatures: true,
            },
          },
          bundleOrderedPartConfigTeasers: {
            include: {
              bundle: true,
            },
          },
        },
      },
      company: {
        include: {
          users: true,
        },
      },
    }, {id: this.state.currentOrder!.id});

    return updatedOrder;
  };

  createInvoice = async () => {
    try {
      const {isConfirmed, isDenied} = await Swal.fire({
        title: 'Generate Invoice',
        html: 'If you choose to send an email, the invoice will be sent to the contact chosen in the related KMS Part List, as well as any invoice email address configured in Kontrol Companies.<br><br>A copy of this email will be Bcc\'d to business@kreatize.com.',
        icon: 'question',
        confirmButtonText: 'Generate Invoice & Send Email to Customer',
        denyButtonText: 'Generate Invoice Only',
        denyButtonColor: '#70BCDE',
        confirmButtonColor: '#7ECBAC',
        showCancelButton: false,
        showDenyButton: true,
        showCloseButton: true,
        showLoaderOnConfirm: true,
        showLoaderOnDeny: true,
        preConfirm: async () => {
          try {
            await createInvoice(this.state.currentOrder!.id, true, this.state.selectedOrderedPartConfigIds as number[]);

            // Get updated order data
            const updatedOrder = await this.getUpdatedOrder();

            this.setState({
              currentOrder: updatedOrder,
            });

            this.props.updateOrderInState(updatedOrder);
          } catch (err) {
            Swal.showValidationMessage('An error occurred while trying to create the invoice. Try again later.');
            return false;
          }
        },
        preDeny: async () => {
          try {
            await createInvoice(this.state.currentOrder!.id, false, this.state.selectedOrderedPartConfigIds as number[]);

            // Get updated order data
            const updatedOrder = await this.getUpdatedOrder();

            this.setState({
              currentOrder: updatedOrder,
            });

            this.props.updateOrderInState(updatedOrder);
          } catch (err) {
            Swal.showValidationMessage('An error occurred while trying to create the invoice. Try again later.');
            return false;
          }
        },
      });

      if (isConfirmed || isDenied) {
        await Swal.fire({
          title: 'Invoice Created',
          text: 'The invoice has been created successfully.',
          icon: 'success',
        });
      }
    } catch (err) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'error',
        snackbarMessage: 'An error occurred while trying to create the invoice. Try again later.',
      });
    }
  };

  render() {
    const {open} = this.props;

    return <Modal open={open} closeAfterTransition={true} className={'edit-order-modal-root'}>
      <Fade in={open} timeout={250}>
        <div className={'edit-order-modal'}>
          <Paper className={'edit-order-modal-paper'}>
            <IconButton
              size={'small'}
              className={'close-edit-order-modal-button'}
              onClick={this.closeModal}
            >
              <Close />
            </IconButton>
            {this.state.currentOrder ?
              <Box className={'edit-order-modal-content'}>
                <Box>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid xs={12}>
                      <Typography variant={'h6'}>Editing Order {this.state.currentOrder.kmsId} / {this.state.currentOrder.name}</Typography>
                      <Typography variant={'subtitle2'}>Current Status: {OrderStatuses.find(s => s.statusKey === this.state.currentOrder!.statusKey)?.translation}</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box width={'100%'} height={'calc(100% - 58px)'} overflow={'hidden'}>
                  <Tabs
                    value={this.state.selectedTab}
                    onChange={(_e, newValue) => this.setState({selectedTab: newValue})}
                  >
                    <Tab value={'order'} label={'Order Details'} />
                    <Tab value={'partConfigs'} label={'Parts'} />
                  </Tabs>
                  <Box hidden={this.state.selectedTab !== 'order'} p={2} height={'calc(100% - 54px)'} overflow={'scroll'}>
                    <Grid container spacing={2} width={'100%'}>
                      <Grid container xs={12} spacing={1}>
                        <Grid xs={12}><Typography variant={'h6'}>General Order Details</Typography></Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              Order Name
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              value={this.state.currentOrder.name || ''}
                              onChange={(e) => this.editOrder('name', e.target.value)}
                              onBlur={(e) => this.editOrder('name', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              Customer Order Number
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              value={this.state.currentOrder.internalOrderNumber || ''}
                              onChange={(e) => this.editOrder('internalOrderNumber', e.target.value)}
                              onBlur={(e) => this.editOrder('internalOrderNumber', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              Contact Person
                            </FormLabel>
                            <Autocomplete
                              size={'small'}
                              options={this.state.currentOrder.company!.users.map(user => user.id)}
                              value={this.state.currentOrder.contactUserId || this.state.currentOrder.userId}
                              getOptionLabel={(option) => {
                                const user = this.state.currentOrder!.company!.users.find(user => user.id === option);
                                return user ? `${user.lastName}, ${user.firstName}; ${user.email}` : '';
                              }}
                              onChange={(_e, value) => this.editOrder('contactUserId', value, true)}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              Ship Directly to Customer (from Partner)
                            </FormLabel>
                            <ToggleButtonGroup
                              exclusive
                              size={'small'}
                              value={this.state.currentOrder.isDirectShipment}
                              onChange={(_e, newValue) => newValue !== null && this.editOrder('isDirectShipment', newValue, true)}
                            >
                              <ToggleButton value={true}>
                                <Check />
                              </ToggleButton>
                              <ToggleButton value={false}>
                                <Close />
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </FormGroup>
                        </Grid>
                      </Grid>
                      <Grid container xs={12} spacing={1}>
                        <Grid xs={12}><Typography variant={'h6'}>Delivery Address</Typography></Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              Address Line 1
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              multiline
                              value={this.state.currentOrder.deliveryAddressLine1 || ''}
                              onChange={(e) => this.editOrder('deliveryAddressLine1', e.target.value)}
                              onBlur={(e) => this.editOrder('deliveryAddressLine1', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              Address Line 2
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              multiline
                              value={this.state.currentOrder.deliveryAddressLine2 || ''}
                              onChange={(e) => this.editOrder('deliveryAddressLine2', e.target.value)}
                              onBlur={(e) => this.editOrder('deliveryAddressLine2', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              Zip Code
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              value={this.state.currentOrder.deliveryZipCode || ''}
                              onChange={(e) => this.editOrder('deliveryZipCode', e.target.value)}
                              onBlur={(e) => this.editOrder('deliveryZipCode', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              City
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              value={this.state.currentOrder.deliveryCity || ''}
                              onChange={(e) => this.editOrder('deliveryCity', e.target.value)}
                              onBlur={(e) => this.editOrder('deliveryCity', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              State
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              value={this.state.currentOrder.deliveryState || ''}
                              onChange={(e) => this.editOrder('deliveryState', e.target.value)}
                              onBlur={(e) => this.editOrder('deliveryState', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              Country
                            </FormLabel>
                            <FormControl size={'small'} fullWidth>
                              <Select
                                value={this.state.currentOrder.deliveryCountry}
                                onChange={(e) => this.editOrder('deliveryCountry', e.target.value, true)}
                              >
                                {['DE', 'AT', 'CH', 'BE', 'FR', 'IT', 'SE', 'GB'].map((country) => {
                                  return <MenuItem value={country}>{country}</MenuItem>;
                                })}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>
                      </Grid>
                      <Grid container xs={12} spacing={1}>
                        <Grid xs={12}><Typography variant={'h6'}>Billing Address</Typography></Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              Address Line 1
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              multiline
                              value={this.state.currentOrder.billingAddressLine1 || ''}
                              onChange={(e) => this.editOrder('billingAddressLine1', e.target.value)}
                              onBlur={(e) => this.editOrder('billingAddressLine1', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              Address Line 2
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              multiline
                              value={this.state.currentOrder.billingAddressLine2 || ''}
                              onChange={(e) => this.editOrder('billingAddressLine2', e.target.value)}
                              onBlur={(e) => this.editOrder('billingAddressLine2', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              Zip Code
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              value={this.state.currentOrder.billingZipCode || ''}
                              onChange={(e) => this.editOrder('billingZipCode', e.target.value)}
                              onBlur={(e) => this.editOrder('billingZipCode', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              City
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              value={this.state.currentOrder.billingCity || ''}
                              onChange={(e) => this.editOrder('billingCity', e.target.value)}
                              onBlur={(e) => this.editOrder('billingCity', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              State
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              value={this.state.currentOrder.billingState || ''}
                              onChange={(e) => this.editOrder('billingState', e.target.value)}
                              onBlur={(e) => this.editOrder('billingState', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              Country
                            </FormLabel>
                            <FormControl size={'small'} fullWidth>
                              <Select
                                value={this.state.currentOrder.billingCountryKey}
                                onChange={(e) => this.editOrder('billingCountryKey', e.target.value, true)}
                              >
                                {['DE', 'AT', 'CH', 'BE', 'FR', 'IT', 'SE', 'GB'].map((country) => {
                                  return <MenuItem value={country}>{country}</MenuItem>;
                                })}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>
                      </Grid>
                      <Grid container xs={12} spacing={1} columns={10}>
                        <Grid xs={10}><Typography variant={'h6'}>Delivery Dates & Terms</Typography></Grid>
                        <Grid xs={2}>
                          <FormGroup>
                            <FormLabel>
                              Estimated Delivery Date
                            </FormLabel>
                            <DatePicker
                              disabled
                              value={moment(this.state.currentOrder.estimatedDeliveryDate)}
                              slotProps={{textField: {size: 'small', fullWidth: true}}}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={2}>
                          <FormGroup>
                            <FormLabel>
                              Desired Delivery Date
                            </FormLabel>
                            <DatePicker
                              value={moment(this.state.currentOrder.desiredDeliveryDate)}
                              slotProps={{textField: {size: 'small', fullWidth: true}}}
                              onChange={(value) => this.editOrder('desiredDeliveryDate', value?.toDate() || null, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={2}>
                          <FormGroup>
                            <FormLabel>
                              Expected Delivery Date
                            </FormLabel>
                            <DatePicker
                              value={moment(this.state.currentOrder.expectedDeliveryDate)}
                              slotProps={{textField: {size: 'small', fullWidth: true}}}
                              disabled={!this.state.currentOrder.expectedDeliveryDate}
                              onChange={(value) => this.editOrder('expectedDeliveryDate', value?.toDate() || null, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={2}>
                          <FormGroup>
                            <FormLabel>
                              Delivery Terms (Incoterms)
                            </FormLabel>
                            <Select
                              value={this.state.currentOrder.incoterms}
                              size={'small'}
                              onChange={(e) => this.editOrder('incoterms', e.target.value, true)}
                            >
                              {['', 'dap', 'ddp', 'fca', 'exw'].map((deliveryTerm) => {
                                return <MenuItem value={deliveryTerm} disabled={deliveryTerm === ''}>{deliveryTerm ? deliveryTerm : 'No Delivery Term Selected'}</MenuItem>;
                              })}
                            </Select>
                          </FormGroup>
                        </Grid>
                        <Grid xs={2}>
                          <FormGroup>
                            <FormLabel>
                              Delayed
                            </FormLabel>
                            <ToggleButtonGroup
                              exclusive
                              size={'small'}
                              value={this.state.currentOrder.delayed}
                              onChange={(_e, newValue) => this.editOrder('delayed', newValue, true)}
                            >
                              <ToggleButton value={true}>
                                <Check />
                              </ToggleButton>
                              <ToggleButton value={false}>
                                <Close />
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box hidden={this.state.selectedTab !== 'partConfigs'} p={2} height={'calc(100% - 48px)'}>
                    <DataGridPro
                      rows={this.state.currentOrder.orderedPartConfigTeasers}
                      columns={[
                        {field: 'id', headerName: '#', width: 100},
                        {field: 'name', headerName: 'Part Name', width: 200, valueGetter: (_value, row) => row.orderedPart?.name},
                        {field: 'drawingNr', headerName: 'Drawing #', width: 200, valueGetter: (_value, row) => row.orderedPart?.drawingNr},
                        {field: 'materialName', headerName: 'Material', width: 100},
                        {field: 'batchSize', headerName: 'Qty.', width: 100},
                        {field: 'weightInKg', headerName: 'Weight (kg)', width: 100, valueFormatter: (value) => value ? `${value} kg` : ''},
                        {field: 'totalCost', headerName: 'Total Cost', width: 100, valueFormatter: (value: string) => formatCurrency(value)},
                        {field: 'statusKey', headerName: 'Current Status', width: 150, valueFormatter: (value: string) => OrderStatuses.find((status) => status.statusKey === value)?.translation},
                        {
                          field: 'currentSupplier', headerName: 'Current Supplier', width: 200,
                          valueGetter: (_value, row) =>
                            row.bundleOrderedPartConfigTeasers.find(bopct => KaktusStatuses.filter((status) => status.statusCategory === 'ordered').map((status) => status.statusKey).includes(bopct.bundle?.statusKey as Status))?.bundle?.companyName,
                        },
                        {
                          field: 'currentBundle', headerName: 'Current Bundle', width: 150,
                          valueGetter: (_value, row) =>
                            row.bundleOrderedPartConfigTeasers.find(bopct => KaktusStatuses.filter((status) => status.statusCategory === 'ordered').map((status) => status.statusKey).includes(bopct.bundle?.statusKey as Status))?.bundle?.bundleKid,
                        },
                        {
                          field: 'currentBundleStatus', headerName: 'Bundle Status', width: 150,
                          valueGetter: (_value, row) =>
                            row.bundleOrderedPartConfigTeasers.find(bopct => KaktusStatuses.filter((status) => status.statusCategory === 'ordered').map((status) => status.statusKey).includes(bopct.bundle?.statusKey as Status))?.bundle?.statusKey,
                          valueFormatter: (value: string) => KaktusStatuses.find((status) => status.statusKey === value)?.translation,
                        },
                        {
                          field: 'bundleOrderedPartConfigTeasers[].shipmentBundleOrderedPartConfigs[].shipmentId', headerName: 'Related Shipment(s)', width: 150,
                          valueGetter: (_value, row) =>
                            row.bundleOrderedPartConfigTeasers.find(
                              bopct => KaktusStatuses.filter((status) => status.statusCategory === 'ordered').map((status) => status.statusKey).includes(bopct.bundle?.statusKey as Status))?.shipmentBundleOrderedPartConfigs,
                          valueFormatter: (value: ShipmentBundleOrderedPartConfig[]) => value?.map(sbopc => `S-${sbopc.shipmentId}`).join(', '),
                        },
                        {
                          field: 'bundleOrderedPartConfigTeasers[].isInComplaint', headerName: 'In Complaint?', width: 150, type: 'boolean',
                          valueGetter: (_value, row) =>
                            row.bundleOrderedPartConfigTeasers.find(bopct => KaktusStatuses.filter((status) => status.statusCategory === 'ordered').map((status) => status.statusKey).includes(bopct.bundle?.statusKey as Status))?.isInComplaint,
                        },
                        {
                          field: 'actions', headerName: 'Actions', type: 'actions', width: 80,
                          getActions: (params) => {
                            return [
                              <GridActionsCellItem
                                icon={<Download />}
                                label={'Download Invoice (DE)'}
                                onClick={() => window.open(
                                  `${process.env.REACT_APP_KNEST_KONTROL_URL}/ordered-part-lists/${params.row.orderedPartListId}/invoices/${params.row.customerTransactionDocumentLineItems.find(
                                    ctdli => ctdli.customerTransactionDocument!.documentType === 'invoice')?.customerTransactionDocument!.id}?language=de&access_token=${getToken().substring(7)}`,
                                  '_blank',
                                  'height=600,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no',
                                )}
                                disabled={!params.row.customerTransactionDocumentLineItems.some(ctdli => ctdli.customerTransactionDocument!.documentType === 'invoice')}
                                showInMenu
                              />,
                              <GridActionsCellItem
                                icon={<Download />}
                                label={'Download Invoice (EN)'}
                                onClick={() => window.open(
                                  `${process.env.REACT_APP_KNEST_KONTROL_URL}/ordered-part-lists/${params.row.orderedPartListId}/invoices/${params.row.customerTransactionDocumentLineItems.find(
                                    ctdli => ctdli.customerTransactionDocument!.documentType === 'invoice')?.customerTransactionDocument!.id}?language=en&access_token=${getToken().substring(7)}`,
                                  '_blank',
                                  'height=600,width=800,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no',
                                )}
                                disabled={!params.row.customerTransactionDocumentLineItems.some(ctdli => ctdli.customerTransactionDocument!.documentType === 'invoice')}
                                showInMenu
                              />,
                            ];
                          },
                        },
                      ]}
                      checkboxSelection={true}
                      rowSelectionModel={this.state.selectedOrderedPartConfigIds}
                      onRowSelectionModelChange={this.updateSelection}
                      initialState={{
                        density: 'compact',
                        pinnedColumns: {left: [GRID_CHECKBOX_SELECTION_FIELD, 'id'], right: ['actions']},
                      }}
                      slots={{toolbar: this.CustomToolbar}}
                      loading={this.state.loading}
                    />
                  </Box>
                </Box>
                <CreateShipmentModal
                  open={this.state.createShipmentModalOpen}
                  bundleOrderedPartConfigTeasers={this.state.selectedPositions}
                  onClose={this.handleCreateShipmentModalClose}
                  shipmentPrefillData={{
                    fromCompanyId: 1,
                    fromAddressLine1: 'Work Box Berlin c/o KREATIZE GmbH',
                    fromAddressLine2: 'Eresburgstr. 24-29',
                    fromZipCode: '12103',
                    fromCity: 'Berlin',
                    fromState: 'Berlin',
                    fromCountryKey: 'DE',
                    toCompanyId: this.state.currentOrder!.companyId!,
                    toAddressLine1: this.state.currentOrder!.deliveryAddressLine1!,
                    toAddressLine2: this.state.currentOrder!.deliveryAddressLine2!,
                    toZipCode: this.state.currentOrder!.deliveryZipCode!,
                    toCity: this.state.currentOrder!.deliveryCity!,
                    toState: this.state.currentOrder!.deliveryState!,
                    toCountryKey: this.state.currentOrder!.deliveryCountry!,
                    carrierKey: 'dhl',
                    isExpressShipment: false,
                  }}
                />
                <EditShipmentModal
                  open={this.state.editShipmentModalOpen}
                  shipment={this.state.createdShipment}
                  onClose={this.handleEditShipmentModalClose}
                />
              </Box>
              : <></>}
          </Paper>
          <Snackbar
            open={this.state.snackbarOpen}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            autoHideDuration={4000}
            onClose={(_event, reason) => reason !== 'clickaway' && this.setState({snackbarOpen: false})}
          >
            <Alert onClose={() => this.setState({snackbarOpen: false})} severity={this.state.snackbarSeverity} sx={{width: '100%'}}>
              {this.state.snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      </Fade>
    </Modal>;
  }
}

export default EditOrderModal;
