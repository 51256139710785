import React, {Component} from 'react';
import {Company, Prisma} from '@prisma/client';
import {Alert, Autocomplete, Box, Button, Fade, FormControl, FormGroup, FormLabel, IconButton, MenuItem, Modal, Paper, Select, Snackbar, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography, Unstable_Grid2 as Grid} from '@mui/material';
import {Check, Close, Download} from '@mui/icons-material';
import {getData as getAllCountries} from 'country-list';
import './EditShipmentModal.scss';
import {Carriers, KaktusStatuses, OrderStatuses, ShippingStatuses} from '../../../types/ReferentialData';
import {downloadShipmentDeliverySlip, getCompanies, updateShipment, updateShipmentStatus} from '../../../services/knestKontrol';
import {DataGridPro} from '@mui/x-data-grid-pro';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {ShipmentTeaser} from '../../ShipmentsTable/ShipmentsTable';
import {NavLink} from 'react-router-dom';

const MySwal = withReactContent(Swal);

const validStatusTransitions: { [key in typeof ShippingStatuses[number]['statusKey']]: typeof ShippingStatuses[number]['statusKey'][] } = {
  created: ['in_transit'],
  in_transit: ['shipment_delivered', 'shipment_lost'],
  shipment_delivered: ['shipment_returned'],
  shipment_lost: [],
  shipment_returned: [],
};

type Shipment = Prisma.ShipmentGetPayload<{}>

type State = {
  currentShipment: ShipmentTeaser | null,
  companies: Company[],
  snackbarOpen: boolean
  snackbarSeverity: 'success' | 'error' | 'warning' | 'info'
  snackbarMessage: string
  selectedTab: 'shipmentDetails' | 'positions'
};

type Props = {
  shipment: ShipmentTeaser | null,
  open: boolean,
  updateShipmentInState?: (shipment: ShipmentTeaser) => void,
  onClose: () => void,
};

class EditShipmentModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      currentShipment: null,
      snackbarOpen: false,
      snackbarSeverity: 'success',
      snackbarMessage: '',
      selectedTab: 'shipmentDetails',
      companies: [],
    };
  }

  async componentDidMount() {
    // Get companies
    const companies = await getCompanies({});

    this.setState({
      companies,
      currentShipment: this.props.shipment,
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.shipment !== this.props.shipment) {
      this.setState({
        currentShipment: this.props.shipment,
      });
    }
  }

  editShipment = async <P extends keyof Shipment, V extends Shipment[P]>(property: P, value: V, commitChange: boolean = false) => {
    this.setState({
      currentShipment: {
        ...this.state.currentShipment!,
        [property]: value,
      },
    });

    if (commitChange && this.props.shipment?.[property] as any !== (value || null)) {
      // Commit the change to the database
      try {
        const updatedShipment = await updateShipment(this.state.currentShipment!.id, {[property]: value || null});

        this.setState({
          snackbarOpen: true,
          snackbarSeverity: 'success',
          snackbarMessage: `Shipment updated successfully`,
          currentShipment: {
            ...this.state.currentShipment!,
            ...updatedShipment,
            fromCompany: this.state.companies.find(c => c.id === updatedShipment.fromCompanyId)!,
            toCompany: this.state.companies.find(c => c.id === updatedShipment.toCompanyId)!,
          },
        }, () => this.props.updateShipmentInState && this.props.updateShipmentInState(this.state.currentShipment!));
      } catch (err: any) {
        this.setState({
          snackbarOpen: true,
          snackbarSeverity: 'error',
          snackbarMessage: `Failed to update shipment: ${err?.message || err?.response?.data?.message || err?.response?.data || err}`,
          currentShipment: this.props.shipment,
        });
      }
    }
  };

  updateShipmentStatus = async (statusKey: typeof ShippingStatuses[number]['statusKey']) => {
    const {isConfirmed} = await MySwal.fire({
      title: 'Are you sure you want to update the shipment status?',
      text: `This will transition the shipment to status ${ShippingStatuses.find(s => s.statusKey === statusKey)?.translation}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, update status',
      cancelButtonText: 'No, cancel',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          await updateShipmentStatus(this.state.currentShipment!.id, statusKey);

          this.setState({
            currentShipment: {
              ...this.state.currentShipment!,
              statusKey,
            },
          });

          this.props.updateShipmentInState && this.props.updateShipmentInState({
            ...this.state.currentShipment!,
            statusKey,
          });
        } catch (e: any) {
          Swal.showValidationMessage(`An error occurred while transitioning the status: ${e?.response?.data?.message || e?.response?.data || e?.message || e}`);
        }
      },
    });

    if (isConfirmed) {
      this.setState({
        snackbarOpen: true,
        snackbarSeverity: 'success',
        snackbarMessage: 'Shipment status updated successfully',
      });
    }
  };

  render() {
    const {open} = this.props;
    return <Modal open={open} closeAfterTransition={true} className={'edit-shipment-modal-root'}>
      <Fade in={open} timeout={250}>
        <div className={'edit-shipment-modal'}>
          <Paper className={'edit-shipment-modal-paper'}>
            <IconButton
              size={'small'}
              className={'close-edit-shipment-modal-button'}
              onClick={this.props.onClose}
            >
              <Close />
            </IconButton>
            {this.state.currentShipment ?
              <Box className={'edit-shipment-modal-content'}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid xs={12}>
                      <Typography variant={'h6'}>Editing Shipment #{this.state.currentShipment.id}</Typography>
                      <Typography variant={'subtitle2'}>This shipment contains {this.state.currentShipment.positionsCount} positions, being delivered to {this.state.currentShipment.toCompany?.companyName}</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box width={'100%'} height={'calc(100% - 58px)'} overflow={'hidden'}>
                  <Grid container width={'100%'}>
                    <Grid xs={4}>
                      <Tabs
                        value={this.state.selectedTab}
                        onChange={(_event, newValue) => this.setState({selectedTab: newValue})}
                      >
                        <Tab value={'shipmentDetails'} label={'Shipment Details'} />
                        <Tab value={'positions'} label={'Positions'} />
                      </Tabs>
                    </Grid>
                    <Grid xs={8} alignItems={'flex-end'} justifyContent={'flex-end'} container spacing={2}>
                      <Grid key={'downloadDeliverySlip'} xs={'auto'}>
                        <Button
                          onClick={() => downloadShipmentDeliverySlip(this.state.currentShipment!.id)}
                          startIcon={<Download />}
                          variant={'contained'}
                          color={'success'}
                          sx={{color: '#fff'}}
                        >
                          Download Delivery Slip
                        </Button>
                      </Grid>
                      {validStatusTransitions[this.state.currentShipment.statusKey!].map(transition => (
                        <Grid key={transition} xs={'auto'}>
                          <Button
                            onClick={() => this.updateShipmentStatus(transition)}
                            variant={'contained'}
                            sx={{color: '#fff'}}
                          >
                            {ShippingStatuses.find(s => s.statusKey === transition)?.translation}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Box hidden={this.state.selectedTab !== 'shipmentDetails'} p={2} height={'calc(100% - 54px)'} overflow={'scroll'}>
                    <Grid container spacing={2} width={'100%'}>
                      <Grid xs={12} container spacing={1}>
                        <Grid xs={12}><Typography>General Shipment Details</Typography></Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              Carrier
                            </FormLabel>
                            <Select
                              value={this.state.currentShipment.carrierKey}
                              size={'small'}
                              onChange={event => this.editShipment('carrierKey', event.target.value as string, true)}
                            >
                              {Carriers.map(carrier => (
                                <MenuItem value={carrier.carrierKey} key={carrier.carrierKey}>{carrier.translation}</MenuItem>
                              ))}
                            </Select>
                          </FormGroup>
                        </Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              Tracking Number
                            </FormLabel>
                            <TextField
                              value={this.state.currentShipment.trackingNumber}
                              size={'small'}
                              onChange={event => this.editShipment('trackingNumber', event.target.value, false)}
                              onBlur={event => this.editShipment('trackingNumber', event.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={3}>
                          <FormGroup>
                            <FormLabel>
                              Is Express Shipment?
                            </FormLabel>
                            <ToggleButtonGroup
                              exclusive
                              size={'small'}
                              value={this.state.currentShipment.isExpressShipment}
                              onChange={(_e, newValue) => this.editShipment('isExpressShipment', newValue, true)}
                            >
                              <ToggleButton value={true}>
                                <Check />
                              </ToggleButton>
                              <ToggleButton value={false}>
                                <Close />
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </FormGroup>
                        </Grid>
                      </Grid>
                      <Grid xs={6} container spacing={1}>
                        <Grid xs={12}><Typography>Sender Details</Typography></Grid>
                        <Grid xs={12}>
                          <FormGroup>
                            <FormLabel>
                              Shipping Company
                            </FormLabel>
                            <Autocomplete<Company, false, true>
                              value={this.state.companies.find(c => c.id === this.state.currentShipment!.fromCompanyId)}
                              onChange={(_e, newValue) => this.editShipment('fromCompanyId', newValue?.id as number, true)}
                              size={'small'}
                              options={this.state.companies}
                              getOptionLabel={option => option.companyName as string}
                              getOptionKey={option => option.id}
                              renderInput={(params) => <TextField {...params} />}
                              disableClearable
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={6}>
                          <FormGroup>
                            <FormLabel>
                              Address Line 1
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              multiline
                              value={this.state.currentShipment.fromAddressLine1 || ''}
                              onChange={(e) => this.editShipment('fromAddressLine1', e.target.value)}
                              onBlur={(e) => this.editShipment('fromAddressLine1', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={6}>
                          <FormGroup>
                            <FormLabel>
                              Address Line 2
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              multiline
                              value={this.state.currentShipment.fromAddressLine2 || ''}
                              onChange={(e) => this.editShipment('fromAddressLine2', e.target.value)}
                              onBlur={(e) => this.editShipment('fromAddressLine2', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={6}>
                          <FormGroup>
                            <FormLabel>
                              Zip Code
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              multiline
                              value={this.state.currentShipment.fromZipCode || ''}
                              onChange={(e) => this.editShipment('fromZipCode', e.target.value)}
                              onBlur={(e) => this.editShipment('fromZipCode', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={6}>
                          <FormGroup>
                            <FormLabel>
                              City
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              multiline
                              value={this.state.currentShipment.fromCity || ''}
                              onChange={(e) => this.editShipment('fromCity', e.target.value)}
                              onBlur={(e) => this.editShipment('fromCity', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={6}>
                          <FormGroup>
                            <FormLabel>
                              State
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              multiline
                              value={this.state.currentShipment.fromState || ''}
                              onChange={(e) => this.editShipment('fromState', e.target.value)}
                              onBlur={(e) => this.editShipment('fromState', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={6}>
                          <FormGroup>
                            <FormLabel>
                              Country
                            </FormLabel>
                            <FormControl size={'small'}>
                              <Select
                                value={this.state.currentShipment.fromCountryKey || ''}
                                onChange={(e) => this.editShipment('fromCountryKey', e.target.value!, true)}
                              >
                                {getAllCountries().map(({code, name}) => {
                                  return <MenuItem value={code} key={code}>{name}</MenuItem>;
                                })}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>
                      </Grid>
                      <Grid xs={6} container spacing={1}>
                        <Grid xs={12}><Typography>Receiver Details</Typography></Grid>
                        <Grid xs={12}>
                          <FormGroup>
                            <FormLabel>
                              Receiving Company
                            </FormLabel>
                            <Autocomplete<Company, false, true>
                              value={this.state.companies.find(c => c.id === this.state.currentShipment!.toCompanyId)}
                              onChange={(_e, newValue) => this.editShipment('toCompanyId', newValue?.id as number, true)}
                              size={'small'}
                              options={this.state.companies}
                              getOptionLabel={option => option.companyName as string}
                              getOptionKey={option => option.id}
                              renderInput={(params) => <TextField {...params} />}
                              disableClearable
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={6}>
                          <FormGroup>
                            <FormLabel>
                              Address Line 1
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              multiline
                              value={this.state.currentShipment.toAddressLine1 || ''}
                              onChange={(e) => this.editShipment('toAddressLine1', e.target.value)}
                              onBlur={(e) => this.editShipment('toAddressLine1', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={6}>
                          <FormGroup>
                            <FormLabel>
                              Address Line 2
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              multiline
                              value={this.state.currentShipment.toAddressLine2 || ''}
                              onChange={(e) => this.editShipment('toAddressLine2', e.target.value)}
                              onBlur={(e) => this.editShipment('toAddressLine2', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={6}>
                          <FormGroup>
                            <FormLabel>
                              Zip Code
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              multiline
                              value={this.state.currentShipment.toZipCode || ''}
                              onChange={(e) => this.editShipment('toZipCode', e.target.value)}
                              onBlur={(e) => this.editShipment('toZipCode', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={6}>
                          <FormGroup>
                            <FormLabel>
                              City
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              multiline
                              value={this.state.currentShipment.toCity || ''}
                              onChange={(e) => this.editShipment('toCity', e.target.value)}
                              onBlur={(e) => this.editShipment('toCity', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={6}>
                          <FormGroup>
                            <FormLabel>
                              State
                            </FormLabel>
                            <TextField
                              size={'small'}
                              fullWidth
                              multiline
                              value={this.state.currentShipment.toState || ''}
                              onChange={(e) => this.editShipment('toState', e.target.value)}
                              onBlur={(e) => this.editShipment('toState', e.target.value, true)}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid xs={6}>
                          <FormGroup>
                            <FormLabel>
                              Country
                            </FormLabel>
                            <FormControl size={'small'}>
                              <Select
                                value={this.state.currentShipment.toCountryKey}
                                onChange={(e) => this.editShipment('toCountryKey', e.target.value!, true)}
                              >
                                {getAllCountries().map(({code, name}) => {
                                  return <MenuItem value={code} key={code}>{name}</MenuItem>;
                                })}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box hidden={this.state.selectedTab !== 'positions'} p={2} height={'calc(100% - 48px)'}>
                    <DataGridPro
                      rows={this.state.currentShipment.shipmentBundleOrderedPartConfigs}
                      columns={[
                        {field: 'bundleOrderedPartConfigId', headerName: 'Position ID', width: 100},
                        {
                          field: 'bundleOrderedPartConfigTeaser.bundleId', headerName: 'Bundle ID', width: 150,
                          valueGetter: (_value, row) => `KAKTUS-${row.bundleOrderedPartConfigTeaser!.bundleId}`,
                        },
                        {
                          field: 'bundleOrderedPartConfigTeaser.orderedPartConfigId', headerName: 'Ordered Part Config ID', width: 100,
                          valueGetter: (_value, row) => row.bundleOrderedPartConfigTeaser!.orderedPartConfigId,
                        },
                        {
                          field: 'bundleOrderedPartConfigTeaser.orderedPartConfigTeaser.orderedPart.name', headerName: 'Part Name', width: 150,
                          valueGetter: (_value, row) => row.bundleOrderedPartConfigTeaser!.orderedPartConfigTeaser!.orderedPart!.name,
                        },
                        {
                          field: 'bundleOrderedPartConfigTeaser.orderedPartConfigTeaser.orderedPart.drawingNr', headerName: 'Drawing Nr', width: 150,
                          valueGetter: (_value, row) => row.bundleOrderedPartConfigTeaser!.orderedPartConfigTeaser!.orderedPart!.drawingNr,
                        },
                        {
                          field: 'bundleOrderedPartConfigTeaser.orderedPartConfigTeaser.orderedPartListId', headerName: 'Order ID', width: 150,
                          renderCell: (params) => {
                            const orderId = params.row.bundleOrderedPartConfigTeaser!.orderedPartConfigTeaser!.orderedPartListId!;
                            const filterModel = JSON.stringify({items: [{field: 'kmsId', operator: 'contains', value: orderId.toString()}]});
                            return <NavLink to={`/orders#editOrderId=${orderId}&filterModel=${encodeURIComponent(filterModel)}`}>{`KMS-${orderId}`}</NavLink>;
                          },
                        },
                        {
                          field: 'bundleOrderedPartConfigTeaser.orderedPartConfig.orderedPartListTeaser.companyName', headerName: 'Customer Name', width: 200,
                          valueGetter: (_value, row) => row.bundleOrderedPartConfigTeaser!.orderedPartConfigTeaser!.orderedPartListTeaser!.companyName,
                        },
                        {field: 'quantity', headerName: 'Quantity Shipped', width: 120, type: 'number'},
                        {
                          field: 'bundleOrderedPartConfigTeaser.orderedPartConfigTeaser.batchSize', headerName: 'Quantity Ordered', width: 120, type: 'number',
                          valueGetter: (_value, row) => row.bundleOrderedPartConfigTeaser!.orderedPartConfigTeaser!.batchSize,
                        },
                        {
                          field: 'bundleOrderedPartConfigTeaser.statusKey', headerName: 'Position Status', width: 150, type: 'singleSelect',
                          valueOptions: KaktusStatuses.map(({statusKey, translation}) => ({value: statusKey, label: translation})),
                          valueGetter: (_value, row) => row.bundleOrderedPartConfigTeaser!.statusKey,
                        },
                        {
                          field: 'bundleOrderedPartConfigTeaser.orderedPartConfigTeaser.statusKey', headerName: 'Ordered Part Config Status', width: 200, type: 'singleSelect',
                          valueOptions: OrderStatuses.map(({statusKey, translation}) => ({value: statusKey, label: translation})),
                          valueGetter: (_value, row) => row.bundleOrderedPartConfigTeaser!.orderedPartConfigTeaser!.statusKey,
                        },
                      ]}
                      getRowId={row => `${row.shipmentId}_${row.bundleOrderedPartConfigId}`}
                    />
                  </Box>
                </Box>
              </Box>
              : <></>
            }
          </Paper>
          <Snackbar
            open={this.state.snackbarOpen}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            autoHideDuration={4000}
            onClose={(_event, reason) => reason !== 'clickaway' && this.setState({snackbarOpen: false})}
          >
            <Alert onClose={() => this.setState({snackbarOpen: false})} severity={this.state.snackbarSeverity} sx={{width: '100%'}}>
              {this.state.snackbarMessage}
            </Alert>
          </Snackbar>
        </div>
      </Fade>
    </Modal>;
  }
}

export default EditShipmentModal;
