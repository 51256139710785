import React, {Component} from 'react';
import {Grid, IconButton, Paper, TextField, MenuItem, FormControlLabel, Checkbox} from '@mui/material';
import {AccountTree, Add, Delete} from '@mui/icons-material';
import {getDataTypes, getUnits} from 'services/kooperKontrol';
import {
  deleteKaktusProcessFeature,
  deleteProcessFeature,
  getProcessFeatures,
  patchProcessFeature,
  postKaktusProcessFeature,
  postProcessFeature,
} from 'services/knestKontrol';
import Swal from 'sweetalert2';
import SelectOptionsModal from '../Modals/SelectOptionsModal/SelectOptionsModal';
import {DataGridPro} from '@mui/x-data-grid-pro';
import {Box} from '@mui/material';

class ProcessFeaturesTable extends Component {
  constructor(props) {
    super(props);

    this.apps = [{label: 'KMS', value: 'kms'}, {label: 'Kaktus', value: 'kaktus'}];

    this.dataTypes = [
      'process',
      'process_feature',
    ];

    this.defaultNewProcessFeature = {
      key: '',
      dataTypeKey: null,
      unitKey: null,
    };

    this.state = {
      loading: true,
      columns: [],
      processFeatures: [],
      units: [],
      dataTypes: [],
      selectedProcessFeature: {},
      selectOptionsModalOpen: false,
      newProcessFeature: {
        key: '',
        dataTypeKey: '',
        unitKey: null,
      },
    };
  }

  fetchAndRefreshData = async () => {
    this.setState({loading: true});

    const [
      processFeatures,
      units,
      dataTypes,
    ] = await Promise.all([
      getProcessFeatures(),
      getUnits(),
      getDataTypes(),
    ]);

    await this.setState({
      loading: false,
      units: [...units.map(x => x.key), 'null'],
      dataTypes: dataTypes.map(x => x.key),
      processFeatures: this.mapProcessFeatures(processFeatures),
      columns: [
        {field: 'key', headerName: 'Key', flex: 1, filterable: true, editable: true},
        {
          field: 'dataTypeKey',
          headerName: 'Data type',
          type: 'singleSelect',
          valueOptions: dataTypes.map((x) => x.key),
          flex: 1,
          filterable: true,
          editable: true,
        },
        {
          field: 'unitKey',
          headerName: 'Unit',
          type: 'singleSelect',
          valueOptions: [...units.map((x) => x.key), 'null'],
          flex: 1,
          filterable: true,
          editable: true,
        },
        {
          field: 'actions', headerName: 'Actions', flex: 1.5, filterable: false, editable: false,
          renderCell: params => {
            return (
              <Grid container spacing={4} alignItems={'center'}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size={'small'}
                        checked={!!params.row.kaktusProcessFeatures.length}
                        onChange={event => this.handleAppProcessFeatureSelect(event.target.checked, params.row, 'kaktus')}
                      />
                    }
                    sx={{margin: 0}}
                    label={'Show on Kaktus'}
                    key={'kaktus'}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    size={'small'}
                    onClick={() => this.onDeleteProcessFeature(params.row)}
                    style={{marginBottom: '6px'}}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
                {params.row.dataTypeKey === 'select' && <Grid item>
                  <IconButton size={'small'} onClick={() => this.updateProcessFeatureSelectOptions(params.row)}>
                    <AccountTree />
                  </IconButton>
                </Grid>}
              </Grid>
            );
          },
        },
      ],
    });
  };

  async componentDidMount() {
    await this.fetchAndRefreshData();
  }

  mapProcessFeatures = (processFeatures) => {
    return processFeatures.map((pf) => {
      return {
        id: pf.id,
        ...pf,
        apps: this.apps.map(app => {
          return {
            ...app,
            selected: this.state[`${app.value}ProcessFeatures`]?.some(id => id === pf.id),
          };
        }),
      };
    });
  };

  onDeleteProcessFeature = async (row) => {
    const {isConfirmed} = await Swal.fire({
      customClass: {
        container: 'custom-swal-container',
      },
      title: 'Are you sure you want to delete this process feature?',
      icon: 'warning',
      confirmButtonText: 'Yes, delete.',
      showCancelButton: true,
      reverseButtons: true,
    });

    if (!isConfirmed) return;

    try {
      await deleteProcessFeature(row.key);
      this.updateProcessFeaturesState(row, true);
      this.props.toast.success('Deletion done 🗑');
    } catch (e) {
      this.props.toast.error(`⛈ Houston, we have a problem: ${e.response?.data?.details}`);
    }
  };

  updateProcessFeaturesState = (processFeature, del = false) => {
    if (del) {
      return this.setState({
        processFeatures: this.state.processFeatures.filter(t => t.id !== processFeature.id),
      });
    }

    if (!this.state.processFeatures.some(x => x.id === processFeature.id)) {
      return this.setState({
        processFeatures: [processFeature, ...this.state.processFeatures],
      });
    }
    return this.setState({
      processFeatures: this.state.processFeatures.map(x => (x.id === processFeature.id ? {...x, ...processFeature} : x)),
    });
  };

  updateNewProcessFeatureState = (key, value) => {
    return this.setState({
      newProcessFeature: {...this.state.newProcessFeature, ...{[key]: value}},
    });
  };

  processRowUpdate = async (newRow, oldRow) => {
    try {
      await patchProcessFeature(oldRow.key, {
        key: newRow.key.trim(),
        dataTypeKey: newRow.dataTypeKey,
        unitKey: newRow.unitKey === 'null' ? null : newRow.unitKey,
      });
      this.props.toast.success('Edit done 👌');

      return newRow;
    } catch (e) {
      console.error(e);
      this.props.toast.error('🤔 Edit didn\'t work: ' + e.message);
      return oldRow;
    }
  };

  onNewProcessFeatureClick = async () => {
    try {
      await postProcessFeature(this.state.newProcessFeature);

      await this.fetchAndRefreshData();

      this.setState({
        newProcessFeature: this.defaultNewProcessFeature,
      });
    } catch (e) {
      console.error(e);
    }
  };

  validNewProcessFeature = () => {
    const _newProcessFeature = this.state.newProcessFeature;

    if (!_newProcessFeature.key?.length) {
      return false;
    }

    return _newProcessFeature.dataTypeKey?.length;
  };

  handleAppProcessFeatureSelect = async (value, processFeature) => {
    value ? await postKaktusProcessFeature({processFeatureKey: processFeature.key}) : await deleteKaktusProcessFeature(processFeature.key);

    await this.setState({
      processFeatures: this.state.processFeatures.map(pf => {
        return {
          ...pf,
          kaktusProcessFeatures: pf.key === processFeature.key ? (value ? [{processFeatureKey: processFeature.key}] : []) : pf.kaktusProcessFeatures,
        };
      }),
    });
  };

  updateProcessFeatureSelectOptions = (processFeature) => {
    this.setState({
      selectOptionsModalOpen: true,
      selectedProcessFeature: processFeature,
    });
  };

  render() {
    return (
      <>
        <Grid container justifyContent={'flex-start'} alignItems={'flex-end'} spacing={1}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label={'Key'}
              value={this.state.newProcessFeature.key}
              onChange={event => this.updateNewProcessFeatureState('key', event.target.value)}
              size={'small'}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              select
              label={'Data Type'}
              onChange={e => this.updateNewProcessFeatureState('dataTypeKey', e.target.value)}
              value={this.state.newProcessFeature.dataTypeKey || ''}
              size={'small'}
              InputLabelProps={{
                shrink: !!this.state.newProcessFeature.dataTypeKey,
              }}
            >
              {this.state.dataTypes.map(dt => (<MenuItem key={dt} value={dt}>{dt}</MenuItem>))}
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              select
              label={'Unit Type'}
              onChange={e => this.updateNewProcessFeatureState('unitKey', e.target.value)}
              value={this.state.newProcessFeature.unitKey || ''}
              size={'small'}
              InputLabelProps={{
                shrink: !!this.state.newProcessFeature.unitKey,
              }}
            >
              {this.state.units.map(u => (<MenuItem key={u} value={u}>{u}</MenuItem>))}
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              color="primary"
              onClick={this.onNewProcessFeatureClick}
              disabled={!this.validNewProcessFeature()}
            >
              <Add />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Paper style={{height: 'calc(100vh - 250px)'}}>
              <Box style={{width: '100%', height: '100%'}}>
                <DataGridPro
                  style={{height: '100%'}}
                  columns={this.state.columns}
                  rows={this.state.processFeatures}
                  loading={this.state.loading}
                  processRowUpdate={this.processRowUpdate}
                  editMode={'row'}
                  density={'compact'}
                  hideFooter
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
        {this.state.selectOptionsModalOpen && <SelectOptionsModal
          processFeature={this.state.selectedProcessFeature}
          confirm={() => {}}
          open={this.state.selectOptionsModalOpen}
          setOpen={() => this.setState({selectOptionsModalOpen: false})}
        />}
      </>
    );
  }
}

export default ProcessFeaturesTable;
