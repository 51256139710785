import React, {Component} from 'react';
import {getMaterialCategories, getMaterialNameStandards, getMaterials, getMaterialShapes, patchMaterial, createMaterial} from 'services/knestKontrol';
import {DataGridPro, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, useGridApiRef} from '@mui/x-data-grid-pro';
import {Paper, Grid, Box, Button, Snackbar, Alert, Tooltip, IconButton} from '@mui/material';
import {Add} from '@mui/icons-material';
import {v4 as uuidv4} from 'uuid';
import {Edit, LocalFireDepartment} from '@mui/icons-material';
import MaterialNameStandardsModal from '../Modals/MaterialNameStandardsModal/MaterialNameStandardsModal';
import MaterialHeatStatesModal from '../Modals/MaterialHeatStatesModal/MaterialHeatStatesModal';
import MaterialMaterialHeatStateModal from '../Modals/MaterialMaterialHeatStateModal/MaterialMaterialHeatStateModal';
import MaterialCategoriesModal from '../Modals/MaterialCategoriesModal/MaterialCategoriesModal';

class MaterialsTable extends Component {
  constructor(props) {
    super(props);

    this.sortModel = [{field: 'key', sort: 'asc'}];

    this.state = {
      loading: true,
      columns: [],
      materials: [],
      materialNameStandards: [],
      materialShapes: [],
      snackbarOpen: false,
      snackbarSeverity: 'success',
      snackbarMessage: '',
      materialHeatStatesModalOpen: false,
      materialNameStandardsModalOpen: false,
      materialCategoriesModalOpen: false,
      materialMaterialHeatStateModalOpen: false,
      selectedMaterial: null,
    };
  }

  fetchAndRefreshData = async () => {
    const materials = await getMaterials({
      filter: {include: {materialNames: true, materialShapes: true, materialHeatStates: true}},
    });

    materials.map(m => m.uuid = uuidv4());

    const materialCategories = await getMaterialCategories({
      filter: {
        include: {materialSubcategories: true},
      },
    });

    const materialNameStandards = await getMaterialNameStandards();

    const materialShapes = await getMaterialShapes();

    this.defaultNewMaterial = {
      id: Infinity,
      key: '',
      materialCategoryKey: null,
      materialSubcategoryKey: null,
      density: null,
      ...materialNameStandards.reduce((acc, mns) => {
        acc[`mns_${mns.key}`] = null;
        return acc;
      }, {}),
      ...materialShapes.reduce((acc, ms) => {
        acc[`ms_${ms.key}`] = null;
        return acc;
      }, {}),
    };

    await this.setState({
      loading: false,
      materials,
      materialNameStandards,
      materialShapes,
      columns: [
        {field: 'id', headerName: 'ID', filterable: true, editable: false, sortable: false},
        {field: 'key', minWidth: 200, headerName: 'Key/Material Number', filterable: true, editable: true, sortable: false},
        {
          field: 'materialCategoryKey',
          minWidth: 170,
          headerName: 'Material Category',
          type: 'singleSelect',
          valueOptions: materialCategories.map(mc => mc.key),
          filterable: true,
          editable: true,
          sortable: false,
          required: true,
        },
        {
          field: 'materialSubcategoryKey',
          minWidth: 170,
          headerName: 'Material Subcategory',
          type: 'singleSelect',
          valueOptions: ({row}) => {
            if (row === undefined) return [...materialCategories.flatMap(mc => mc.materialSubcategories.map(msc => ({value: msc.key, label: msc.key}))), {value: null, label: 'NULL'}];
            return [
              ...materialCategories.find(mc => mc.key === row.materialCategoryKey)
                ?.materialSubcategories
                .map(msc => ({value: msc.key, label: msc.key})) || [],
              {value: null, label: 'NULL'},
            ];
          },
          valueSetter: (value, row) => {
            if (!materialCategories.find(mc => mc.key === row.materialCategoryKey)
              ?.materialSubcategories
              .some(msc => msc.key === value)) value = null;
            return {...row, materialSubcategoryKey: value};
          },
          filterable: true,
          editable: true,
          sortable: false,
        },
        {field: 'density', minWidth: 150, headerName: 'Density (g/㎤)', type: 'number', editable: true, sortable: false},
        {field: 'additionalLeadtime', minWidth: 150, headerName: 'Additional Leadtime (days)', type: 'number', editable: true, sortable: false},
        ...materialNameStandards.map(mns => ({
          field: `mns_${mns.key}`,
          headerName: `Name in: ${mns.key}`,
          minWidth: 200,
          valueGetter: (value, row) => row.materialNames?.filter(mn => mn.materialNameStandardKey === mns.key).map(mn => mn.name).join(', ') || null,
          editable: true,
        })),
        ...materialShapes.map(ms => ({
          field: `ms_${ms.key}`,
          headerName: `${ms.key} €/kg`,
          type: 'number',
          valueGetter: (value, row) => row.materialShapes?.find(mms => mms.materialShapeKey === ms.key)?.price || null,
          editable: true,
        })),
        {
          field: 'actions',
          width: 10,
          headerName: '',
          editable: false,
          sortable: false,
          renderCell: (params) => {
            return (
              <Tooltip title={'Edit Related Heat States'}>
                <IconButton onClick={() => this.editMaterialHeatStates(params.row)} size={'small'}><LocalFireDepartment /></IconButton>
              </Tooltip>
            );
          },
        },
      ],
    });
  };

  async componentDidMount() {
    await this.fetchAndRefreshData();
  }

  editMaterialHeatStates = async row => {
    await this.setState({
      selectedMaterial: row,
      materialMaterialHeatStateModalOpen: true,
    });
  };

  confirmEditMaterialHeatStates = async () => {
    await this.fetchAndRefreshData();
  };

  addMaterialHeatStateToMaterial = async (materialId, materialHeatStateKey) => {
    const updatedMaterial = await patchMaterial(materialId, {
      materialHeatStates: {
        create: {
          materialHeatState: {connect: {key: materialHeatStateKey}},
        },
      },
    });

    await this.setState({
      materials: this.state.materials.map(m => m.id === materialId ? {...m, ...updatedMaterial} : m),
      selectedMaterial: {
        ...this.state.selectedMaterial,
        materialHeatStates: updatedMaterial.materialHeatStates,
      },
    });
  };

  removeMaterialHeatStateFromMaterial = async (materialId, materialHeatStateKey) => {
    const updatedMaterial = await patchMaterial(materialId, {
      materialHeatStates: {
        deleteMany: {
          materialHeatStateKey,
        },
      },
    });

    await this.setState({
      materials: this.state.materials.map(m => m.id === materialId ? {...m, ...updatedMaterial} : m),
      selectedMaterial: {
        ...this.state.selectedMaterial,
        materialHeatStates: updatedMaterial.materialHeatStates,
      },
    });
  };

  handleRowUpdate = async (newRow) => {
    const updatedMaterial = newRow.id !== Infinity
      ? await patchMaterial(newRow.id, {
        key: newRow.key?.trim().length ? newRow.key.trim() : null,
        materialCategoryKey: newRow.materialCategoryKey,
        materialSubcategoryKey: newRow.materialSubcategoryKey,
        density: newRow.density,
        additionalLeadtime: newRow.additionalLeadtime,
        materialShapes: {
          upsert: this.state.materialShapes.filter(ms => newRow[`ms_${ms.key}`]).map(ms => ({
            where: {materialId_materialShapeKey: {materialId: newRow.id, materialShapeKey: ms.key}},
            create: {
              materialShape: {connect: {key: ms.key}},
              unit: {connect: {key: 'euro_per_kg'}},
              price: newRow[`ms_${ms.key}`],
            },
            update: {
              price: newRow[`ms_${ms.key}`],
            },
          })),
          deleteMany: {
            materialShapeKey: {in: this.state.materialShapes.filter(ms => !newRow[`ms_${ms.key}`]).map(ms => ms.key)},
          },
        },
        materialNames: {
          upsert: this.state.materialNameStandards.filter(mns => newRow[`mns_${mns.key}`]).reduce((acc, mns) => {
            const materialNames = newRow[`mns_${mns.key}`].split(',').map(e => e.trim()).filter(e => e.length);
            materialNames.map(mn => acc.push({
              where: {materialId_materialNameStandardKey_name: {materialId: newRow.id, materialNameStandardKey: mns.key, name: mn}},
              create: {
                materialNameStandard: {connect: {key: mns.key}},
                name: mn,
              },
              update: {
                name: mn,
              },
            }));

            return acc;
          }, []),
          deleteMany: {
            OR: [
              {materialNameStandardKey: {in: this.state.materialNameStandards.filter(mns => !newRow[`mns_${mns.key}`]).map(mns => mns.key)}},
              {
                AND: [
                  ...this.state.materialNameStandards.filter(mns => newRow[`mns_${mns.key}`]).reduce((acc, mns) => {
                    const validMaterialNames = newRow[`mns_${mns.key}`].split(',').map(e => e.trim()).filter(e => e.length);
                    validMaterialNames.map(mn => acc.push({
                      NOT: {
                        materialNameStandardKey: mns.key,
                        name: mn,
                      },
                    }));

                    return acc;
                  }, []),
                ],
              },
            ],
          },
        },
      })
      : await createMaterial({
        key: newRow.key?.trim().length ? newRow.key.trim() : null,
        materialCategoryKey: newRow.materialCategoryKey,
        materialSubcategoryKey: newRow.materialSubcategoryKey,
        density: newRow.density,
        additionalLeadtime: newRow.additionalLeadtime,
        materialShapes: {
          create: this.state.materialShapes.filter(ms => newRow[`ms_${ms.key}`]).map(ms => ({
            materialShape: {connect: {key: ms.key}},
            price: newRow[`ms_${ms.key}`],
            unit: {connect: {key: 'euro_per_kg'}},
          })),
        },
        materialNames: {
          create: this.state.materialNameStandards.filter(mns => newRow[`mns_${mns.key}`]).reduce((acc, mns) => {
            const materialNames = newRow[`mns_${mns.key}`].split(',').map(e => e.trim()).filter(e => e.length);
            materialNames.map(mn => acc.push({
              materialNameStandard: {connect: {key: mns.key}},
              name: mn,
            }));

            return acc;
          }, []),
        },
      });

    await this.setState({
      snackbarOpen: true,
      snackbarMessage: 'Material successfully saved/updated.',
      snackbarSeverity: 'success',
    });

    return {...newRow, ...updatedMaterial};
  };

  handleRowUpdateFailure = async (error) => {
    console.error(error);
    this.setState({
      snackbarOpen: true,
      snackbarMessage: 'An error occurred while saving the material. Verify that all inputs are valid (i.e. density > 0), and that all required inputs (i.e. material category, density) are provided, or try again later.',
      snackbarSeverity: 'error',
    });
  };

  handleRowEditStop = (param, event) => {
    if (param.reason === 'rowFocusOut') event.defaultMuiPrevented = true;
  };

  CustomToolbar = () => {
    const currentGrid = this.props.gridApiRef.current;

    const addNewMaterialRow = async () => {
      const existingRowInEditMode = this.state.materials.find(m => currentGrid.getRowMode(m.uuid) === 'edit');

      if (existingRowInEditMode) {
        return this.setState({
          snackbarOpen: true,
          snackbarMessage: 'You cannot add a new material while another material is being updated/created.',
          snackbarSeverity: 'warning',
        });
      }

      const uuid = uuidv4();

      await this.setState({
        materials: [{...this.defaultNewMaterial, uuid}, ...this.state.materials],
      });

      currentGrid.startRowEditMode({id: uuid, fieldToFocus: 'key'});
    };

    return (
      <GridToolbarContainer>
        <Button startIcon={<Add />} onClick={() => addNewMaterialRow()} size={'small'}>Add Material</Button>
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Button
          startIcon={<Edit />}
          size={'small'}
          onClick={() => this.setState({materialCategoriesModalOpen: true})}
        >
          Edit Material Categories
        </Button>
        <MaterialCategoriesModal
          open={this.state.materialCategoriesModalOpen}
          confirm={() => this.fetchAndRefreshData()}
          setOpen={materialCategoriesModalOpen => this.setState({materialCategoriesModalOpen})}
        />
        <Button
          startIcon={<Edit />}
          size={'small'}
          onClick={() => this.setState({materialNameStandardsModalOpen: true})}
        >
          Edit Material Name Standards
        </Button>
        <MaterialNameStandardsModal
          open={this.state.materialNameStandardsModalOpen}
          confirm={() => this.fetchAndRefreshData()}
          setOpen={materialNameStandardsModalOpen => this.setState({materialNameStandardsModalOpen})}
        />
        <Button
          startIcon={<Edit />}
          size={'small'}
          onClick={() => this.setState({materialHeatStatesModalOpen: true})}
        >
          Edit Available Material Heat States
        </Button>
        <MaterialHeatStatesModal
          open={this.state.materialHeatStatesModalOpen}
          confirm={() => this.fetchAndRefreshData()}
          setOpen={materialHeatStatesModalOpen => this.setState({materialHeatStatesModalOpen})}
        />
      </GridToolbarContainer>
    );
  };

  render() {
    return (
      <>
        <Grid container spacing={2} sx={{height: 'calc(100vh - 180px)'}}>
          <Grid item xs={12} sx={{height: '100%'}}>
            <Paper sx={{height: '100%'}}>
              <Box sx={{width: '100%', height: '100%'}}>
                {!this.state.loading && <DataGridPro
                  style={{height: '100%'}}
                  columns={this.state.columns}
                  rows={this.state.materials}
                  loading={this.state.loading}
                  density={'compact'}
                  disableColumnSelector
                  pagination={false}
                  pinnedColumns={{left: ['key'], right: ['actions']}}
                  editMode={'row'}
                  processRowUpdate={this.handleRowUpdate}
                  onProcessRowUpdateError={this.handleRowUpdateFailure}
                  onRowEditStop={this.handleRowEditStop}
                  slots={{
                    toolbar: this.CustomToolbar,
                  }}
                  apiRef={this.props.gridApiRef}
                  hideFooter
                  columnVisibilityModel={{id: false}}
                  sortModel={[{field: 'id', sort: 'desc'}]}
                  getRowId={row => row.uuid}
                />}
                <Snackbar
                  open={this.state.snackbarOpen}
                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                  autoHideDuration={4000}
                  onClose={() => this.setState({snackbarOpen: false})}
                >
                  <Alert onClose={() => this.setState({snackbarOpen: false})} severity={this.state.snackbarSeverity} sx={{width: '100%'}}>
                    {this.state.snackbarMessage}
                  </Alert>
                </Snackbar>
                {this.state.materialMaterialHeatStateModalOpen && <MaterialMaterialHeatStateModal
                  open={this.state.materialMaterialHeatStateModalOpen}
                  material={this.state.selectedMaterial}
                  removeMaterialHeatStateFromMaterial={this.removeMaterialHeatStateFromMaterial}
                  addMaterialHeatStateToMaterial={this.addMaterialHeatStateToMaterial}
                  confirm={this.confirmEditMaterialHeatStates}
                  setOpen={materialMaterialHeatStateModalOpen => this.setState({materialMaterialHeatStateModalOpen})}
                />}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}

const withGridApiRef = Component => props => {
  const gridApiRef = useGridApiRef();
  return <Component gridApiRef={gridApiRef} {...props} />;
};

export default withGridApiRef(MaterialsTable);
