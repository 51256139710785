import React, {Component} from 'react';
import {
  Autocomplete,
  Box, Button,
  Fade,
  IconButton,
  Modal,
  Paper, TextField, Tooltip,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import {withTheme} from '@mui/styles';
import {Close, Delete} from '@mui/icons-material';
import './MaterialCategoriesModal.scss';
import {createMaterialCategory, deleteMaterialCategory, getMaterialCategories, patchMaterialCategory} from '../../../services/knestKontrol';
import {DataGridPro, GridToolbarContainer, useGridApiRef} from '@mui/x-data-grid-pro';
import {v4 as uuidv4} from 'uuid';
import {Add} from '@mui/icons-material';

class MaterialCategoriesModal extends Component {
  constructor(props) {
    super(props);

    this.defaultNewMaterialCategory = {
      key: null,
      materialFeatures: [],
      materialSubcategories: [],
    };

    this.defaultSate = {
      materialCategories: [],
      columns: [],
      loading: true,
    };

    this.state = {
      ...this.defaultSate,
    };
  }

  fetchAndRefreshData = async () => {
    await this.setState({loading: true});

    const materialCategories = await getMaterialCategories({
      filter: {
        include: {materialFeatures: true, materialSubcategories: true},
      },
    });

    materialCategories.map(mc => mc.uuid = uuidv4());

    await this.setState({
      loading: false,
      materialCategories,
      columns: [
        {field: 'key', headerName: 'Material Category Key', width: 200, editable: true},
        {
          field: 'materialSubcategories',
          headerName: 'Material Subcategories',
          minWidth: 300,
          renderCell: ({row}) => {
            return <Autocomplete
              multiple
              fullWidth
              freeSolo
              value={row.materialSubcategories}
              onChange={(event, value, reason, {option}) => this.handleMaterialSubcategoryChange(row, reason, option)}
              filterSelectedOptions
              size={'small'}
              options={row.materialSubcategories}
              getOptionLabel={option => option.key}
              isOptionEqualToValue={(option, value) => option.key === value.key}
              sx={{maxHeight: '100%'}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={'standard'}
                  sx={{maxHeight: 50, overflowY: 'scroll'}}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: false,
                    disableUnderline: true,
                    sx: {maxHeight: 50},
                  }}
                />
              )}
            />;
          },
        },
        {
          field: 'materialFeatures',
          headerName: 'Material Features',
          minWidth: 300,
          renderCell: ({row}) => {
            return <Autocomplete
              multiple
              fullWidth
              freeSolo
              value={row.materialFeatures}
              onChange={(event, value, reason, {option}) => this.handleMaterialFeaturesChange(row, reason, option)}
              filterSelectedOptions
              size={'small'}
              options={row.materialFeatures}
              getOptionLabel={option => option.key}
              isOptionEqualToValue={(option, value) => option.key === value.key}
              sx={{maxHeight: '100%'}}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant={'standard'}
                  sx={{maxHeight: 50, overflowY: 'scroll'}}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: false,
                    disableUnderline: true,
                    sx: {maxHeight: 50},
                  }}
                />
              )}
            />;
          },
        },
        {
          field: 'actions',
          headerName: '',
          width: 10,
          renderCell: ({row}) => {
            return (
              <Tooltip title={'Delete Material Category'}>
                <IconButton onClick={() => this.deleteMaterialCategory(row.key)} size={'small'}><Delete /></IconButton>
              </Tooltip>
            );
          },
        },
      ],
    });
  };

  deleteMaterialCategory = async (materialCategoryKey) => {
    materialCategoryKey && await deleteMaterialCategory(materialCategoryKey);

    await this.fetchAndRefreshData();
  };

  handleMaterialSubcategoryChange = async (row, reason, option) => {
    const updatedMaterialCategory = await patchMaterialCategory(row.key, {
      materialSubcategories: {
        ...reason === 'createOption' ?
          {
            create: {
              key: option,
            },
          } :
          {
            deleteMany: {
              key: option.key,
            },
          },
      },
    });

    await this.setState({
      materialCategories: this.state.materialCategories.map(mc => mc.uuid === row.uuid ? {...mc, ...updatedMaterialCategory} : mc),
    });
  };

  handleMaterialFeaturesChange = async (row, reason, option) => {
    const updatedMaterialCategory = await patchMaterialCategory(row.key, {
      materialFeatures: {
        ...reason === 'createOption' ?
          {
            create: {
              key: option,
            },
          } :
          {
            deleteMany: {
              key: option.key,
            },
          },
      },
    });

    await this.setState({
      materialCategories: this.state.materialCategories.map(mc => mc.uuid === row.uuid ? {...mc, ...updatedMaterialCategory} : mc),
    });
  };

  async componentDidMount() {
    await this.fetchAndRefreshData();
  }

  closeModal = async () => {
    this.props.confirm();
    this.props.setOpen(false);
  };

  handleNewMaterialCategory = async newRow => {
    if (!newRow.key.length) return;

    const materialCategory = await createMaterialCategory({
      key: newRow.key,
    });

    await this.setState({
      materialCategories: this.state.materialCategories.map(mc => mc.uuid === newRow.uuid ? {...mc, ...materialCategory} : mc),
    });

    return newRow;
  };

  CustomToolbar = () => {
    const currentGrid = this.props.gridApiRef.current;

    const addNewMaterialCategoryRow = async () => {
      const existingRowInEditMode = this.state.materialCategories.find(mc => currentGrid.getRowMode(mc.uuid) === 'edit');

      if (existingRowInEditMode) return;

      const uuid = uuidv4();

      await this.setState({
        materialCategories: [{...this.defaultNewMaterialCategory, uuid}, ...this.state.materialCategories],
      });

      currentGrid.startRowEditMode({id: uuid, fieldToFocus: 'key'});
    };

    return (
      <GridToolbarContainer>
        <Button startIcon={<Add />} onClick={() => addNewMaterialCategoryRow()} size={'small'}>Add Material Category</Button>
      </GridToolbarContainer>
    );
  };

  render() {
    const {open} = this.props;

    return (
      <Modal open={open} closeAfterTransition={true}>
        <Fade in={open} timeout={250}>
          <Box className={'material-categories-modal'}>
            <Paper sx={{height: '100%'}}>
              <IconButton
                size={'small'}
                className={'close-material-categories-modal-button'}
                onClick={this.closeModal}
              >
                <Close />
              </IconButton>
              <Grid
                container
                spacing={2}
                className={'material-categories-modal-content'}
              >
                <Grid xs={12}>
                  <Typography>Editing Material Categories</Typography>
                </Grid>
                <Grid xs={12} alignItems={'flex-end'} sx={{width: '60vw', height: '60vh'}}>
                  <DataGridPro
                    columns={this.state.columns}
                    rows={this.state.materialCategories}
                    getRowId={row => row.uuid}
                    processRowUpdate={this.handleNewMaterialCategory}
                    components={{
                      Toolbar: this.CustomToolbar,
                    }}
                    editMode={'row'}
                    experimentalFeatures={{newEditingApi: true}}
                    hideFooter
                    apiRef={this.props.gridApiRef}
                    pinnedColumns={{left: ['key'], right: ['actions']}}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Fade>
      </Modal>
    );
  }
}

const withGridApiRef = Component => props => {
  const gridApiRef = useGridApiRef();
  return <Component gridApiRef={gridApiRef} {...props} />;
};

export default withGridApiRef(withTheme(MaterialCategoriesModal));
