import './Loading.scss';
import {Grid, Typography} from '@mui/material';
import BoxLogo from 'assets/img/app_logo.svg';
import {Progress} from 'react-sweet-progress';
import React, {useEffect, useState} from 'react';

export default function Loading({pageHeight = '75vh', withProgress = false, currentProgress = undefined, secondsToFinish = 5, withText = false, text = ''}) {

  const [progress, setProgress] = useState(null);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (!currentProgress) {
      const timerId = setInterval(() => {
        setSeconds(seconds + 0.1);
        setProgress(Math.round(seconds / secondsToFinish * 100));
      }, 100);
      if (progress >= 100) {
        clearInterval(timerId);
      }
      return () => clearInterval(timerId);
    }
  });

  return (
    <Grid
      container
      justifyContent={'center'}
      alignContent={'center'}
      alignItems={'center'}
      className={'loading-container'}
      style={{height: pageHeight}}
    >
      <Grid
        item
        md={4}
        container
        justifyContent={'center'}
        alignContent={'center'}
        alignItems={'center'}
      >
        <Typography variant="h3" component="h4">
          <div className="lds-css ng-scope">
            <div className={'lds-eclipse'}>
              <div></div>
            </div>
            <img alt="loading" src={BoxLogo} className={'loading-logo'}></img>
          </div>
        </Typography>
        {withProgress &&
          <Progress
            percent={currentProgress || progress}
            status="active"
            theme={{active: {color: '#32bee1'}}}
          />}
        {withText &&
          <div style={{width: '100%'}}>{text}</div>}
      </Grid>
    </Grid>
  );
}
